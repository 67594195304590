import React from 'react';
import { IKiosk } from '../kiosks/Kiosks.model';
import KiosksService from '../kiosks/Kiosks.service';
import { ITag } from '../tags/Tags.model';
import TagsService from '../tags/Tags.service';
import IModule, { ModuleState } from "../../system/IModule"
import { AuthContext } from '../../system/Base'
import { IUserContext } from '../../system/User.model'
import KioskDetail from '../dashboard/KioskDetail';
import LogoUploader from '../dashboard/LogoUploader';
import LocalKioskService from '../kiosks/LocalKiosk.service';
import { setPageTitle } from '../../App'
import ColorPicker from '../../system/ColorPicker'

interface IState {
	kiosks: IKiosk[],
	tags: ITag[],
	test1: boolean,
	test2: boolean,
	test3: boolean,
	test4: boolean,
	v1: string,
	v2 : string,
	
	kiosk? : IKiosk,
	
	testColor : string,
}

@IModule
export default class Test extends React.Component<{}, IState> {
	
	protected reloadInterval: any | null = null;

	constructor(props: {}) {
		super(props);
		this.state = {
			kiosks: [],
			tags: [],
			test1 : false,
			test2 : false,
			test3 : false,
			test4 : false,
			v1 : "",
			v2 : "",
			
			kiosk : undefined,
			
			testColor : '#777777'
		};

		this.reload = this.reload.bind(this);
		this.onColorChange = this.onColorChange.bind(this);
	}

	async componentDidMount() {
		setPageTitle("Test");

		await this.reload();

		if (this.reloadInterval === null)
			this.reloadInterval = setInterval(() => this.reload(), 1 * 60 * 1000);
			
		this.reloadKiosk();
	}

	componentWillUnmount() {
		clearInterval(this.reloadInterval);
		this.reloadInterval = null;
	}
	
	public static getLocale() { return null; }
	
	public static isEnabled(auth: IUserContext) { 
		// testing:
		return ModuleState.DISABLED;
	}	

	async reload(): Promise<void> {
		const kiosks = (await KiosksService.getAll()).data;	
		const tags = (await TagsService.getAll()).data;
		this.setState({
			kiosks: kiosks,
			tags: tags
		});
	}

	public static menu() {
		return {
			title : "Test",
			route : "/test",
			icon : <div style={{fontSize: "14px", lineHeight: "14px"}}>test<br />stuff</div>,
			weight : 200
		};
	}
	
	public static search(input : string) {
		return null;
	}
	
	async reloadKiosk(): Promise<void> {
		const kiosk = (await LocalKioskService.getLocal());	
		console.log(kiosk);

		this.setState({
			kiosk: kiosk
		});
	}
	
	onColorChange (hex : string) {
		this.setState({
			testColor : hex
		});
	}
	
	render() {
	
	/*	const doRequest = <Button onClick={()=>{
			FilesService.getAll("MEDIA", "").then(()=>{ 
				this.setState({test2 : true});
			});
		}
		}>Test request A</Button>	
		
		const doRequest2 = <Button onClick={()=>{
			FilesService.getAll("MEDIA", "").then(()=>{ 
				this.setState({test4 : true});
			});
		}
		}>Test request B</Button>	
		
		const fe=<FileExplorer mode="input" folder="MEDIA" output="singleFile" value={this.state.v1} onChange={(e)=>{ this.setState({v1 : e as string}) }} />
		const fe2=<FileExplorer mode="input" folder="MEDIA" output="singleFile" value={this.state.v2} onChange={(e)=>{ this.setState({v2 : e as string}) }} />*/
	
		return (<AuthContext.Consumer>{auth => (<>
		
		<div style={{display: "inline-block", width: "300px", border: "1px dashed"}}>
			<ColorPicker value={ this.state.testColor } onChange={ this.onColorChange } />
		</div>
		
		</>)}</AuthContext.Consumer>);
	}
}