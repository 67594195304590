import React from "react";
import { FieldProps, FormikProps, useFormikContext } from "formik";
import { WithTranslation } from "react-i18next";

interface ILabeledFieldProps<V = any, FormValues = any> extends FieldProps<V, FormValues>, WithTranslation {
    labelKey: string,
    namespace: string
}

interface EventTarget<T> {
    id?: string,
    name: string,
    value: T
}

function triggerFormChange<TEntity, TValue>(form: FormikProps<TEntity>, fieldName: string, fieldValue: TValue, targetId?: string) {
    const target: EventTarget<TValue> = {
        name: fieldName,
        value: fieldValue
    };
    if (targetId) target.id = targetId;

    let ev = {
        bubbles: true,
        cancelable: false,
        currentTarget: target, // DOMEventTarget
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: true,
        nativeEvent: new Event('change', {bubbles: true}), // DOMEvent
        preventDefault: () => {},
        isDefaultPrevented: () => false,
        stopPropagation: () => {},
        isPropagationStopped: () => false,
        persist: () => {},
        target: target, // DOMEventTarget
        timeStamp: 0,
        type: ''
    } as React.ChangeEvent<any>;
    form.getFieldProps(fieldName).onChange(ev);
}

function debounce(func: (...args: any[]) => any, timeoutMs = 300) {
    let timer: any;
    return (...args: any[]) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), timeoutMs);
    };
}

/** Adapted from Formik example on autosave by Jared Palmer:
 * https://github.com/jaredpalmer/formik/blob/master/examples/DebouncedAutoSave.js */
interface IAutoSaveProps
{
    debounceMs: number,
    printInfo: boolean
}
const AutoSave = (props: IAutoSaveProps) => {
    const formik = useFormikContext();
    const [lastSaved, setLastSaved] = React.useState<string | null>(null);
    const debouncedSubmit = React.useCallback(
      debounce(
        () => formik.submitForm().then(() => setLastSaved(new Date().toISOString())),
        props.debounceMs
      ),
      [props.debounceMs, formik.submitForm]
    );
  
    React.useEffect(() => {
      debouncedSubmit();
    }, [debouncedSubmit, formik.values]);
  
    return (
      <>
        {props.printInfo && (!!formik.isSubmitting
          ? 'saving...'
          : lastSaved !== null
          ? `Last Saved: ${lastSaved}`
          : null)}
      </>
    );
};

export type { ILabeledFieldProps, EventTarget }
export { triggerFormChange, AutoSave }