import http from "../../system/Communicator";
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { combineQueries, encodeAsParams } from "../../system/RequestResponse.model";
import { IActivityEntry, IRemoteActivity, createDefaultTimeFilter, ReportType, IButtonPositionRow, IButtonLabelRow, ITimeFilter, IContentFilter, createDefaultContentFilter } from "./Statistics.model";
import { Duration, DateTimeUnit, DateTime } from "luxon";
import { datetimeFromIso, datetimeToIso } from "../../system/MuiDateTimeField";
import { AxiosResponse } from "axios";
import { createDefaultKioskFilter, IKioskFilter } from "../kiosks/Kiosks.model";

export abstract class BaseStatisticService {

    abstract getAll(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter, contentFilter?: IContentFilter, pageQuery?: PageQuery): Promise<IPagedResponse<IRemoteActivity>>;
    abstract getAllExport(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter, contentFilter?: IContentFilter): Promise<void>;
    abstract getButtonPosition(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<IPagedResponse<IButtonPositionRow>>;
    abstract getButtonPositionExport(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<void>;
	abstract getButtonLabel(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<IPagedResponse<IButtonLabelRow>>;
    abstract getButtonLabelExport(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<void>;

    async getAllNavigation(timeFilter: ITimeFilter, kioskFilter?: IKioskFilter, contentFilter?: IContentFilter): Promise<IActivityEntry[]> {
        if (!timeFilter.to || ! timeFilter.from)
            return [];

        const promises = [];
        const times = [];
        const stats: IActivityEntry[] = [];
        const { intervals, unit } = this.getBestTimeStep(timeFilter);

        for (let i = 0; i < intervals; ++i) {
            const tf = this.getStepInterval(i, intervals, timeFilter, unit);
            promises.push(this.getAll(tf, kioskFilter, contentFilter, new PageQuery(0, 0)));
            times.push(tf);
        }
        const resolved = await Promise.all(promises);

        for (let i = 0; i < intervals; ++i) {
            const navs = resolved[i].total;
            this.addActivityEntry(stats, times[i], unit, navs);
        }
        return stats;
    }

    async getButtonClicks(timeFilter: ITimeFilter, kioskFilter?: IKioskFilter): Promise<IActivityEntry[]> {
        if (!timeFilter.to || ! timeFilter.from)
            return [];

        const promises = [];
        const times = [];
        const stats: IActivityEntry[] = [];
        const { intervals, unit } = this.getBestTimeStep(timeFilter);

        for (let i = 0; i < intervals; ++i) {
            const tf = this.getStepInterval(i, intervals, timeFilter, unit);
            promises.push(this.getButtonPosition(tf, kioskFilter));
            times.push(tf);
        }
        const resolved = await Promise.all(promises);

        for (let i = 0; i < intervals; ++i) {
            const clicks = resolved[i].data.reduce((prev, curr) => prev + curr.clicks, 0);
            this.addActivityEntry(stats, times[i], unit, clicks);
        }
        return stats;
    }

    protected addActivityEntry(arr: IActivityEntry[], interval: ITimeFilter, unit: DateTimeUnit, rate: number) {
        const from = datetimeFromIso(interval.from!)!.toLocal();
        const to = datetimeFromIso(interval.to!)!.toLocal()
        const alignedFrom = this.getStartOf(from, unit);
        const alignedTo = this.getStartOfNext(to, unit);

        arr.push({
            time: datetimeToIso(alignedFrom, true),
            completeData: from.equals(alignedFrom) && to.equals(alignedTo),
            rate: rate
        });
    }

    getBestTimeStep(timeFilter: ITimeFilter) {
        const from = datetimeFromIso(timeFilter.from)!.toLocal();
        const to = datetimeFromIso(timeFilter.to)!.toLocal();

        const duration = to.diff(from);
        const unit = this.getBestDurationUnit(duration);
        
        const alignedFrom = this.getStartOf(from, unit);
        const alignedTo = this.getStartOfNext(to, unit);

        const intervals = Math.ceil(alignedTo.diff(alignedFrom, unit).as(unit));

        return { intervals, unit };
    }

    protected getStartOf(datetime: DateTime, unit: DateTimeUnit) {
        return datetime.startOf(unit);
    }
        
    protected getStartOfNext(datetime: DateTime, unit: DateTimeUnit) {
        if (datetime.equals(this.getStartOf(datetime, unit)))
            return datetime;
        return datetime.plus({ [unit]: 1 }).startOf(unit);
    }

    protected getStepInterval(i: number, total: number, timeFilter: ITimeFilter, unit: DateTimeUnit) {
        const alignedFrom = this.getStartOf(datetimeFromIso(timeFilter.from)!.toLocal(), unit);

        if (i === 0) {
            return {
                from: timeFilter.from!,
                to: datetimeToIso(alignedFrom.plus({ [unit]: 1 }), true)
            }
        }
        if (i === total - 1) {
            return {
                from: datetimeToIso(alignedFrom.plus({ [unit]: i }), true),
                to: timeFilter.to!,
            }
        }
        return {
            from: datetimeToIso(alignedFrom.plus({ [unit]: i }), true),
            to: datetimeToIso(alignedFrom.plus({ [unit]: i + 1}), true)
        };
    }
        
    protected getBestDurationUnit(duration: Duration): DateTimeUnit {
        if (duration.as('year') >= 2)
            return 'year';
        if (duration.as('month') > 5)
            return 'month';
        if (duration.as('week') > 5)
            return 'week';
        if (duration.as('day') >= 2)
            return 'day';
        return 'hour';
        // everything else is too detailed for the usecase
    }

    protected getFileName(response: AxiosResponse<string, any>, timeFilter: ITimeFilter | undefined, fallbackFileSuffix: string) {        
        const cd = response.headers['content-disposition'];
        if (cd && cd.includes('filename')) // 'attachment; filename="xyz"'
            return cd.replace('attachment; filename="', '').replace('"', '');

        return datetimeFromIso(timeFilter?.from)?.toLocal().toFormat('yyyy-LL-dd-HH-mm')
            + '_' + datetimeFromIso(timeFilter?.to)?.toLocal().toFormat('yyyy-LL-dd-HH-mm') + fallbackFileSuffix;
    }

    protected saveCsv(data: string, filename: string) {
        const file = new Blob([data], {type: 'text/csv'});
        // @ts-ignore
        if (window.navigator.msSaveOrOpenBlob) // IE10+
        // @ts-ignore
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            const a = document.createElement('a');
            const url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            a.dispatchEvent(new MouseEvent('click'));
    
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }
}


class GlobalStatisticsService extends BaseStatisticService {

	async getAll(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter, contentFilter?: IContentFilter, pageQuery?: PageQuery): Promise<IPagedResponse<IRemoteActivity>> {
		const pg = pageQuery?.toString() ?? '';
        const kf = kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const cf = contentFilter ? encodeAsParams(contentFilter, createDefaultContentFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.All }, { type: ReportType.All });
        const query = combineQueries(ty, pg, kf, tf, cf);
        
		return (await http.get<IPagedResponse<IRemoteActivity>>(`/statistics/activity?${query}`)).data;
	}

    async getAllExport(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter, contentFilter?: IContentFilter): Promise<void> {
        const kf = kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const cf = contentFilter ? encodeAsParams(contentFilter, createDefaultContentFilter()) : '';
        const ex = encodeAsParams({ export: true }, { export: true });
        const ty = encodeAsParams({ type: ReportType.All }, { type: ReportType.All });
        const query = combineQueries(ty, ex, kf, tf, cf);
        
		const response = (await http.get<string>(`/statistics/activity?${query}`));
        this.saveCsv(response.data, this.getFileName(response, timeFilter, '_all-activity.csv'));
	}
    
    async getButtonPosition(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<IPagedResponse<IButtonPositionRow>> {
        const kf = kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonPosition }, { type: ReportType.ButtonPosition });
        const query = combineQueries(ty, kf, tf);
        
		return (await http.get<IPagedResponse<IButtonPositionRow>>(`/statistics/activity?${query}`)).data;
	}
    
    async getButtonPositionExport(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<void> {
        const kf = kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonPosition }, { type: ReportType.ButtonPosition });
        const ex = encodeAsParams({ export: true }, { export: true });
        const query = combineQueries(ty, ex, kf, tf);
        
		const response = (await http.get<string>(`/statistics/activity?${query}`));
        this.saveCsv(response.data, this.getFileName(response, timeFilter, '_button-order.csv'));
	}
	
	async getButtonLabel(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<IPagedResponse<IButtonLabelRow>> {
        const kf = kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonLabel }, { type: ReportType.ButtonLabel });
        const query = combineQueries(ty, kf, tf);

		return (await http.get<IPagedResponse<IButtonLabelRow>>(`/statistics/activity?${query}`)).data;
	}

    async getButtonLabelExport(timeFilter?: ITimeFilter, kioskFilter?: IKioskFilter): Promise<void> {
        const kf = kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonLabel }, { type: ReportType.ButtonLabel });
        const ex = encodeAsParams({ export: true }, { export: true });
        const query = combineQueries(ty, ex, kf, tf);

		const response = (await http.get<string>(`/statistics/activity?${query}`));
        this.saveCsv(response.data, this.getFileName(response, timeFilter, '_button-label.csv'));
	}
}
export default new GlobalStatisticsService();