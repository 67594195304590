import { IAttribute } from "../../system/CRUDAttribute";
import { isValidIdentifier } from "../../system/FieldValidators";
import { isAuthorized, IUserContext, permissions } from "../../system/User.model";
import { ITag } from "../tags/Tags.model";
import TagSelect from "../tags/TagSelect";
import { IKiosk } from "./Kiosks.model";

// FIX ME: provide proper form component type
export const kioskAttributes: IAttribute<IKiosk, any>[] = [
	{ name: "id", type: "number", labelKey: "id", namespace: 'module.kiosks', sortable: true, hideInCreateForm: true, hideInUpdateForm: true, hideInTable: true },
	{ name: "identifier", labelKey: "identifier", namespace: 'module.kiosks', sortable: true, validator: isValidIdentifier, hideInUpdateForm: true },
	{ name: "name", labelKey: "name", namespace: 'module.kiosks', sortable: true },
	{ name: "description", labelKey: "description", namespace: 'module.kiosks', sortable: true, formComponent: "textarea" },
	{ name: "contact", labelKey: "contact", namespace: 'module.kiosks', sortable: true, formComponent: "textarea" },
	{ name: "lat", type: "number", labelKey: "latitude", namespace: 'module.kiosks', sortable: true },
	{ name: "lon", type: "number", labelKey: "longitude", namespace: 'module.kiosks', sortable: true },
	{ name: "tags", labelKey: "tags", namespace: 'module.kiosks', sortable: false, formComponent: TagSelect,
		tableValueResolver: (entity: IKiosk, _: keyof IKiosk) =>
				entity.tags?.map((t: ITag) => t?.name).join(", ") ?? ""}
];

export function hideTagsIfCannotModify(auth: IUserContext, attributes: IAttribute<IKiosk, any>[]) {
	return attributes.map((attr) => {
		if (attr.name !== 'tags')
			return attr;
		
		const canModifyTags = isAuthorized(auth, permissions.kiosk.modifyTags);
		return {
			...attr,
			hideInCreateForm: attr.hideInCreateForm || !canModifyTags,
			hideInUpdateForm: attr.hideInUpdateForm || !canModifyTags
		};
	});
}