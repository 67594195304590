import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import GridViewIcon from '@mui/icons-material/GridView';
import hoistStatics from "hoist-non-react-statics";
import { Box, /*ButtonGroup,*/ Tab, Tabs, Theme, Typography } from "@mui/material";
import DataTable, { Attribute } from "../../system/DataTable";
import PlaylistService from "./Boards.service";
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { IEntry, ISearchResult } from "../../system/SearchResult.model";
import { IBoard } from "./Boards.model";
import BoardsService from "./Boards.service";
import { CRUDButton } from "../../system/CRUDButton";
import { AuthContext } from "../../system/Base";
import { Modal } from "../../system/Modal";
/*import { CRUDForm } from "../../system/CRUDForm";
import { CRUDOperation } from "../../system/CRUDOperation";*/
import BoardChange from "./BoardChange";

import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { ENTITY_VISIBILITY_TYPE } from "../../system/CRUDTable";
import { IPermission } from "../users/Users.model";
import { ITableEntity } from "../../system/ICrudService";
/*import { ICrudActionDefinitions } from "../../system/CRUDAttribute";*/
import UserStrip from "../profile/UserStrip";

interface IState {
	currentEntity: IBoard | null
	createShown: boolean,
    updateShown: boolean,
	deleteShown: boolean,
	tabIndex: number,
	seed: number,
	updateReadOnly: boolean,
}
interface IProps extends WithTranslation { }

interface IBoardExt extends IBoard {
	actions: any // TODO
}

@IModule
class Boards extends React.Component<IProps, IState, WithTranslation> {

	dataTableRef: React.RefObject<DataTable<IBoard>>;
	boardService = BoardsService;

	constructor(props: IProps) {		
		super(props);
		this.state = {
			currentEntity: null,
			createShown: false,
			updateShown: false,
			deleteShown: false,
			updateReadOnly: false,
			tabIndex: 1,
			seed: 0
		};

		this.triggerRefresh = this.triggerRefresh.bind(this);
		this.showCreate = this.showCreate.bind(this);
        this.showUpdate = this.showUpdate.bind(this);
        //this.showDelete = this.showDelete.bind(this);

		this.colorView = this.colorView.bind(this);

		this.dataTableRef = React.createRef<DataTable<IBoard>>();
	} 
	
	public static getLocale() { return "module.boards"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Boards.getLocale()+".module-title", { ns: Boards.getLocale() }));
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Boards.getLocale()+".module-title", {ns:Boards.getLocale()})) || "???",
			route : "/boards",
			icon : <GridViewIcon />,
			weight : 80
		};
	}
	
	public static async search(input: string) {
		const res = await BoardsService.search(input);		
		return res.map((r: IEntry<IBoard>) : ISearchResult => { return {
			title : r.entity.title,
			description : '', // there's not much else to use for description...
			icon : <GridViewIcon />,
			link : "/boards?search-result-index=" + r.index
		}});
	}
	
	public static isEnabled (auth: IUserContext) {
		if (!hasController(auth, controllerKeys.board))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.board.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	async triggerRefresh() {
		await this.dataTableRef.current?.refresh();
	}

    async showCreate(entity: IBoard | null) {
        this.setState(() => ({
            currentEntity: entity,
            createShown: Boolean(entity)
        }),
        async () => {
            if (!entity) await this.triggerRefresh();
        });
    }

    async showUpdate(entity: IBoard | null, readOnly: boolean = false) {
        this.setState(() => ({
            currentEntity: entity,
			updateShown: Boolean(entity),
			updateReadOnly: readOnly,
        }), 
        async () => {
            if (!entity) await this.triggerRefresh();
        });
    }

    /*async showDelete(entity: IBoard | null) {
		if (!entity) await this.triggerRefresh();

        this.setState(() => ({
            currentEntity: entity,
            deleteShown: Boolean(entity)
        }));
	}*/
	
	colorView(color: string): JSX.Element {
		return <Box sx={(theme: Theme) => ({ color: theme.palette.getContrastText(color || "#FFF"), backgroundColor: color, p: '.3rem', borderRadius: '4px'})}>
			{color}
		</Box>;
	}

	render() {
		const t = this.props.t;
		const ns = Boards.getLocale();
		const modalWidth = { xs: 'min(90vw, 360px)', md: 'auto' };
		const modalHeight = '90vh';

		const AuthorField = (p: IBoardExt, k: keyof IBoardExt, perms: IPermission[] | undefined, fullEntity: ITableEntity<IBoardExt> | undefined) => <>
			{fullEntity?.access?.record.owner &&
				<UserStrip user={fullEntity?.access?.record.owner!} />
			}
		</>

		/*const CrudActions = (b: IBoardExt, _1: keyof IBoardExt, _2: IPermission[] | undefined, _3: ITableEntity<IBoardExt> | undefined, act: ICrudActionDefinitions | undefined) => <AuthContext.Consumer>
			{auth => <ButtonGroup variant="outlined">
				<CRUDButton variant="outlined" role="update" disabled={!isAuthorized(auth, permissions.board.update) || !isAuthorized(auth, permissions.media.read)}
					onClick={() => this.showUpdate(b)} />
				<CRUDButton variant="outlined" role="delete" disabled={!isAuthorized(auth, permissions.board.delete)}
					onClick={act?.deleteAction} />
				{this.state.tabIndex < 2 &&
					<CRUDButton variant="outlined" role="share" disabled={false}
						onClick={act?.shareAction} />
				}
				{this.state.tabIndex < 2 &&
					<CRUDButton variant="outlined" role="changeowner" disabled={false}
						onClick={act?.changeOwnerAction} />
				

				}								
			</ButtonGroup>}
		</AuthContext.Consumer>;*/

		return (<>
			<Typography variant="h2">
				{ t(Boards.getLocale()+".module-title", {ns:Boards.getLocale()}) }
			</Typography>

			<Tabs value={this.state.tabIndex} onChange={(_, i) => { this.setState({ tabIndex: i, seed: this.state.seed + 1 }); }} centered sx={{
				background: 'linear-gradient(0deg, rgba(128,128,128,0.1), transparent)',
				margin: '0px -25px'
			}}>
				<Tab icon={<FolderSpecialIcon />} label={t("owntype.owned", { ns: "common" })} />
				<Tab icon={<FolderSharedIcon />} label={t("owntype.supervised", { ns: "common" })} />
				<Tab icon={<DriveFileMoveIcon />} label={t("owntype.shared", { ns: "common" })} />
			</Tabs>

			{this.state.tabIndex === 0 && <AuthContext.Consumer>{(auth) =>
				<CRUDButton role="create" variant="contained" disabled={!isAuthorized(auth, permissions.board.create)}
					onClick={() => this.showCreate(this.boardService.createDefaultEntity())} />
			}
			</AuthContext.Consumer>}
			<AuthContext.Consumer>{(auth) => <DataTable ref={this.dataTableRef} service={this.boardService} entityName={t(`${ns}.entity`, { ns: ns })} entityType='boards' refreshSeed={this.state.seed}
				ownMode={this.state.tabIndex === 0 ? ENTITY_VISIBILITY_TYPE.OWNED : (this.state.tabIndex === 1 ? ENTITY_VISIBILITY_TYPE.SUPERVISED : ENTITY_VISIBILITY_TYPE.SHARED)}
				permissions={permissions.board} create={false} customEditMethod={ this.showUpdate }
				edit={isAuthorized(auth, permissions.board.update) && isAuthorized(auth, permissions.media.read)}
				delete={isAuthorized(auth, permissions.board.delete)} share={true} changeOwner={true} hideActions={false} >
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="title" labelKey="title" namespace={ns} />
				<Attribute name="image" labelKey="image" namespace={ns} />
				<Attribute name="color" labelKey="color" namespace={ns}
					tableValueResolver={(entity) => this.colorView(entity.color)} />

				<Attribute labelKey="owner" namespace="common" sortable={false} hideInCreateForm hideInUpdateForm
					tableValueResolver={AuthorField} hideInTable={this.state.tabIndex === 0} />

				{/*<Attribute name="actions" labelKey="actions" namespace={ns} sortable={false} tableValueResolver={CrudActions} />*/}
			</DataTable>
			}
			</AuthContext.Consumer>

			{this.state.createShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getCreateFormConfig().title} isOpen={this.state.createShown}
					width={modalWidth} height={modalHeight} onClose={async () => await this.showCreate(null)}>
                    
					<BoardChange entityId={this.state.currentEntity.id!} role='create' readOnly={false} onSubmit={async (board) => {
						await this.dataTableRef.current?.getCreateFormConfig().onSubmit(board);
						await this.showCreate(null);
					}} onClose={() => { this.showCreate(null); }} />
                </Modal>}

			{this.state.updateShown && this.state.currentEntity && this.dataTableRef.current &&
				<Modal title={this.state.updateReadOnly ? this.state.currentEntity.title : (this.dataTableRef.current.getUpdateFormConfig().title + ' ' + this.state.currentEntity.title)}
					isOpen={this.state.updateShown} width={modalWidth} height={modalHeight} onClose={async () => await this.showUpdate(null)}>
                    
					<BoardChange entityId={this.state.currentEntity.id!} role='update' readOnly={this.state.updateReadOnly } onSubmit={async (board) => {
						await this.dataTableRef.current?.getUpdateFormConfig().onSubmit(board);
						await this.showUpdate(null);
					}} onClose={() => { this.showCreate(null); }} />
                </Modal>}

			{/* this.state.deleteShown && this.state.currentEntity && this.dataTableRef.current &&
                <Modal title={this.dataTableRef.current.getDeleteFormConfig().title + ' ' + this.state.currentEntity.title}
					isOpen={this.state.deleteShown} onClose={() => this.showDelete(null)}>
                    
					<CRUDForm {...this.dataTableRef.current?.getDeleteFormConfig()}
                        entity={this.state.currentEntity}
                        role={CRUDOperation.Delete}
						attributes={[]}
						onSubmit={async (board) => {
							await this.dataTableRef.current?.getDeleteFormConfig().onSubmit(board);
							await this.showDelete(null);
						}} />
                </Modal> */}
			</>);
    }
}

export default hoistStatics(withTranslation()(Boards), Boards)