import http from "../../system/Communicator";
import { AxiosError, AxiosResponse } from 'axios';
import { IUser, IValidatedCredentials, ILoginCredentials, IPasswordChange, IPermission, IPermissionChange, createDefaultUser, IPasswordOverwrite } from "./Users.model"
import ICrudService, { ICrudServiceSmart, ITableEntity } from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { Sorting } from "../../system/CRUDTable";
import { combineQueries } from "../../system/RequestResponse.model";
import { IRole, createDefaultRole } from "./Role.model";

interface IRoleService extends ICrudServiceSmart<IRole> {
	getRolePermissions(roleId: number): Promise<IPermission[]>;
	getAllPermissions(): Promise<IPermission[]>;
	setRolePermissions(entity: IRole | number, permissions: IPermission[]): Promise<void>;
	groupByModule(permissions: IPermission[]): IPermission[][];
}

interface IRolePermissions {
	permissions: string[]
}

class RoleService implements IRoleService {

	async getAllPermissions(): Promise<IPermission[]> {
		return (await http.get<IPermission[]>("/permission")).data;
	}

	async getRolePermissions(roleId: number): Promise<IPermission[]> {
		return (await http.get<IPermission[]>("/role/" + roleId + "/permissions")).data;

	}

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IRole>): Promise<IPagedResponse<ITableEntity<IRole>>> {
		const pq = pageQuery?.toString() ?? '';
		
		if (sort?.constructor.name === "Object")
			sort = new Sorting<IRole>(sort.field, sort.direction === "ascending");
			
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq);

		return (await http.get<IPagedResponse<ITableEntity<IRole>>>(`/role?${query}`)).data;
	}
	
	async create(role: IRole): Promise<IRole> {
		const k = (await http.post<IRole>("/role", role)).data;
		if (role.permissions !== undefined) {
			await this.setRolePermissions(role, role.permissions || []);
		}
		return k;
	}

	async setRolePermissions(entity: IRole | number, permissions: IPermission[]): Promise<void> {
		let id = -1;
		if (typeof (entity) === 'number')
			id = entity;
		else
			if (!entity.id)
				return;
			else
				id = entity.id;
		
		let pId: string[] = permissions.filter(t => t.permission !== null && t.permission !== undefined).map(t => t.permission!);

		let ids: IRolePermissions = { permissions: pId };

		await http.post<IRolePermissions>("/role/" + id + "/set-permissions", ids);
	}
	
	async delete(entity: IRole): Promise<void> {
		await http.delete<void>("/role/" + entity.id!);
	}
	
	async edit(role: IRole): Promise<IRole> {
		const k = (await http.patch<IRole>("/role/" + role.id, this.pure(role))).data;
		if (role.permissions !== undefined) {
			await this.setRolePermissions(role, role.permissions || []);
			k.permissions = role.permissions;
		}
		return k;
	}

	createDefaultEntity = createDefaultRole;

	private pure(entity: IRole): IRole {
		let e = Object.assign({}, entity);
		delete e.permissions;
		return e;
	}
	
	groupByModule(permissions: IPermission[]): IPermission[][] {
		let grouped = new Map<string, IPermission[]>();

		for (let p of permissions) {
			if (!grouped.has(p.module))
				grouped.set(p.module, []);
			
			grouped.get(p.module)!.push(p);
		}
		return Array.from(grouped.values());
	}
	

  /*getAll() {
    return http.get<Array<ITutorialData>>("/tutorials");
  }
  get(id: string) {
    return http.get<ITutorialData>(`/tutorials/${id}`);
  }
  create(data: ITutorialData) {
    return http.post<ITutorialData>("/tutorials", data);
  }
  update(data: ITutorialData, id: any) {
    return http.put<any>(`/tutorials/${id}`, data);
  }
  delete(id: any) {
    return http.delete<any>(`/tutorials/${id}`);
  }
  deleteAll() {
    return http.delete<any>(`/tutorials`);
  }
  findByTitle(title: string) {
    return http.get<Array<ITutorialData>>(`/tutorials?title=${title}`);
  }*/
}
export default new RoleService();
export type { IRoleService };