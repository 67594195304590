import DashboardGlobal from './dashboard/DashboardGlobal';
import DashboardLocal from './dashboard/DashboardLocal';
import Test from './test/Test';
import Users from './users/Users';
import Kiosks from './kiosks/Kiosks';
import Tags from './tags/Tags';
import Files from './files/Files';
import Playlists from './playlists/Playlists';
import Content from './content/Content'
import Media from './media/Media'
import Whitelist from './whitelist/Whitelist'
import Settings from './settings/Settings'
import Boards from './boards/Boards';
import Statistics from './statistics/Statistics';
import Shared from './shared/Shared';
import Bulk from './bulk/Bulk';
import Admin from './admin/Admin';
import Profile from './profile/Profile';
import Manuals from './shared/Manuals';

const modules = [
	DashboardGlobal, DashboardLocal, Test, Users, Kiosks, Bulk, Tags, Files, Playlists, Content, Media, Shared, Whitelist, Settings, Boards, Statistics, Admin, Profile, Manuals
]

export default modules;