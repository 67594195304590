import React, { ReactElement, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AuthContext } from '../../system/Base';
import { Form, Formik } from 'formik';
import { Alert, Box, Button, CircularProgress, Collapse, FormControl, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Tab, Tabs, TextField } from '@mui/material';
import { Modal } from '../../system/Modal';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { INotificationProvider, INotificationSubscription, INotificationTrigger } from './Notifications.model';
import NotificationService from './Notifications.service';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import snackNotifications from '../../system/SnackBarUtils';
import { CRUDButton } from '../../system/CRUDButton';
import { TimePicker } from '@mui/x-date-pickers';
import { UserContext, isAuthorized, permissions } from '../../system/User.model';
import SaveIcon from '@mui/icons-material/Save';

enum Mode { Daily, Weekly, Monthly, Custom };

interface IState {
    tabIndex: number,
    providers: INotificationProvider[] | null,
    subscriptions: INotificationSubscription[] | null,
    originalSubscriptions: INotificationSubscription[],
    loaded: boolean
    expandedModule: string,
    deletingSub: INotificationSubscription | null,
    creatingSubProvider: string | null,
    creatingSubParameter: string,
    creatingSubTime: any, // Internally probably uses dayjs

    creatingMonthDay: number
    creatingWeekDay: string,
    creatingMode: Mode,

    internalCreationCounter: number,
    forCreation: INotificationSubscription[],
    forDeletion: INotificationSubscription[],
}

interface IProps extends WithTranslation {
    userId: number,
    autoSave: boolean,
    onDone?: () => void
}

class NotificationSettings extends React.Component<IProps, IState, WithTranslation> {
    public constructor(props: IProps) {
        super(props);
        this.state = {
            tabIndex: 0,
            providers: null,
            subscriptions: null,
            originalSubscriptions: [],
            loaded: false,
            expandedModule: '',
            deletingSub: null,
            creatingSubProvider: null,
            creatingSubParameter: '0 12 * * *',
            creatingSubTime: this.defaultSubTime(),
            creatingWeekDay: '* * 1',
            creatingMonthDay: 1,
            creatingMode: Mode.Daily,
            internalCreationCounter: -1,
            forCreation: [],
            forDeletion: []
        };

        this.page = this.page.bind(this);
        this.loadProviders = this.loadProviders.bind(this);
        this.loadSubscriptions = this.loadSubscriptions.bind(this);
        this.toggleModuleOpen = this.toggleModuleOpen.bind(this);
        this.currentTriggerType = this.currentTriggerType.bind(this);
        this.createSubscription = this.createSubscription.bind(this);
        this.deleteSubscription = this.deleteSubscription.bind(this);
        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleWeekDayChange = this.handleWeekDayChange.bind(this);
        this.handleMonthDayChange = this.handleMonthDayChange.bind(this);
        /*this.handleSelectChange = this.handleSelectChange.bind(this);*/
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadProviders();
        this.loadSubscriptions();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.userId !== this.props.userId) {
            this.setState({ subscriptions: null });
            this.loadSubscriptions();
        }
    }

    defaultSubTime() {
        let subDefaultTime = new Date();
        subDefaultTime.setHours(12);
        subDefaultTime.setMinutes(0);
        subDefaultTime.setSeconds(0);
        subDefaultTime.setMilliseconds(0);
        return subDefaultTime;
    }

    page(auth: UserContext, type: INotificationTrigger | null | undefined): ReactNode {
        if (type === null || type === undefined || !this.state.providers) return 'loading'; // Shoudln't happen

        let moduleTranslate = (module: string) => this.props.t('module.users.permission.controller.' + module.toLowerCase(), { ns: 'module.users' });

        let modules: string[] = [];
        let modulesData = new Map<string, INotificationProvider[]>();
        this.state.providers.filter(x => type in x.supportedTriggers).forEach(provider => {
            if (!modules.includes(provider.module)) {
                modules.push(provider.module);
                modulesData.set(provider.module, []);
            }
            modulesData.get(provider.module)?.push(provider);
        });

        return modules.map(module => <React.Fragment key={module}>
            <ListItem disablePadding>
                <ListItemButton onClick={() => this.toggleModuleOpen(module)} dense>
                    <ListItemIcon>
                        {this.state.expandedModule === module ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                    <ListItemText primary={moduleTranslate(module)} />
                </ListItemButton>
            </ListItem>
            <Collapse sx={{ ml: '3em' }} in={this.state.expandedModule === module} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        modulesData.get(module)?.map(provider => {
                            return <React.Fragment key={provider.identifier}><ListItem
                                    key={provider.identifier}
                                    sx={{ height: '36px', marginLeft: '25px' }}>
                                <ListItemText>
                                    {this.props.t('module.users.notif.' + provider.identifier, { ns: 'module.users' })}
                                </ListItemText>
                                <ListItemIcon>
                                    <Button disabled={!isAuthorized(auth, permissions.notifications.create)} sx={{ marginLeft: '50px' }} startIcon={<AddAlertIcon />} onClick={() => { this.setState({ creatingSubProvider: provider.identifier }); /*this.createSubscription(provider.identifier, "test");*/ }}>
                                        {this.props.t('common.add', { ns: 'common'}) }
                                    </Button>
                                </ListItemIcon>
                            </ListItem>
                                <Box sx={{ marginLeft: '50px', paddingTop: '0px', paddingBottom: '0px' }}>
                                    {this.state.subscriptions && this.state.subscriptions?.filter(x => x.trigger == this.currentTriggerType() && module === provider.module && x.provider === provider.identifier).map(sub => {
                                        return <ListItem key={sub.id} sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                            <ListItemIcon>
                                                <IconButton disabled={!isAuthorized(auth, permissions.notifications.delete)} onClick={() => {
                                                    if (this.props.autoSave)
                                                        this.setState({ deletingSub: sub });
                                                    else
                                                        this.deleteSubscription(sub);
                                                }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText>
                                                {this.readableCron(sub.triggerParameter)}
                                            </ListItemText>
                                        </ListItem>
                                    })}
                                </Box>
                            </React.Fragment>
                        })
                    }
                </List>
            </Collapse>
        </React.Fragment>)

        /*return <>{this.state.providers.filter(x => type in x.supportedTriggers).map(provider => <>
            {moduleTranslate(provider.module)}
        </>)}</>*/
    }

    toggleModuleOpen(module: string) {
        this.setState({ expandedModule: this.state.expandedModule === module ? '' : module });
    }

    async deleteSubscription(sub: INotificationSubscription) {
        //const resp = await NotificationService.deleteSubscription(sub);
        if (this.state.forCreation.find(x => x.id == sub.id)) {
            // Not a real subscription yet. Just delete it from virtual list:
            this.setState({
                deletingSub: null,
                forCreation: [...this.state.forCreation.filter(x => x.id !== sub.id)]
            }, this.maybeSaveAndLoad);
        } else {
            // Already existing real subscription
            this.setState({
                deletingSub: null,
                forDeletion: [...this.state.forDeletion, sub]
            }, this.maybeSaveAndLoad);
        }
    }

    async maybeSaveAndLoad() {
        if (this.props.autoSave)
            await this.save();
        this.loadSubscriptions();
    }

    async createSubscription(provider: string, parameter: string) {
        let sub: INotificationSubscription = {
            id: this.state.internalCreationCounter,
            userId: this.props.userId,
            provider: provider,
            trigger: INotificationTrigger.PERIODIC,
            triggerParameter: parameter
        };
        /*const err = await NotificationService.createSubscription(sub);
        if (err)
            snackNotifications.error(err); // TODO: Translations and stuff.*/
        this.setState({
            creatingSubProvider: null,
            internalCreationCounter: this.state.internalCreationCounter - 1,
            forCreation: [...this.state.forCreation, sub]
        }, this.maybeSaveAndLoad);
    }

    async save() {
        for (let i = 0; i < this.state.forDeletion.length; i++)
            await NotificationService.deleteSubscription(this.state.forDeletion[i]);
        for (let i = 0; i < this.state.forCreation.length; i++) {
            const err = await NotificationService.createSubscription(this.state.forCreation[i]);
            if (err)
                snackNotifications.error(err);
        }
        this.setState({
            forDeletion: [],
            forCreation: [],
            loaded: false,
            subscriptions: null,
            originalSubscriptions: []
        }, this.loadSubscriptions);

        if (this.props.onDone)
            this.props.onDone();
    }

    async loadProviders() {
        let p = await NotificationService.getAllProviders();
        this.setState({providers: p});
    }

    async loadSubscriptions() {
        if (!this.state.loaded) {
            let s = await NotificationService.getAllSubscriptions(this.props.userId);
            this.setState({ subscriptions: s, loaded: true, originalSubscriptions: s });
            // There can be no deadlock because user cant edit anything before first load completes.
        } else {
            this.setState({ subscriptions: [...this.state.originalSubscriptions, ...this.state.forCreation].filter(x => this.state.forDeletion.find(y => y.id === x.id) === undefined) });
        }
    }

    currentTriggerType() {
        const types = [
            INotificationTrigger.PERIODIC,
            INotificationTrigger.ON_CHANGE,
        ];

        return types[this.state.tabIndex];
    }

    getMAndH(obj: any) {
        if (obj.getMinutes) {
            return obj.getMinutes() + " " + obj.getHours()
        } else
                return (obj.minute || 0) + " " + (obj.hour || 0);
    }

    /*handleSelectChange(param: string) {
        if (param !== 'custom') {
            this.setState({
                creatingSubSelect: param,
                creatingSubParameter: this.getMAndH(this.state.creatingSubTime) + " " + param
            });
        } else {
            this.setState({ creatingSubSelect: param });
        }
    }*/

    handleTimeChange(time: any) {
        if (time !== null) {
            this.setState({
                creatingSubTime: time!
            }, this.refreshCronValue);
        }
    }

    handleModeChange(value: Mode) {
        this.setState({ creatingMode: value }, this.refreshCronValue);
    }

    handleWeekDayChange(value: string) {
        this.setState({ creatingWeekDay: value }, this.refreshCronValue);
    }

    handleMonthDayChange(value: number) {
        this.setState({ creatingMonthDay: value }, this.refreshCronValue);
    }

    refreshCronValue() {
        if (this.state.creatingMode === Mode.Custom)
            return; // Do not refresh anything.
        const t = this.getMAndH(this.state.creatingSubTime);
        if (this.state.creatingMode === Mode.Daily)
            this.setState({ creatingSubParameter: t + ' * * *' });
        if (this.state.creatingMode === Mode.Weekly)
            this.setState({ creatingSubParameter: t + ' ' + this.state.creatingWeekDay });
        if (this.state.creatingMode === Mode.Monthly)
            this.setState({ creatingSubParameter: t + ' ' + this.state.creatingMonthDay + ' * *' })
    }

    cronDays = {
        /*"1 * *": "notif-param-month-start",
        "15 * *": "notif-param-month-middle",*/
        "* * 1": "notif-param-week-monday",
        "* * 2": "notif-param-week-tuesday",
        "* * 3": "notif-param-week-wednesday",
        "* * 4": "notif-param-week-thursday",
        "* * 5": "notif-param-week-friday",
        "* * 6": "notif-param-week-saturday",
        "* * 7": "notif-param-week-sunday",
    }

    readableCron(param: string) {
        const args = param.split(' ');
        const t = (msg: string) => this.props.t('module.users.' + msg, { ns: 'module.users' });
        if (args.length === 5) {
            const time = args[1] + ':' + args[0].padStart(2, '0');
            const day = args[2] + ' ' + args[3] + ' ' + args[4];
            return this.readableCronDay(day, true) + ' ' + t('notif-param-in') + ' ' + time;
        } else
            return this.readableCronDay(param, true);
    }

    readableCronDay(param: string, suffix: boolean = false) {
        const t = (msg: string) => this.props.t('module.users.' + msg, { ns: 'module.users' });
        if (param in this.cronDays) {
            // Once per week
            //@ts-ignore
            const x: any = this.cronDays[param];
            return t(x);
        }
        else {
            if (param === '* * *') {
                return t('notif-mode.daily');
            }
            if (param.endsWith(' * *')) {
                const a = param.split(' ')[0] + '';
                let s = '';
                if (a.endsWith('1') || a.endsWith('2') || a.endsWith('3')) s = '-' + a[a.length - 1];
                return t('notif-param-month' + s).replace('{day}', a);
            } else
                return t('notif-param-custom') + (suffix ? ': ' + param : '');
        }
    }

    render() {
        const t = (msg: string) => this.props.t('module.users.' + msg, { ns: 'module.users' });
        const type = this.currentTriggerType();

        const form = <>
            {(this.state.providers === null || this.state.subscriptions === null) && (<Box sx={{ display: 'flex' }}>
			    <CircularProgress />
            </Box>)}
            {(this.state.providers !== null && this.state.subscriptions !== null) && <><Box sx={{ display: 'flex' }}><AuthContext.Consumer>{auth => <>
                <Tabs value={this.state.tabIndex} orientation='vertical' onChange={(_, i) => {
                    this.setState({ tabIndex: i })
                }}>
                    <Tab icon={<AccessTimeIcon />} label={t('notifications.periodic')} />
                    <Tab icon={<ElectricBoltIcon />} label={t('notifications.on-change')} />
                </Tabs>
                <Box sx={{ minHeight: '550px' }}>{this.page(auth, type)}</Box>
            </>}</AuthContext.Consumer></Box>
                {!this.props.autoSave && <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                    <Button sx={{ mt: '1rem' }} variant="contained" type="submit" startIcon={<SaveIcon />} onClick={this.save}>
                        {this.props.t('common.save', { ns: 'common' })}
                    </Button></Box>
                }
            </>
            }

            <Modal title={t('delete-notif')} isOpen={this.state.deletingSub !== null} onClose={() => this.setState({ deletingSub: null })}>
                <>
                    <p>{t("delete-notif-are-you-sure")}</p>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                        <CRUDButton variant="contained" role="delete" onClick={() => { this.deleteSubscription(this.state.deletingSub!); }} />
                    </Box>
                </>
            </Modal>

            <Modal title={t('create-notif') + ': ' + t('notif.' + this.state.creatingSubProvider ?? '')} isOpen={this.state.creatingSubProvider !== null} onClose={() => this.setState({ creatingSubProvider: null })}>
                <>
                    {/*<p>{t('create-notif-parameter')}</p>*/}

                    <FormControl variant="outlined" style={{ width: "100%" }}>
                        <InputLabel>{t('notif-mode')}</InputLabel>
                        <Select label={t('notif-mode')} fullWidth value={this.state.creatingMode} onChange={(e) => { this.handleModeChange(e.target.value as Mode); }}>
                            <MenuItem value={Mode.Daily} >{t('notif-mode.daily')}</MenuItem>
                            <MenuItem value={Mode.Weekly} >{t('notif-mode.weekly')}</MenuItem>
                            <MenuItem value={Mode.Monthly} >{t('notif-mode.monthly')}</MenuItem>
                            <MenuItem value={Mode.Custom} >{t('notif-mode.custom')}</MenuItem>
                        </Select>
                    </FormControl>
                    {this.state.creatingMode === Mode.Weekly &&
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                            <InputLabel>{t('notif-day')}</InputLabel>
                            <Select label={t('notif-day')} fullWidth value={this.state.creatingWeekDay} onChange={(e) => { this.handleWeekDayChange(e.target.value); }}>
                                {Object.keys(this.cronDays).map(key =>
                                    <MenuItem value={key}>{this.readableCronDay(key)}</MenuItem>
                                )};
                            </Select>
                        </FormControl>
                    }
                    {this.state.creatingMode === Mode.Monthly && <>
                        <TextField type="number" value={this.state.creatingMonthDay} fullWidth label={t('notif-day')} InputProps={{
                            inputProps: {
                                max: 31, min: 1
                        }
                        }} onChange={(e) => { this.handleMonthDayChange(parseInt(e.target.value)); }} />
                        { this.state.creatingMonthDay > 28 && <>
                            <Alert variant="filled" severity="warning">
                                { t('month-help') }
                            </Alert><br /></>
                        }</>
                    }
                    {this.state.creatingMode !== Mode.Custom &&
                        <TimePicker label={t('notif-time')} value={this.state.creatingSubTime} onChange={(e) => { this.handleTimeChange(e); }} renderInput={(props) => <TextField fullWidth {...props} />} />
                    }
                    {this.state.creatingMode === Mode.Custom &&
                        <TextField label={t('notif-param-custom')} fullWidth value={this.state.creatingSubParameter} onChange={(e) => { this.setState({ creatingSubParameter: e.target.value }) }}
                            helperText={t('cron-help')}></TextField>
                    }

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                        <CRUDButton variant="contained" role="create" onClick={() => { this.createSubscription(this.state.creatingSubProvider!, this.state.creatingSubParameter); }} />
                    </Box>

                </>
            </Modal>
        </>

        return form;
    }
}

export default withTranslation()(NotificationSettings)