import http from "../../system/Communicator";
import { IPagedResponse } from "../../system/Pagination.model";
import { INotificationSubscription, INotificationProvider } from "./Notifications.model";

interface INotificationService {
    getAllProviders(): Promise<INotificationProvider[]>;
    getAllSubscriptions(userId: number): Promise<INotificationSubscription[]>;
    createSubscription(subscription: INotificationSubscription): Promise<string | null>;
}

// This is not a CRUD service. It has some similiarities but it works differently.
class NotificationService implements INotificationService {
    async getAllProviders() {
        return (await http.get<INotificationProvider[]>("/notification/providers")).data;
    }

    async getAllSubscriptions(userId: number) {
        const page = (await http.get<IPagedResponse<INotificationSubscription>>("/notification/user/" + userId)).data;
        return page.data;
    }

    async createSubscription(subscription: INotificationSubscription) {
        const req = (await http.post<any>("/notification", subscription));
        const status = req.status;
        if (status.toString().startsWith("2"))
            return null;
        else return req?.data?.error || "Unknown error";
    }

    async deleteSubscription(subscription: INotificationSubscription) {
        const req = (await http.delete<any>("/notification/" + subscription.id));
        const status = req.status;
        if (status.toString().startsWith("2"))
            return null;
        else return req?.data?.error || "Unknown error";
    }
}

export default new NotificationService();
export type { INotificationService };