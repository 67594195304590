import { FieldProps } from "formik";
import { triggerFormChange } from "../../system/Formik.model";
import { FileData } from "chonky";
import { IUser } from "../users/Users.model";

interface IFileRecord {
	isDirectory : boolean,
	name : string,
	size : number,
	modified : Date | string,
	url : string,
	thumbnail? : string,
	hash : string,
	children?: number,
	valid?: boolean,
	invalidReason: string
}

interface IAccessRecordInfo {
	recordId: number;
	ownerId: number;
	owner: IUser;
	library: string;
	path: string;
}

interface IAccessSharingInfo {
	recordId: number;
	mode: 'READ_ONLY' | 'READ_WRITE'
	userId: number;
}

interface IAccessInfo {
	canRead: boolean;
	canWrite: boolean;
	canDelete: boolean;
	canShare: boolean;
	canUnshare: boolean;
	canChangeOwner: boolean;
	record: IAccessRecordInfo;
	sharing: IAccessSharingInfo[];
}

interface IFileRecordInfo {
	entry: IFileRecord,
	access: IAccessInfo
}

interface ISearchResult {
	isDirectory : boolean,
	name : string,
	path: string,
}

interface IFileEntries {
	entries : IFileRecordInfo[]
}

function onFileChangeFormikAdapter<T extends string | string[]>(values: T | undefined, props: FieldProps, undefinedReplacement?: T) {
	if (values === undefined && undefinedReplacement !== undefined)
		values = undefinedReplacement;
	triggerFormChange(props.form, props.field.name, values);
}

export type { IFileRecord, IFileEntries, ISearchResult }

export enum SHARING_TYPE {
	READ_ONLY = "READ_ONLY",
	READ_WRITE = "READ_WRITE"
}

const imageFilter = ["*.jpg", "*.JPG", "*.jpeg", "*.JPEG", "*.png", "*.PNG", "*.gif", "*.GIF", "*.svg", "*.SVG"];
const videoFilter = ["*.mp4", "*.MP4"];
const textFilter = ["*.link", "*.LINK", "*.txt", "*.TXT"];
const advancedTextFilter = textFilter.concat(["*.json", "*.JSON", "*.js", "*.JS", ".env", "*.sh", "*.SH", "*.css", "*.CSS"]);
const mediaFilter = imageFilter.concat(videoFilter);

function isType(filename: string, filter: string[]): boolean {
	let match = false;

	for (let extension of filter)
		match = match || filename?.endsWith(extension.replace('*', ''));
	return match;
}

function isVideo(filename: string): boolean {
	return isType(filename, videoFilter);
}

function isImage(filename: string): boolean {
	return isType(filename, imageFilter);
}

function filterToExtension(filter: string[]): string[] {
	let extensions = new Set<string>();
	for (let i = 0; i < filter.length; ++i)
		extensions.add(filter[i].toLowerCase().replace("*", "").replace(".", ""));
	return Array.from(extensions);
}

function getExtension(filename: string): string {
	return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
}

function hasCorrectExtension(filename: string, extensions: string[]): boolean {
	if (extensions.length === 0)
		return true;
	let fileExtension = getExtension(filename);
	return extensions.some(e => e === fileExtension);
}

function recordToData(record: IFileRecordInfo): FileData {
	return {
		id: record.entry.hash,
		name: record.entry.name,
		size: record.entry.size,
		modDate: record.entry.modified,
		isDir: record.entry.isDirectory,
		thumbnailUrl: record.entry.thumbnail || undefined,
		childrenCount: record.entry.children,
		url: record.entry.url,
		// Extra share info:
		resourceId: record.access.record.recordId,
		ownerId: record.access.record.ownerId,
		owner: record.access.record.owner,
		canRead: record.access.canRead,
		canWrite: record.access.canWrite,
		canDelete: record.access.canDelete,
		canShare: record.access.canShare,
		canUnshare: record.access.canUnshare,
		canChangeOwner: record.access.canChangeOwner,
		sharing: record.access.sharing,
		valid: record.entry.valid !== false,
		invalidReason: record.entry.invalidReason
	} as FileData;
}

function dataToRecord(data: FileData): IFileRecord {
	return {
		isDirectory: !!data.isDir, 
		name: data.name,
		size: data.size ?? 0,
		modified: data.modDate,
		url: data.url,
		thumbnail: data.thumbnailUrl,
		hash: data.id,
		children: data.childrenCount
	} as IFileRecord;
}

function formatSize(sizeBytes: number, locale: string): string {
	const formatFloat = (n: number) =>
		n.toLocaleString(locale, { maximumFractionDigits: 1});

	const kb = 1000;
	if (sizeBytes < kb)
        return formatFloat(sizeBytes) + ' B';
	if (sizeBytes < kb * kb)
		return formatFloat(sizeBytes / kb) + ' kB';
	if (sizeBytes < kb * kb * kb)
		return formatFloat(sizeBytes / (kb * kb)) + ' MB';
	return formatFloat(sizeBytes / (kb * kb * kb)) + ' GB';
	// larger sizes unlikely
}

export {
	onFileChangeFormikAdapter, filterToExtension, getExtension, hasCorrectExtension,
	recordToData, dataToRecord, isVideo, isImage, formatSize,
	imageFilter, videoFilter, mediaFilter, textFilter, advancedTextFilter
}
