import http from "../../system/Communicator";
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { combineQueries, encodeAsParams } from "../../system/RequestResponse.model";
import { IRemoteActivity, createDefaultTimeFilter, ReportType, IButtonPositionRow, IButtonLabelRow, ITimeFilter, IContentFilter, createDefaultContentFilter } from "./Statistics.model";
import { IKioskFilter } from "../kiosks/Kiosks.model";
import { BaseStatisticService } from "./Statistics.service";

class StatisticsService extends BaseStatisticService {

	async getAll(timeFilter?: ITimeFilter, _kioskFilter?: IKioskFilter, contentFilter?: IContentFilter, pageQuery?: PageQuery): Promise<IPagedResponse<IRemoteActivity>> {
		const pg = pageQuery?.toString() ?? '';
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const cf = contentFilter ? encodeAsParams(contentFilter, createDefaultContentFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.All }, { type: ReportType.All });
        const query = combineQueries(ty, pg, tf, cf);
        
		return (await http.get<IPagedResponse<IRemoteActivity>>(`/statistics/activity?${query}`)).data;
	}

    async getAllExport(timeFilter?: ITimeFilter, _kioskFilter?: IKioskFilter, contentFilter?: IContentFilter): Promise<void> {
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const cf = contentFilter ? encodeAsParams(contentFilter, createDefaultContentFilter()) : '';
        const ex = encodeAsParams({ export: true }, { export: true });
        const ty = encodeAsParams({ type: ReportType.All }, { type: ReportType.All });
        const query = combineQueries(ty, ex, tf, cf);
        
		const response = (await http.get<string>(`/statistics/activity?${query}`));
        this.saveCsv(response.data, this.getFileName(response, timeFilter, '_all-activity.csv'));
	}
    
    async getButtonPosition(timeFilter?: ITimeFilter, _kioskFilter?: IKioskFilter): Promise<IPagedResponse<IButtonPositionRow>> {
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonPosition }, { type: ReportType.ButtonPosition });
        const query = combineQueries(ty, tf);
        
		return (await http.get<IPagedResponse<IButtonPositionRow>>(`/statistics/activity?${query}`)).data;
	}
    
    async getButtonPositionExport(timeFilter?: ITimeFilter, _kioskFilter?: IKioskFilter): Promise<void> {
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonPosition }, { type: ReportType.ButtonPosition });
        const ex = encodeAsParams({ export: true }, { export: true });
        const query = combineQueries(ty, ex, tf);
        
		const response = (await http.get<string>(`/statistics/activity?${query}`));
        this.saveCsv(response.data, this.getFileName(response, timeFilter, '_button-order.csv'));
	}
	
	async getButtonLabel(timeFilter?: ITimeFilter, _kioskFilter?: IKioskFilter): Promise<IPagedResponse<IButtonLabelRow>> {
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonLabel }, { type: ReportType.ButtonLabel });
        const query = combineQueries(ty, tf);

		return (await http.get<IPagedResponse<IButtonLabelRow>>(`/statistics/activity?${query}`)).data;
	}

    async getButtonLabelExport(timeFilter?: ITimeFilter, _kioskFilter?: IKioskFilter): Promise<void> {
        const tf = timeFilter ? encodeAsParams(timeFilter, createDefaultTimeFilter()) : '';
        const ty = encodeAsParams({ type: ReportType.ButtonLabel }, { type: ReportType.ButtonLabel });
        const ex = encodeAsParams({ export: true }, { export: true });
        const query = combineQueries(ty, ex, tf);

		const response = (await http.get<string>(`/statistics/activity?${query}`));
        this.saveCsv(response.data, this.getFileName(response, timeFilter, '_button-label.csv'));
	}
}
export default new StatisticsService();