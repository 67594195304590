import React, { HTMLInputTypeAttribute } from "react";
import { IEntity, IFieldProps } from "./CRUDForm";
import { CRUDOperation } from "./CRUDOperation";
import { IPermission } from "../modules/users/Users.model";
import { ITableEntity } from "./ICrudService";
import { IShouldCloseContext } from "./SaveCloseButton";

interface IState { }

export interface ICrudActionDefinitions {
    updateAction: () => void
    deleteAction: () => void,
    shareAction: () => void,
    changeOwnerAction: () => void,
}

export interface IAttribute<TEntity extends IEntity, TComponentProps extends IFieldProps<TEntity, any> = IFieldProps<TEntity, any>> {
	name?: keyof TEntity,
    labelKey: string,
    namespace: string,
    sortable: boolean,
    validator?: (e: TEntity, k: keyof TEntity, o: CRUDOperation) => string | undefined,
    type?: HTMLInputTypeAttribute,
    formComponent?: string | React.ComponentType<TComponentProps> | React.ComponentType,
    tableValueResolver?: (e: TEntity, k: keyof TEntity, p: IPermission[] | undefined, fe: ITableEntity<TEntity> | undefined, crudActions: ICrudActionDefinitions | undefined) => string | JSX.Element,
    hideInCreateForm?: boolean,
    hideInUpdateForm?: boolean,
    hideInDeleteForm?: boolean,
    hideInTable?: boolean,
    readOnly?: boolean
}

export class CRUDAttribute<TEntity extends IEntity> extends React.Component<IAttribute<TEntity>, IState> {

    static defaultProps = {
        sortable: true,
    };

    render(): JSX.Element {
        return <></>;
    }
}