import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import FileExplorer from '../files/FileExplorer'
import IModule, { ModuleState } from "../../system/IModule"
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import FileService from '../files/Files.service';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { setPageTitle } from '../../App'
import { AuthContext } from '../../system/Base';
import MenuBookIcon from '@mui/icons-material/MenuBook';

interface IState { }
interface IProps extends WithTranslation { }

@IModule
class Shared extends React.Component<IProps, IState, WithTranslation> {

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Shared.getLocale()+".title-manuals", {ns:Shared.getLocale()})) || "???",
			route : "/shared",
			icon: <MenuBookIcon />,
			weight: 60,
			bottom: true
		};
	}
	
	public static getLocale() { return "module.shared"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Shared.getLocale()+".title-manuals", { ns: Shared.getLocale() }));
	}
	
	public static async search(input : string) {
		let results = await FileService.search("shared", input);
		if (results && results.map)
			return results.map(result => { return {
				title: result.name,
				description: result.path,
				icon: <InsertDriveFileIcon />,
				link: "/shared?search-file-path=" + result.path
			}});
		else return [];
	}
	
	public static isEnabled(auth: IUserContext) {
		if (!hasController(auth, controllerKeys.shared) || !hasController(auth, controllerKeys.kiosk, ControllerDeployment.Local))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.shared.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}
	
	render() {
		return (<Fragment>
		<Typography variant="h2">
			{ this.props.t(Shared.getLocale()+".title-manuals", {ns:Shared.getLocale()}) }
		</Typography>
		
        <AuthContext.Consumer>{auth => {
            const readonly = !isAuthorized(auth, permissions.shared.write);
            return <>
                <FileExplorer folder="shared" mode="full" readonly={readonly} allowCreate={readonly ? [] : [".link", ".txt"]} />
            </>
        }}
        </AuthContext.Consumer>

		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(Shared), Shared)