import React, { Fragment, ReactElement } from 'react';
import GroupIcon from '@mui/icons-material/Group';
import { Typography, Button, Tabs, Tab, TextField, MenuItem, IconButton, Tooltip, ButtonGroup } from '@mui/material';
import UserService from './Users.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import DataTable, { Attribute } from '../../system/DataTable'
import hoistStatics from 'hoist-non-react-statics';
import KeyIcon from '@mui/icons-material/Key';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { IErrors, IFieldProps } from '../../system/CRUDForm';
import { IUser } from './Users.model';
import { CRUDOperation } from '../../system/CRUDOperation';
import { Modal } from '../../system/Modal';
import PermissionChange from './PermissionChange';
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, isAuthorizedAny, IUserContext, permissions } from '../../system/User.model'
import PasswordOverwrite from './PasswordOverwrite';
import { AuthContext } from '../../system/Base';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GroupsIcon from '@mui/icons-material/Groups';
import RolesService from './Roles.service';
import { IRole, IScopedRole } from './Role.model';
import RolesChange from './RolesChange';
import TabPanel, { fireResize } from '../../system/TabPanel';
import UserStrip from '../profile/UserStrip';
import NotificationSettings from '../notifications/NotificationSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';

interface IState {
	editedUserId: number | null,
	editedRoleId: number | null,
	editingPassword: boolean,
	editingPermissions: boolean,
	editingRolesOfUser: boolean,
	editingNotifOfUser: boolean,
	tabIndex: number,
	users: IUser[],
	refreshSeed: number,
}
interface IProps extends WithTranslation { }

interface IUserExt extends IUser {
	passwordOverwrite: any, // TODO
	roleChange: string[]
}

interface IRoleExt extends IRole {
	permissionChange: any;
}

@IModule
class Users extends React.Component<IProps, IState, WithTranslation> {

	public constructor(props: IProps) {
		super(props);

		this.state = {
			editedUserId: null,
			editingPassword: false,
			editingPermissions: false,
			editingRolesOfUser: false,
			editingNotifOfUser: false,
			editedRoleId: null,
			tabIndex: 0,
			users: [],
			refreshSeed: 0,
		};

		this.validate = this.validate.bind(this);
		//this.onChange = this.onChange.bind(this);
	}

	public static getLocale() { return "module.users";	}
	
	async componentDidMount()
	{
		setPageTitle(this.props.t(Users.getLocale() + ".title", { ns: Users.getLocale() }));
		await this.reloadUsers();
	}

	async reloadUsers() {
		let users = (await UserService.getAll(undefined, undefined, true)).data;
		this.setState({ users: users, refreshSeed: this.state.refreshSeed + 1 });
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Users.getLocale()+".title", {ns:Users.getLocale()})) || "???",
			route : "/users",
			icon : <GroupIcon />,
			weight : 150
		};
	}
	
	public static search(input : string) {
		return null;
	}
	
	public static isEnabled(auth: IUserContext) {
		if (!hasController(auth, controllerKeys.user))
			return ModuleState.DISABLED;
		if (isAuthorizedAny(auth, permissions.user.read, permissions.user.create, permissions.role.read, permissions.role.create))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	/*onChange(user: IUser, operation: CRUDOperation, auth: IUserContext) {
		if (operation === CRUDOperation.Create && user.id && isAuthorized(auth, permissions.auth.modifyPermissions))
			this.showPermissionForm(user.id);
	}*/

	onChangeRole(user: IRole, operation: CRUDOperation, auth: IUserContext) {
		if (operation === CRUDOperation.Create && user.id && isAuthorized(auth, permissions.auth.modifyPermissions))
			this.showPermissionForm(user.id);
	}

	validate(values: IUser, operation: CRUDOperation) {
		let errors = {} as IErrors<IUser>;
		const e = this.props.t('validation.value-empty');

		if (operation !== CRUDOperation.Create && !values.id)
			errors.id = e;
		
		if (operation !== CRUDOperation.Delete) {
			if (!values.name)
				errors.name = e;
			
			if (!values.login)
				errors.login = e;
		}

		if (operation === CRUDOperation.Create && !values.password) {
			errors.password = e;
		}

		return errors;
	}

	showPasswordForm(userId: number | null) {
		this.setState({
			editedUserId: userId,
			editingPassword: userId ? true : false
		});
	}

	showRoleForm(userId: number | null) {
		this.setState({
			editedUserId: userId,
			editingRolesOfUser: !!userId
		});
	}

	showNotifForm(userId: number | null) {
		this.setState({
			editedUserId: userId,
			editingNotifOfUser: !!userId
		});
	}

	showPermissionForm(roleId: number | null, auth?: IUserContext) {
		const editedUserId = this.state.editedUserId;

		this.setState({
			editedRoleId: roleId,
			editingPermissions: roleId ? true : false
		}, () => {
			if (!roleId && editedUserId === auth?.id) {
				// FIX ME: This is a hack to reload auth permissions.
				// Reload is needed if the current user had changed their own permissions.
				window.location.reload();
			}
		});
	}

	render() {
		const t = this.props.t;
		const ns = Users.getLocale();

		const tt = (title: string, elem: any) => {
			if (elem.props.disabled) return elem; else return <Tooltip title={title}>{elem}</Tooltip>; }
		
		const PasswordOverwriteAction = (u: IUserExt) =>
			tt(t("module.users.set-password", { ns: 'module.users' }),
				<Button variant="outlined" type="submit" onClick={() => this.showPasswordForm(u.id!)}>
					<KeyIcon />
				</Button>);

		const RoleChangeAction = (u: IUserExt) => tt(t("module.users.set-roles", { ns: 'module.users' }),
			<Button variant="outlined" type="submit" onClick={() => { this.showRoleForm(u.id!) }}
				disabled={u.id === 1}>
				<AccountTreeIcon />
			</Button>);

		const NotifChangeAction = (u: IUserExt) => tt(t("module.users.set-notifications", { ns: 'module.users' }),
			<Button variant="outlined" type="submit" onClick={() => { this.showNotifForm(u.id!) }}>
				<NotificationsIcon />
			</Button>);

		const PermissionChangeAction = (u: IRoleExt) => <div>
			{u.id === 1 && t("module.users.all-permissions", {ns:'module.users'})}
			{u.id !== 1 &&
			<Button variant="outlined" type="submit" onClick={() => this.showPermissionForm(u.id!)} startIcon={<LocalPoliceIcon />}>
				{t("module.users.change-permissions", {ns:'module.users'})}
			</Button>}
		</div>;

		const SuperiorRenderer = (props: IFieldProps<IUser, string>) => ((props?.entity?.id || 0) !== 1 && <TextField
			fullWidth select
			value={props.field.value} name={props.field.name} onChange={props.field.onChange} onBlur={props.field.onBlur}
			label={t(`${props.namespace}.${props.labelKey}`, { ns: props.namespace })}>

			{this.state.users.filter(u => u.id !== props.entity.id).map(u => {
				return (<MenuItem value={u.id!} key={u.id!}>{u.name}</MenuItem>)
			})}
		</TextField>) || <></>;

		const SettingsAction = (u: IUserExt) => <ButtonGroup variant="outlined">
			{PasswordOverwriteAction(u)}
			{NotifChangeAction(u)}
			{RoleChangeAction(u)}
		</ButtonGroup>
	
		return (
		<Fragment>
		<Typography variant="h2">
			{ t(Users.getLocale()+".title", {ns:Users.getLocale()}) }
		</Typography>
		
		<AuthContext.Consumer>{(auth) => {
			const attributes = (<>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="name" labelKey="name" namespace={ns} tableValueResolver={(u: IUser) => <UserStrip user={u} />} />
				<Attribute name="login" labelKey="login" namespace={ns} tableValueResolver={(u: IUser) => <b>{u.login}</b>} />
				<Attribute name="email" labelKey="email" namespace={ns} />
				<Attribute name="password" type="password" labelKey="password" namespace={ns} sortable={false} hideInUpdateForm hideInTable />
				<Attribute name="superior" labelKey="supervisor" namespace={ns} formComponent={SuperiorRenderer}
					tableValueResolver={(u: any) => { return <>{u.id === 1? '': this.state.users.find(x => x.id === u.superior)?.name || '???'}</>; }} />

				{/*isAuthorized(auth, permissions.user.setPassword) && <Attribute name="passwordOverwrite" labelKey="password" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={PasswordOverwriteAction} />*/}

				<Attribute name="roles" labelKey="title.roles" namespace={ns} sortable={false} hideInUpdateForm hideInCreateForm
					tableValueResolver={(entity: IUser, _: keyof IUser) => <>{
						entity.roles?.map((t: IScopedRole, i: number) => <div key={i} className="role-row">{
							(hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global) &&
							   <><b>{t?.role.name + ": "}</b>{t?.scope?.name}</>)
							|| <>{t?.role.name}</>
						}</div>)}</> ?? <></>} />

				{/*(isAuthorized(auth, permissions.user.grantRole) || isAuthorized(auth, permissions.user.revokeRole)) &&
					<Attribute name="roleChange" labelKey="set-roles" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={RoleChangeAction} />
				*/}

				{  (isAuthorized(auth, permissions.user.grantRole) || isAuthorized(auth, permissions.user.revokeRole) ||
					isAuthorized(auth, permissions.user.setPassword) ||
					isAuthorized(auth, permissions.notifications.read)
				) &&
					<Attribute name="roleChange" labelKey="settings" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={SettingsAction} />
				}
				
				{ /*isAuthorized(auth, permissions.auth.modifyPermissions) && <Attribute name="permissionChange" labelKey="permissions" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={PermissionChangeAction} /> */}
					</>);

			const attributesRoles = (<>
				<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
				<Attribute name="name" labelKey="name" namespace={ns} />
				<Attribute name="description" labelKey="description" namespace={ns} />

				{isAuthorized(auth, permissions.role.update) && <Attribute name="permissionChange" labelKey="permissions" namespace={ns}
					sortable={false} hideInUpdateForm hideInCreateForm tableValueResolver={PermissionChangeAction} />}
			</>);
			
			return <><Tabs value={this.state.tabIndex} onChange={(_, i) => {
				this.setState({ tabIndex: i });
				fireResize();
			}} centered sx={{
					background: 'linear-gradient(0deg, rgba(128,128,128,0.1), transparent)',
					margin: '0px -25px'
				}}>
				<Tab icon={<GroupsIcon />} label={t("module.users.title.users", { ns: "module.users" })} />
				<Tab icon={<AccountTreeIcon />} label={t("module.users.title.roles", { ns: "module.users" })} />
			</Tabs>
			<TabPanel index={0} state={this.state.tabIndex}>
					<DataTable service={UserService} entityName={t("module.users.entity", { ns: 'module.users' })} entityType='users'
						firstRecordPermanent refreshSeed={this.state.refreshSeed}
					permissions={permissions.user} create={true} edit={true} delete={true} validate={this.validate}>
					{attributes.props.children.filter((attr: ReactElement | boolean | null) => attr)}
				</DataTable>
			</TabPanel>
			<TabPanel index={1} state={this.state.tabIndex}>
				<DataTable service={RolesService} entityName={t("module.users.entity.role", { ns: 'module.users' })} entityType='roles'
						permissions={permissions.role} create={true} edit={true} delete={true} firstRecordPermanent>
					{attributesRoles.props.children.filter((attr: ReactElement | boolean | null) => attr)}
				</DataTable>
			</TabPanel>
			</>
		}}
		</AuthContext.Consumer>

		<AuthContext.Consumer>{(auth) => { return <>

			{this.state.editedUserId && this.state.editingPassword &&
			<Modal title={t("module.users.set-password", {ns:'module.users'})} isOpen={true} onClose={() => this.showPasswordForm(null)}>
                <PasswordOverwrite userId={this.state.editedUserId} onDone={() => this.showPasswordForm(null)}/>
					</Modal>
			}

			{this.state.editedRoleId && this.state.editingPermissions &&
				<Modal width="lg" title={t("module.users.change-permissions", {ns:'module.users'})} isOpen={true} onClose={() => this.showPermissionForm(null)}>
				<AuthContext.Consumer>{(auth) => (
					<PermissionChange roleId={this.state.editedRoleId!} onDone={() => this.showPermissionForm(null, auth)} />
				)}</AuthContext.Consumer>
					</Modal>
			}

			{this.state.editedUserId && this.state.editingRolesOfUser &&
						<Modal title={t("module.users.set-roles", { ns: 'module.users' })} isOpen={true} onClose={() => this.showRoleForm(null)}>
							<RolesChange isGlobal={hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global) } userId={this.state.editedUserId} onDone={() => { this.showRoleForm(null); this.reloadUsers(); }} />
				</Modal>
			}

			{this.state.editedUserId && this.state.editingNotifOfUser &&
				<Modal width="820px" title={t("module.users.set-notifications", { ns: 'module.users' })} isOpen={true} onClose={() => this.showNotifForm(null)}>
					<NotificationSettings userId={this.state.editedUserId} onDone={() => this.showNotifForm(null)} autoSave={false} />
				</Modal>
			}
		</> }}
		</AuthContext.Consumer>
		
		</Fragment>);
	}
}

export default hoistStatics(withTranslation()(Users), Users)