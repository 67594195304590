import React, { Fragment } from 'react';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import IModule, { ModuleState } from "../../system/IModule"
import { setPageTitle } from '../../App'
import { ControllerDeployment, controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import { AuthContext } from '../../system/Base';
import { Modal } from '../../system/Modal';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AppsIcon from '@mui/icons-material/Apps';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import snackNotifications from '../../system/SnackBarUtils';
import BulkService from './Bulk.service';
import { Field, Form, Formik, FormikProps } from 'formik';
import KioskSelect from '../kiosks/KioskSelect';
import { IKiosk, IKioskFilter, IPreKioskFilter, processKiosks } from '../kiosks/Kiosks.model';
import KiosksService from '../kiosks/Kiosks.service';
import { IButton, createDefaultButton } from '../dashboard/Button.model';
import ButtonFields from '../dashboard/ButtonFields';
import { createDefaultScheduleException, IExceptionFilter, IScheduleException } from '../dashboard/Schedule.model';
import ScheduleExceptionFields from '../dashboard/ScheduleExceptionFields';
import { DateTime } from 'luxon';
import MuiDateTimeField from '../../system/MuiDateTimeField';
import BoardsService from '../boards/Boards.service';
import { IBoard } from '../boards/Boards.model';

interface IState {
	currentFilter?: IKioskFilter,
	loading: boolean,
	selectedKiosks: IKiosk[],
	showSelected: boolean
	
	boards: IBoard[]
	
    editButton: boolean
	addUptimeException: boolean
    addAudioException: boolean
	removeUptimeExceptions: boolean
	removeAudioExceptions: boolean
	
	button: IButton
	exception: IScheduleException
	kioskFilter: IPreKioskFilter,
}
interface IProps extends WithTranslation { }

@IModule
class Bulk extends React.Component<IProps, IState, WithTranslation> {

    bulkService = BulkService;

	formikRef: React.RefObject<FormikProps<IPreKioskFilter>>;

	constructor(props: IProps) {		
		super(props);
		this.state = {
			selectedKiosks: [],
			loading: false,
			showSelected: false,

			boards: [],

            editButton: false,
            addUptimeException: false,
            addAudioException: false,
            removeUptimeExceptions: false,
            removeAudioExceptions: false,

			button: createDefaultButton(),
			exception: createDefaultScheduleException(),
			kioskFilter: {
				kiosks: [],
				scopes: []
			},
		};

		this.onSubmit = this.onSubmit.bind(this);
		this.rebootKiosks = this.rebootKiosks.bind(this);
		this.restartApps = this.restartApps.bind(this);
		this.resyncKiosks = this.resyncKiosks.bind(this);
		this.editButton = this.editButton.bind(this);
		this.addException = this.addException.bind(this);
		this.addExceptionForm = this.addExceptionForm.bind(this);
		this.removeExceptions = this.removeExceptions.bind(this);
		this.removeExceptionsForm = this.removeExceptionsForm.bind(this);
		
		this.formikRef = React.createRef<FormikProps<IPreKioskFilter>>();
	}
	
	public static getLocale() { return "module.bulk";	}
	
	async componentDidMount()
	{
		setPageTitle(this.props.t( Bulk.getLocale()+".title", { ns: Bulk.getLocale() }));

		const boards = (await BoardsService.getAllUserCanUse());
		this.setState({ boards: boards.map(x => x.entity) });
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(this.getLocale()+".title", {ns:this.getLocale()})) || "???",
			route : "/bulk",
			icon : <OfflineShareIcon />,
			weight : 90
		};
	}
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.bulk, ControllerDeployment.Global))
			return ModuleState.DISABLED;
        if (isAuthorized(auth, permissions.kiosk.read) &&
			(isAuthorized(auth, permissions.kiosk.modifyUptimes) ||
			isAuthorized(auth, permissions.kiosk.modifyAudio) ||
			isAuthorized(auth, permissions.kiosk.modifyButtons) ||
            isAuthorized(auth, permissions.kiosk.reboot) ||
            isAuthorized(auth, permissions.kiosk.restart) ||
            isAuthorized(auth, permissions.kiosk.resync)))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}	
	
	public static search(input : string) {
		return null;
	}

    async rebootKiosks() {
        await this.bulkService.reboot(this.state.currentFilter);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }

    async restartApps() {
        await this.bulkService.restart(this.state.currentFilter);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }

    async resyncKiosks() {
        await this.bulkService.resync(this.state.currentFilter);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }
	
	async editButton(button: IButton) {
		await this.bulkService.updateButton(button, this.state.currentFilter);
		this.setState({ editButton: false });
		snackNotifications.success(this.props.t('common.request-completed', 'common'));
	}

	async addException(exception: IScheduleException) {
		exception.state = JSON.parse('' + exception.state); // convert to bool; FIX ME: solve universally

		if (this.state.addAudioException) {
			await this.bulkService.addAudioException(exception, this.state.currentFilter);
			this.setState({ addAudioException: false });
		} else {
			await this.bulkService.addUptimeException(exception, this.state.currentFilter);
			this.setState({ addUptimeException: false });
		}
		snackNotifications.success(this.props.t('common.request-completed', 'common'));
	}

	async removeExceptions(filter: IExceptionFilter) {
		if (this.state.removeAudioExceptions) {
			await this.bulkService.removeAudioExceptions(filter, this.state.currentFilter);
			this.setState({ removeAudioExceptions: false });
		} else {
			await this.bulkService.removeUptimeExceptions(filter, this.state.currentFilter);
			this.setState({ removeUptimeExceptions: false });
		}
		snackNotifications.success(this.props.t('common.request-completed', 'common'));
	}

	onSubmit(kioskFilter: IPreKioskFilter) {
		const kf = processKiosks(kioskFilter);
		let selected: IKiosk[] = [];

		this.setState({ loading: true }, async () => {	
			if (kioskFilter.kiosks && kioskFilter.kiosks.length > 0)
				selected = kioskFilter.kiosks;
			else if (kf.scopes && kf.scopes.length > 0)
	    		selected = await KiosksService.getScoped(kf.scopes);
			
			this.setState({
				loading: false,
				currentFilter: kf,
				selectedKiosks: selected.sort((a, b) => a.name.localeCompare(b.name)),
			});
		})
    }

	addExceptionForm() {
		return <Formik initialValues={this.state.exception} onSubmit={this.addException}>
			<Form>
				<Field name="" component={ScheduleExceptionFields} />
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button type='submit' color='primary' variant='contained' startIcon={<AddIcon />}>{this.props.t("common.add")}</Button>
				</Box>
			</Form>
		</Formik>;
	}

	removeExceptionsForm() {
		return <Formik initialValues={{ before: DateTime.now().toISO() }} onSubmit={this.removeExceptions}>
			<Form>
				<Field name="before" labelKey="remove-before" namespace={Bulk.getLocale()} convertUTC={false} component={MuiDateTimeField} />
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button type='submit' color='primary' variant='contained' startIcon={<DeleteIcon />}>{this.props.t("common.remove")}</Button>
				</Box>
			</Form>
		</Formik>;
	}

	render() {
		const t = this.props.t;
        const ns = "module.bulk";

		const disable = this.state.selectedKiosks.length === 0 || this.state.loading;

		return (<>
		<Typography variant="h2">
			{ this.props.t(Bulk.getLocale()+".title", {ns:Bulk.getLocale()}) }
		</Typography>

		<Formik innerRef={this.formikRef} initialValues={this.state.kioskFilter} onSubmit={this.onSubmit} validateOnBlur={true}>
			<Form>
				<Field name='' component={KioskSelect} />
				<Box sx={{ display: 'flex', columnGap: '16px' }}>
					<Button variant="contained" color="primary" startIcon={<DoneIcon />} type="submit">{t(`common.select`)}</Button>
					<Button variant="outlined" disabled={disable} onClick={() => this.setState({ showSelected: true })}>
						{`${t(`${ns}.selected-kiosks`, {ns})}: ${this.state.selectedKiosks.length}`}
					</Button>
				</Box>
			</Form>
		</Formik>

		{this.state.showSelected &&
            <Modal title={t(`${ns}.selected-kiosks`, {ns:ns})} isOpen={this.state.showSelected} onClose={() => this.setState({ showSelected: false })}>
				<List dense>
					{this.state.selectedKiosks.map(kiosk => 
						<ListItem dense key={kiosk.id}>{kiosk.name}</ListItem>
						)}
				</List>
			</Modal>}
		
		<AuthContext.Consumer>{(auth) => (<div className="kiosk-option-buttons settings-page">

            <Button variant="contained" color="info" startIcon={<SyncAltIcon />} onClick={this.resyncKiosks}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.resync)}>
				{t(`${ns}.sync-data`, { ns: ns }) + ''}
			</Button>
			
			<Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} onClick={this.restartApps}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.restart)}>
				{t(`${ns}.restart-app`, { ns: ns }) + ''}
			</Button>
			
			<Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} onClick={this.rebootKiosks}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.reboot)}>
				{t(`${ns}.reboot`, { ns: ns }) + ''}
			</Button>

            <Button variant="contained" color="primary" startIcon={<AppsIcon />} onClick={() => this.setState({ editButton: true })}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.modifyButtons)}>
				{t(`${ns}.edit-button`, { ns: ns }) + ''}
			</Button>

            <Button variant="contained" color="primary" startIcon={<ScheduleIcon />} onClick={() => this.setState({ addUptimeException: true })}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.modifyUptimes)}>
				{t(`${ns}.add-uptime-exception`, { ns: ns }) + ''}
			</Button>

            <Button variant="contained"  color="primary" startIcon={<VolumeUpIcon />} onClick={() => this.setState({ addAudioException: true })}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.modifyAudio)}>
				{t(`${ns}.add-audio-exception`, { ns: ns }) + ''}
			</Button>

			<Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => this.setState({ removeUptimeExceptions: true })}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.modifyUptimes)}>
				{t(`${ns}.remove-uptime-exceptions`, { ns: ns }) + ''}
			</Button>

            <Button variant="contained"  color="primary" startIcon={<DeleteIcon />} onClick={() => this.setState({ removeAudioExceptions: true })}
				disabled={disable || !isAuthorized(auth, permissions.kiosk.modifyAudio)}>
				{t(`${ns}.remove-audio-exceptions`, { ns: ns }) + ''}
			</Button>
		</div>
		)}</AuthContext.Consumer>
		
		{this.state.addUptimeException &&
            <Modal title={t(`${ns}.add-uptime-exception`, {ns:ns})} isOpen={this.state.addUptimeException} onClose={() => this.setState({ addUptimeException: false })}>
				{this.addExceptionForm()}
            </Modal>}
		
        {this.state.addAudioException &&
            <Modal title={t(`${ns}.add-audio-exception`, {ns:ns})} isOpen={this.state.addAudioException} onClose={() => this.setState({ addAudioException: false })}>
				{this.addExceptionForm()}
            </Modal>}
		
		{this.state.editButton &&
			<Modal title={t(`${ns}.edit-button`, {ns:ns})} isOpen={this.state.editButton} onClose={() => this.setState({ editButton: false })}>
				<Formik initialValues={this.state.button} onSubmit={this.editButton}>
					<Form>
						<Field name="" showPosition={true} boards={this.state.boards} component={ButtonFields} />
						<Box sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button type='submit' color='primary' variant='contained' startIcon={<SaveIcon />}>{t("common.save")}</Button>
						</Box>
					</Form>
				</Formik>
			</Modal>}

		{this.state.removeUptimeExceptions &&
            <Modal title={t(`${ns}.remove-uptime-exceptions`, {ns:ns})} isOpen={this.state.removeUptimeExceptions} onClose={() => this.setState({ removeUptimeExceptions: false })}>
				{this.removeExceptionsForm()}
            </Modal>}
		
		{this.state.removeAudioExceptions &&
            <Modal title={t(`${ns}.remove-audio-exceptions`, {ns:ns})} isOpen={this.state.removeAudioExceptions} onClose={() => this.setState({ removeAudioExceptions: false })}>
				{this.removeExceptionsForm()}
            </Modal>}

		</>);
	}
}

export default hoistStatics(withTranslation()(Bulk), Bulk)