import React, { Fragment } from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import TagsService from './Tags.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import DataTable, { Attribute } from '../../system/DataTable'
import { ITag } from './Tags.model'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import hoistStatics from 'hoist-non-react-statics';
import { IFieldProps } from '../../system/CRUDForm';
import { IIdentifiable } from '../../system/Identifiable.model';
import {IEntry, ISearchResult} from "../../system/SearchResult.model"
import SellIcon from '@mui/icons-material/Sell';
import IModule, { ModuleState } from "../../system/IModule"
import { controllerKeys, hasController, isAuthorized, isAuthorizedAny, IUserContext, permissions } from '../../system/User.model'
import KioskTree, { Mode } from '../kiosks/KioskTree';
import { setPageTitle } from '../../App'
import StyleIcon from '@mui/icons-material/Style';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import ScopesService from './Scopes.service';
import { IScope } from './Scopes.model';
import TagSelect from './TagSelect';
import { AuthContext } from '../../system/Base';
import TabPanel, { fireResize } from '../../system/TabPanel';

interface IRuntimeTag extends IIdentifiable {
	name: string,
	parent: number | null | string
}

interface IState {
	tags: ITag[],
	scopes: IScope[],
	tabIndex: number,
}
interface IProps extends WithTranslation { }

@IModule
class Tags extends React.Component<IProps, IState, WithTranslation> {
	
	dataTableRef: React.RefObject<DataTable<ITag>>;
	dataTableScopeRef: React.RefObject<DataTable<IScope>>;

	constructor(props : IProps) {
		super(props);
		this.state = {
			tags: [],
			scopes: [],
			tabIndex: 0,
		};

		this.reloadTags = this.reloadTags.bind(this);
		this.correctParentValue = this.correctParentValue.bind(this);

		this.dataTableRef = React.createRef<DataTable<ITag>>();
		this.dataTableScopeRef = React.createRef<DataTable<IScope>>();
	}
	
	public static getLocale() { return "module.tags"; }
	
	public static async search(input : string): Promise<ISearchResult[]> {
		let res = await TagsService.search(input);		
		return res.map((r: IEntry<ITag>) : ISearchResult => { return {
			title : r.entity.name,
			description : r.entity.identifier, // there's not much else to use for description...
			icon : <SellIcon />,
			link : "/tags?search-result-index=" + r.index
		}});
	}
	
	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Tags.getLocale()+".title", {ns:Tags.getLocale()})) || "???",
			route : "/tags",
			icon : <LocalOfferIcon />,
			weight : 40
		};
	}
	
	public static isEnabled(auth: IUserContext) {
		if (!hasController(auth, controllerKeys.tag))
			return ModuleState.DISABLED;
		if (isAuthorizedAny(auth, permissions.tag.read, permissions.tag.create, permissions.scope.read, permissions.scope.create))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}

	// The parent value obtained from select cannot be null (neither select nor React support it),
	// so it is necessary to manually set it to null before submitting the form
	private correctParentValue(runtimeEntity: IRuntimeTag) {
		if (runtimeEntity.parent === '' || runtimeEntity.parent === '-')
			runtimeEntity.parent = null;
	}
	
	async reloadTags() {
		const tags = (await TagsService.getAll()).data;
		const scopes = (await ScopesService.getAll()).data;
		this.setState({ tags: tags, scopes: scopes.map(x => x.entity as IScope) });
	}
	
	async componentDidMount() {
		setPageTitle(this.props.t( Tags.getLocale()+".title", { ns: Tags.getLocale() }));
		await this.reloadTags();
	}

	tagSelectForNonGlobals(props: IFieldProps<IScope, ITag[]>) {
		return ((props?.entity?.id || 0) !== 1 && <TagSelect className={''} form={props.form} field={props.field} labelKey={props.labelKey} namespace={props.namespace} ></TagSelect>) || <></>;
	};
	
	render() {
		const t = this.props.t;
		const ns = Tags.getLocale();

		const SelectTagParentRenderer = (props: IFieldProps<ITag, string>) => ((props.entity.id !== 1) && <TextField
			fullWidth select
			value={props.field.value ?? null} name={props.field.name} onChange={props.field.onChange} onBlur={props.field.onBlur}
			label={t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace})}>
			
			{ /* <MenuItem key='none' value='-'>-</MenuItem> */}
			{ this.state.tags.filter(tag => tag.id !== props.entity.id).map(tag => {
				return (<MenuItem value={tag.id!} key={tag.id!}>{tag.name}</MenuItem>)
			})}
		</TextField>) || <></>;

		return <AuthContext.Consumer>{(auth) => <>
			<Typography variant="h2">
				{t(Tags.getLocale() + ".title", { ns: Tags.getLocale() })}
			</Typography>

			<Tabs value={this.state.tabIndex} onChange={(_, i) => {
				this.setState({ tabIndex: i });
				fireResize();
			}} centered sx={{
				background: 'linear-gradient(0deg, rgba(128,128,128,0.1), transparent)',
				margin: '0px -25px'
				}}>
				<Tab icon={<StyleIcon />} label={t("module.tags.title.tags", { ns: "module.tags" })} disabled={!isAuthorizedAny(auth, permissions.tag.read, permissions.tag.create)} />
				<Tab icon={<GroupWorkIcon />} label={t("module.tags.title.scopes", { ns: "module.tags" })} disabled={!isAuthorizedAny(auth, permissions.scope.read, permissions.scope.create)} />
			</Tabs>

			<TabPanel index={0} state={this.state.tabIndex}>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8}>
							<DataTable ref={this.dataTableRef} service={TagsService} entityType='tags' entityName={t("module.tags.entity", { ns: 'module.tags' })} firstRecordPermanent
								customLock={(test, all) => { return test.id !== 1 && !all.some(x => x.entity.id === test.parent); }}
								permissions={permissions.tag} create={true} edit={true} delete={true} beforeSubmit={this.correctParentValue} onChange={this.reloadTags}>
								<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
								<Attribute name="name" labelKey="name" namespace={ns} />
								<Attribute name="parent" type="number" labelKey="parent" namespace={ns}
									tableValueResolver={(elem: any) => {
										if (elem.parent === null) return "";
										var par = this.state.tags.find(x => x.id === elem.parent);
										return par ? par.name : "???";
									}}
									formComponent={SelectTagParentRenderer} />
							</DataTable>
						</Grid>
						<Grid item xs={12} md={4}>
							<KioskTree mode={Mode.Tags} tags={this.state.tags} onTagClick={(id) => {
								const index = this.state.tags.findIndex((tag) => tag.id === id);
								this.dataTableRef.current?.selectEntry(index);
							}} />
						</Grid>
					</Grid>
				</Box>
			</TabPanel>

			<TabPanel index={1} state={this.state.tabIndex}>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={12}>
							<DataTable ref={this.dataTableScopeRef} service={ScopesService} entityType='scopes' entityName={t("module.tags.entity.scope", { ns: 'module.tags' })} firstRecordPermanent								
								permissions={permissions.scope} create={true} edit={true} delete={true} onChange={this.reloadTags}>
								<Attribute name="id" type="number" labelKey="id" namespace={ns} hideInCreateForm hideInUpdateForm hideInTable />
								<Attribute name="name" labelKey="scope.name" namespace={ns} />
								<Attribute name="description" labelKey="scope.description" namespace={ns} />
								<Attribute name="included" labelKey="scope.included" namespace={ns} sortable={false} formComponent={this.tagSelectForNonGlobals}
									tableValueResolver={(entity: IScope, _: keyof IScope) =>
										entity.included?.map((t: ITag) => t?.name).join(", ") ?? ""} />
								<Attribute name="excluded" labelKey="scope.excluded" namespace={ns} sortable={false} formComponent={this.tagSelectForNonGlobals}
									tableValueResolver={(entity: IScope, _: keyof IScope) =>
										entity.excluded?.map((t: ITag) => t?.name).join(", ") ?? ""} />
							</DataTable>
						</Grid>
					</Grid>
				</Box>
			</TabPanel>
		</>}</AuthContext.Consumer>;
	}
}

export default hoistStatics(withTranslation()(Tags), Tags)