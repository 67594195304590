import http from "../../system/Communicator";
import { ENTITY_VISIBILITY_TYPE, Sorting } from "../../system/CRUDTable";
import ICrudService, { ICrudServiceSmart, ITableEntity } from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { combineQueries } from "../../system/RequestResponse.model";
import { IEntry, ISearchable } from "../../system/SearchResult.model";
import { createDefaultPlaylist, IBannerSlide, IPlaylist, IRssSlide, IScheduleRecord, ISlide, isTagged, ITaggedScheduleRecord } from "./Playlists.model";

class PlaylistService implements ICrudServiceSmart<IPlaylist>, ISearchable<IPlaylist> {

	async search(text: string): Promise<IEntry<IPlaylist>[]> {
		const result = await this.getAll();
		return result.data
			.map((playlist, index) => { return { entity: playlist.entity, index: index } })
			.filter(x => x.entity.name.toLocaleLowerCase('cs').includes(text.toLocaleLowerCase('cs')));
	}

	async getAllMerged(): Promise<ITableEntity<IPlaylist>[]> {
		var a = await this.getAll(undefined, undefined, ENTITY_VISIBILITY_TYPE.OWNED);
		var b = await this.getAll(undefined, undefined, ENTITY_VISIBILITY_TYPE.SHARED);
		var c = await this.getAll(undefined, undefined, ENTITY_VISIBILITY_TYPE.SUPERVISED);

		return a.data.concat(b.data.concat(c.data));
	}

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IPlaylist>, mode?: ENTITY_VISIBILITY_TYPE): Promise<IPagedResponse<ITableEntity<IPlaylist>>> {
		const pq = pageQuery?.toString() ?? '';
		const sq = sort?.toString() ?? '';
		
		const query = combineQueries(pq, sq, 'mode=' + (mode || ENTITY_VISIBILITY_TYPE.OWNED));
		let playlists = (await http.get<IPagedResponse<ITableEntity<IPlaylist>>>(`/playlist?${query}`)).data;
		
		return playlists;
	}
	
	async getById(id: Number): Promise<IPlaylist> {
		return (await http.get<IPlaylist>(`/playlist/${id}`)).data;
	}
	
	async create(entity: IPlaylist): Promise<IPlaylist> {
		const playlist = (await http.post<IPlaylist>("/playlist", this.pure(entity))).data;
		
		if (entity.slides !== undefined) {
			await this.setSlides(playlist, entity.slides);
			playlist.slides = entity.slides;
		}
		if (entity.schedule !== undefined) {
			await this.setSchedule(playlist, entity.schedule);
			playlist.schedule = entity.schedule;
		}

		return playlist;
	}
	
	async delete(entity: IPlaylist): Promise<void> {
		await http.delete<void>("/playlist/" + entity.id);
	}
	
	async edit(entity: IPlaylist): Promise<IPlaylist> {
		let p = (await http.patch<IPlaylist>("/playlist/" + entity.id, this.pure(entity))).data;
		
		if (entity.slides !== undefined) {
			await this.setSlides(entity, entity.slides);
			p.slides = entity.slides;
		}
		if (entity.schedule !== undefined) {
			await this.setSchedule(p, entity.schedule);
			p.schedule = entity.schedule;
		}
		return p;
	}

	async setSlides(entity: IPlaylist, slides: (ISlide | IRssSlide | IBannerSlide)[]): Promise<void> {
		await http.post<void>("/playlist/" + entity.id + "/set-slides", slides);
	}

	async setSchedule(entity: IPlaylist, schedule: (IScheduleRecord | ITaggedScheduleRecord)[]): Promise<void> {
		let scheduleDto = [];
		for (let record of schedule) {
			
			if (isTagged(record)) {
				/*const include = record.include.map(tag => tag.id);
				const exclude = record.exclude.map(tag => tag.id);
				scheduleDto.push({ ...record, include: include, exclude: exclude });*/
				const scopes = record.scopes.map(scope => scope.id);
				scheduleDto.push({ ...record, scopes: scopes });
			} else {
				scheduleDto.push(record);
			}
		}

		await http.post<void>("/playlist/" + entity.id + "/set-schedule", scheduleDto);
	}

	async getContentAreas(): Promise<string[]> {
		return (await http.get<string[]>(`/playlist/area`)).data;
	}

	createDefaultEntity = createDefaultPlaylist;

	private pure(entity: IPlaylist): IPlaylist {
		let e = Object.assign({}, entity);
		delete e.slides;
		delete e.schedule;
		return e;
	}
}
export default new PlaylistService();