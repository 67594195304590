import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ITag } from './Tags.model';
import { FieldInputProps, FormikProps } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Tags from './Tags';
import { triggerFormChange } from '../../system/Formik.model';
import ScopesService from './Scopes.service';
import { IScope } from './Scopes.model';


interface IState { 
	scopes: IScope[]
}

interface IProps extends WithTranslation {
	field: FieldInputProps<IScope[]>,
    form: FormikProps<any>, // can be used versatilely
    labelKey?: string,
    namespace?: string,
	className: string,
	disabled?: boolean
}

class ScopeSelect extends React.Component<IProps, IState, WithTranslation> {

	constructor(props : IProps) {
		super(props);
		this.state = {
			scopes: []
		}

		this.setSelected = this.setSelected.bind(this);
		this.scopesEqual = this.scopesEqual.bind(this);
	}

	async componentDidMount() {
		var all = (await ScopesService.getAll()).data;
		this.setState({
			scopes: all.map(x => x.entity),
		});
	}

	scopesEqual(a: IScope, b: IScope) {
		return a.id === b.id;
	}

	setSelected(tags?: IScope[]) {
		triggerFormChange(this.props.form, this.props.field.name, tags, 'scopeSelect');
	}

	render() {
		return <Box className={'scopeSelect ' + this.props.className}>
			{this.state.scopes === null && (<Box sx={{ display: 'flex' }}>
				<CircularProgress />
			</Box>) }
		
			{this.state.scopes && <Autocomplete
				options={this.state.scopes}
				isOptionEqualToValue={this.scopesEqual}
				getOptionLabel={(scope: IScope) => scope.name}
				autoHighlight
				filterSelectedOptions
				multiple

				disabled={this.props.disabled}
				value={this.props.field.value}
				onChange={(_event, value, _reason, _details) => this.setSelected(value)}

				renderInput={(params) =>
				<TextField variant='outlined'
					{...params}
					fullWidth
					label={this.props.labelKey ? this.props.t(`${this.props.namespace}.${this.props.labelKey}`, {ns: this.props.namespace}) : undefined}
					placeholder={this.props.t(`${Tags.getLocale()}.scope-input-help`, {ns: Tags.getLocale()})}
					className='entityInputField'
					inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
				}
			/>}
		</Box>
	}
}

export default hoistStatics(withTranslation()(ScopeSelect), ScopeSelect)