import http from "../../system/Communicator";
import { combineQueries, encodeAsParams } from "../../system/RequestResponse.model";
import { IButton } from "../dashboard/Button.model";
import { IExceptionFilter, IScheduleException } from "../dashboard/Schedule.model";
import { createDefaultKioskFilter, IKioskFilter } from "../kiosks/Kiosks.model";


class BulkService {
	
	async reboot(kioskFilter?: IKioskFilter): Promise<void> {
		return (await http.post<void>(`/bulk/reboot?${this.getFilterQuery(kioskFilter)}`)).data;
	}
	
	async restart(kioskFilter?: IKioskFilter): Promise<void> {
		return (await http.post<void>(`/bulk/restart?${this.getFilterQuery(kioskFilter)}`)).data;
	}
	
	async resync(kioskFilter?: IKioskFilter): Promise<void> {
		return (await http.post<void>(`/bulk/resync?${this.getFilterQuery(kioskFilter)}`)).data;
	}

	async updateButton(button: IButton, kioskFilter?: IKioskFilter) {
		return (await http.patch<void>(`/bulk/update-button?${this.getFilterQuery(kioskFilter)}`,
			button
		)).data;
	}

	async addUptimeException(exception: IScheduleException, kioskFilter?: IKioskFilter) {
		return (await http.post<void>(`/bulk/add-uptime-exception?${this.getFilterQuery(kioskFilter)}`,
			exception
		)).data;
	}

	async addAudioException(exception: IScheduleException, kioskFilter?: IKioskFilter) {
		return (await http.post<void>(`/bulk/add-audio-exception?${this.getFilterQuery(kioskFilter)}`,
			exception
		)).data;
	}

	async removeUptimeExceptions(exceptionFilter: IExceptionFilter, kioskFilter?: IKioskFilter) {
		const q = combineQueries(this.getFilterQuery(kioskFilter), `before=${exceptionFilter.before}`);

		return (await http.delete<void>(`/bulk/remove-uptime-exceptions?${q}`)).data;
	}

	async removeAudioExceptions(exceptionFilter: IExceptionFilter, kioskFilter?: IKioskFilter) {
		const q = combineQueries(this.getFilterQuery(kioskFilter), `before=${exceptionFilter.before}`);
		
		return (await http.delete<void>(`/bulk/remove-audio-exceptions?${q}`)).data;
	}

	private getFilterQuery(kioskFilter?: IKioskFilter): string {
        return kioskFilter ? encodeAsParams(kioskFilter, createDefaultKioskFilter()) : '';
	}
}
export default new BulkService();