import React from 'react'
import { useMediaQuery, useTheme } from "@mui/material";

export interface IBreakpointInfo {
	xs: boolean,
	sm: boolean,
	md: boolean,
	lg: boolean,
	xl: boolean
}

export interface WithBreakpointProps {
    breakpoints: IBreakpointInfo,
}

export function withBreakpoints<
    P extends WithBreakpointProps = WithBreakpointProps,
>(Component: React.ComponentType<P>) {
	return (props: Omit<P, keyof WithBreakpointProps>) => {
		const theme = useTheme();
		const xs = useMediaQuery(theme.breakpoints.down('sm'));
		const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
		const md = useMediaQuery(theme.breakpoints.between('md', 'lg'));
		const lg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
		const xl = useMediaQuery(theme.breakpoints.up('xl'));

		return <Component {...(props as P)} breakpoints={{ xs, sm, md, lg, xl }} />;
	};
};