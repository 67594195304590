import axios, { AxiosInstance } from 'axios';

const fetchClient = () => {

  const defaultOptions = {
	baseURL: 'https://digipanel.cz', // TODO: use env?
    method: 'get',
    headers: {
      'Content-Type': 'text/plain',
    },
	setToken : () => {}
  };

  // Create instance
  let instance = axios.create(defaultOptions);
  return instance;
};


class SoftwareVersionService {
    private http: AxiosInstance;

    private currentDPBranch = new Map<string, string>();
    private currentDPContent = new Map<string, string>();
    private currentBackend = new Map<string, string>();
    private currentFrontend: string | undefined = undefined;

    constructor() {
        this.http = fetchClient();
    }

    async isDPBranchUpToDate(revision?: string): Promise<boolean> {
        if (!revision) return false;

        const gitBranch = this.getGitBranch(revision);
        if (this.currentDPBranch.has(gitBranch))
            return this.matches(revision, this.currentDPBranch.get(gitBranch));

        const current = (await this.http.get<string>(`/digipanel/${gitBranch}/digipanel.info`)).data?.trim();
        this.currentDPBranch.set(gitBranch, current);
        return this.matches(revision, current);
    }
    
    async isDPContentUpToDate(revision?: string): Promise<boolean> {
        if (!revision) return false;

        const gitBranch = this.getGitBranch(revision);
        if (this.currentDPContent.has(gitBranch))
            return this.matches(revision, this.currentDPContent.get(gitBranch));

        const current = (await this.http.get<string>(`/digipanel/${gitBranch}/digipanel-content.info`)).data?.trim();
        this.currentDPContent.set(gitBranch, current);
        return this.matches(revision, current);
    }
    
    async isBackendUpToDate(branch?: string, revision?: string): Promise<boolean> {
        if (!branch || !revision) return false;
        
        if (this.currentBackend.has(branch))
            return this.matches(revision, this.currentBackend.get(branch));
    
        const branchPath = branch === "main" ? "" : `-${branch}`;
        const current = (await this.http.get<string>(`/digipanel-admin/digipanel-backend${branchPath}/version`)).data?.trim();
        
        this.currentBackend.set(branch, current);
        return this.matches(revision, current);
    }

    async isFrontendUpToDate(revision?: string): Promise<boolean> {
        if (!revision) return false;

        if (this.currentFrontend)
            return this.matches(revision, this.currentFrontend);

        const current = (await this.http.get<string>(`/digipanel-admin/digipanel-frontend/version`)).data?.trim();
        this.currentFrontend = current;
        return this.matches(revision, current);
    }

    private getGitBranch(revision: string): string {
        return revision.split(" ")[0];
    }
    
    // amos 2022-06-06 12:19:51 1a79316def4a40ed9e416bbe02c801a5dae55562
    // amos 2022-06-06 12:19:51
    // 2022-06-06 12:19:51 1a79316def4a40ed9e416bbe02c801a5dae55562
    private matches(revisionA?: string, revisionB?: string): boolean {
        if (!revisionA || !revisionB)
            return false;

        const partsA = revisionA.split(" ");
        const partsB = revisionB.split(" ");

        for (let i = 0; i < Math.min(partsA.length, partsB.length); ++i)
            if (partsA[i] !== partsB[i])
                return false;
        return true;
    }
}

export default new SoftwareVersionService();