import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CustomThemeProvider from './system/CustomThemeProvider'
import BrandingProvider from './system/BrandingProvider'
import { ShouldCloseContextProvider } from './system/SaveCloseButton';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<ShouldCloseContextProvider>
			<BrandingProvider>
				<CustomThemeProvider>
					<App />
				</CustomThemeProvider>
			</BrandingProvider>
		</ShouldCloseContextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
