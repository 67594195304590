import React, { ImgHTMLAttributes, useState } from 'react';
import IModule, { ModuleState } from "../../system/IModule"
import { IUserContext, isAuthorized, permissions } from '../../system/User.model'
import { setPageTitle } from '../../App'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import hoistStatics from "hoist-non-react-statics";
import { AuthContext } from '../../system/Base';
import UserAvatar from './UserAvatar';
import KeyIcon from "@mui/icons-material/Key";
import PasswordChange from '../users/PasswordChange';
import { Modal } from '../../system/Modal';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import UsersService, { AccessList } from '../users/Users.service';
import { CRUDButton } from '../../system/CRUDButton';
import Dropzone, { IDropzoneProps, IFileWithMeta } from 'react-dropzone-uploader';
import { API_URL } from '../../system/Communicator';
import PersonIcon from '@mui/icons-material/Person';
import TabPanel, { fireResize } from '../../system/TabPanel';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationSettings from '../notifications/NotificationSettings';

interface IState {
	editingPassword: boolean,
	uploadingAvatar: boolean,
	deletingAvatar: boolean,
	userInfo?: AccessList,
	lastDropZoneEvent: Date,
	refreshSeed: number,
	tabIndex: number,
}
interface IProps extends WithTranslation { }

interface IProps extends WithTranslation {

}

@IModule
class Profile extends React.Component<IProps, IState, WithTranslation> {
	

	constructor(props: IProps) {
		super(props);
		this.state = {
			editingPassword: false,
			uploadingAvatar: false,
			deletingAvatar: false,
			lastDropZoneEvent: new Date(),
			refreshSeed: 0,
			tabIndex: 0
		}
		this.showPasswordForm = this.showPasswordForm.bind(this);
		this.deleteAvatar = this.deleteAvatar.bind(this);
		this.showDeleteAvatar = this.showDeleteAvatar.bind(this);
		this.showUploadAvatar = this.showUploadAvatar.bind(this);
		this.handleAvatarUpload = this.handleAvatarUpload.bind(this);
		this.loadUser = this.loadUser.bind(this);
		this.getUploadParams = this.getUploadParams.bind(this);
		this.handleChangeStatus = this.handleChangeStatus.bind(this);
	}

	async componentDidMount() {
		setPageTitle(this.props.t(Profile.getLocale() + ".profile-title", { ns: Profile.getLocale() }));
		this.loadUser();
	}

	async componentWillUnmount() {		
	}
	
	public static getLocale() { return "module.users"; }
	
	public static isEnabled(auth: IUserContext) {
		return ModuleState.ENABLED_HIDDEN;
	}	

	public static menu(t: ((x: string, y: any) => string)) {
		return {
			title: (t && t(Profile.getLocale() + ".profile-title", { ns: Profile.getLocale() })) || "???",
			route : "/profile",
			icon: <AccountCircleIcon />,
			weight : 200
		};
	}
	
	public static search(input : string) {
		return null;
	}

	showPasswordForm(show: boolean) {
		this.setState({
			editingPassword:show
		});
	}

	showDeleteAvatar(show: boolean) {
		this.setState({
			deletingAvatar: show
		});
	}

	showUploadAvatar(show: boolean) {
		this.setState({
			uploadingAvatar: show,
		});
	}

	async deleteAvatar() {
		await UsersService.deleteCurrentAvatar();
		this.showDeleteAvatar(false);
		this.setState({
			refreshSeed: this.state.refreshSeed + 1,
		});
	}

	async loadUser() {
		let userInfo = await UsersService.getCurrentAccess();
		this.setState({ userInfo: userInfo });
	}

	handleAvatarUpload(files: any[], allFiles: any[]) {
		allFiles.forEach(f => f.remove());
		this.showUploadAvatar(false);
		this.setState({
			refreshSeed: this.state.refreshSeed + 1,
		});
	}

	getUploadParams(fileWithMeta: IFileWithMeta) {
		this.setState({ lastDropZoneEvent: new Date() }); // Force refresh			
		return {
			url: API_URL + "/user/image",
			headers: {
				'Authorization': sessionStorage.getItem('dp2_token') || ""
			}
		};
	};

	handleChangeStatus() {
		this.setState({ lastDropZoneEvent: new Date() }); // Force refresh
	}
	
	render() {	
		const t = this.props.t;
		const ns = Profile.getLocale();
		const profileHeaders = {
			borderBottom: '1px solid rgba(128,128,128,0.35)',
			marginBottom: '10px',
			marginTop: '30px'
		};
		return <AuthContext.Consumer>
			{auth => <>

				<Typography variant="h2" sx={{ mb: '20px' }}>
					{auth.name}
				</Typography>

				<Tabs value={this.state.tabIndex} onChange={(_, i) => {
					this.setState({ tabIndex: i });
					fireResize();
				}} centered sx={{
					background: 'linear-gradient(0deg, rgba(128,128,128,0.1), transparent)',
					margin: '0px -25px'
				}}>
					<Tab icon={<PersonIcon />} label={t("module.users.profile", { ns: "module.users" })} />
					<Tab icon={<NotificationsIcon />} label={t("module.users.notifications", { ns: "module.users" })}
						disabled={!isAuthorized(auth, permissions.notifications.read)} />
				</Tabs>
				<TabPanel index={0} state={this.state.tabIndex}>
					<Typography variant="h4" sx={profileHeaders}>
						{t(`${ns}.avatar`, { ns: ns }) + ''}
					</Typography>

					<Box sx={{ display: 'flex', gap: '10px' }}>
						<UserAvatar userId={auth.id} className="profile-avatar" refreshSeed={this.state.refreshSeed} />
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
							<Button variant="contained" type="submit" onClick={() => this.showUploadAvatar(true)} startIcon={<UploadIcon />}>
								{t("module.users.upload-avatar", { ns: ns })}
							</Button>
							<Button variant="outlined" color="secondary" type="submit" onClick={() => this.showDeleteAvatar(true)} startIcon={<DeleteIcon />}>
								{t("module.users.delete-avatar", { ns: ns })}
							</Button>
						</Box>
					</Box>

					<Typography variant="h4" sx={profileHeaders}>
						{t(`${ns}.basic-info`, { ns: ns }) + ''}
					</Typography>

					<table className="kioskInfo userInfo">
						<tbody>
							<tr>
								<td>
									ID
								</td>
								<td>
									{this.state.userInfo?.user.id}
								</td>
							</tr>
							<tr>
								<td>
									{t(`${ns}.login`, { ns: ns }) + ''}
								</td>
								<td>
									{this.state.userInfo?.user.login}
								</td>
							</tr>
							<tr>
								<td>
									{t(`${ns}.name`, { ns: ns }) + ''}
								</td>
								<td>
									{this.state.userInfo?.user.name}
								</td>
							</tr>
							<tr>
								<td>
									{t(`${ns}.email`, { ns: ns }) + ''}
								</td>
								<td>
									{this.state.userInfo?.user.email}
								</td>
							</tr>
							<tr>
								<td>
									{t(`${ns}.password`, { ns: ns }) + ''}
								</td>
								<td>
									<Button variant="contained" type="submit" onClick={() => this.showPasswordForm(true)} startIcon={<KeyIcon />}>
										{t("module.users.change-password", { ns: ns })}
									</Button>
								</td>
							</tr>
						</tbody>
					</table>

					<Typography variant="h4" sx={profileHeaders}>
						{t(`${ns}.roles`, { ns: ns }) + ''}
					</Typography>
					<table className="kioskInfo userInfo">
						<tbody>
							{this.state.userInfo?.roles.map((role, ix) => {

								return <tr key={ix}>
									<td>
										{role.role.name}
									</td>
									<td>
										{role.scope?.name}
									</td>
								</tr>
							})}

						</tbody>
					</table>
				</TabPanel>
				
				<TabPanel index={1} state={this.state.tabIndex}>
					<Typography variant="h4" sx={profileHeaders}>
						{t(`${ns}.notifications`, { ns: ns }) + ''}
					</Typography>
					<NotificationSettings userId={auth.id} autoSave={true} />
				</TabPanel>
				

				{this.state.editingPassword &&
					<Modal title={t("module.users.change-password", { ns: 'module.users' })} isOpen={true} onClose={() => this.showPasswordForm(false)}>
						<PasswordChange userId={auth.id} onDone={() => this.showPasswordForm(false)} />
					</Modal>
				}

				{this.state.deletingAvatar &&
					<Modal title={t("module.users.delete-avatar", { ns: 'module.users' })} isOpen={true} onClose={() => this.showDeleteAvatar(false)}>
						<p>{t("module.users.delete-avatar-are-you-sure", { ns: 'module.users' })}</p>
						<Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
							<CRUDButton variant="contained" role="delete" onClick={() => { this.deleteAvatar(); }} />
						</Box>
					</Modal>
				}

				{this.state.uploadingAvatar &&
					<Modal title={t("module.users.upload-avatar", { ns: 'module.users' })} isOpen={true} onClose={() => { this.handleAvatarUpload([], []); }}>
						<div className="modal-file-upload"><Dropzone multiple={false} maxFiles={1}
							getUploadParams={this.getUploadParams}
							onSubmit={this.handleAvatarUpload}
							onChangeStatus={this.handleChangeStatus}
							submitButtonContent={t("module.files.upload-done", { ns: 'module.files'})}
							inputContent={t("module.users.upload-avatar-input", { ns: 'module.users' })}
						/></div>

					</Modal>
				}
			</>}
			</AuthContext.Consumer>;
	}
}

export default hoistStatics(withTranslation()(Profile), Profile)