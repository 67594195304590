import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import { API_URL } from "../../system/Communicator";
import { IStatus, LicenseState, StatusParser } from "../kiosks/Kiosks.model";
import { Box, Table, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Modal } from '../../system/Modal';

interface IState { 
	lastRefresh: Date, // This is mainly to force re-render
	showLicenseDetail: boolean,
}
interface IProps extends WithTranslation { 
	kioskId?: number,
	status?: IStatus,
	showLicence: boolean,
}

class KioskPreview extends React.Component<IProps, IState, WithTranslation> {
	
	refreshInterval : any = null // timeinterval
	
	constructor(props: IProps) {		
		super(props);
		this.state = {
			lastRefresh: new Date(),
			showLicenseDetail: false,
		};

		this.refresh = this.refresh.bind(this);
	}
	
	componentDidMount()
	{
		this.refreshInterval = setInterval(this.refresh, 60 * 1000);
		// Don't do refresh on load. Default render is fine.
	}
	
	componentWillUnmount()
	{
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval);
			this.refreshInterval = null;
		}
	}
	
	refresh()
	{
		this.setState({ lastRefresh : new Date() });
	}
	
	render() {	
	
		let url = API_URL + "/kiosk/"
		if (this.props.kioskId)
			url += this.props.kioskId + "/";
		url += "screen?seed=" + this.state.lastRefresh.getTime();
		
		const transparent = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
		const styleWrapper = { height: { xs: '570px !important', lg: '355px !important' }, overflow: 'hidden' };
		//const styleWrapper = { overflow: 'hidden' };
		const styleInner = { maxHeight: { xs: '500px !important', lg: '285px !important' }};
		
		const t = this.props.t;

		const license = this.props.status?.licence;

		const kioskState = StatusParser.getKioskState(this.props.status);
		const licenseState = StatusParser.getLicenseState(this.props.status);
		const licenseDuration = StatusParser.getLicenseDuration(this.props.status);
		const from = licenseDuration?.from.toLocaleString(DateTime.DATE_SHORT, {locale: this.props.i18n.language});
		const to = licenseDuration?.to.toLocaleString(DateTime.DATE_SHORT, {locale: this.props.i18n.language});
		const placeholder = '?';

		let color = '';
		let licenseText = '';
		switch (licenseState) {
			case LicenseState.Valid:
				color = 'success';
				licenseText = t('license.valid');
				break;
			case LicenseState.Expired:
				color = 'error';
				licenseText = t('license.expired');
				break;
			case LicenseState.Invalid:
				licenseText = t('license.invalid');
				color = 'warning';
				break;
			case LicenseState.Unknown:
				licenseText = t('license.not-found');
				color = 'warning';
		}

		let tags = ((license?.info?.tags || []).join(',') ?? '');
		if (tags) tags = '(' + tags + ')';

		let res = this.props.status?.screen?.resolution?.toLowerCase().split('x')
		let resW = "";
		let resH = "";
		if (res?.length === 2) {
			resW = res[0].trim();
			resH = res[1].trim();
		}
	
		return (<Box className={"kiosk-screenshot-wrapper " + kioskState}
			sx={{ ...styleWrapper, mb: { xs: 2, md: 0 } }}
			data-screen-port={this.props.status?.screen?.port}
			data-screen-resolution={this.props.status?.screen?.resolution}
			data-screen-resolution-width={ resW }
			data-screen-resolution-height={ resH }
			data-screen-orientation={this.props.status?.screen?.orientation}
			>
			{/* Layers are only for CSS branding, they are not styled by default and don't do anything. */}
			<Box className="layer-back-1" sx={styleInner}>
				<div className="layer-back-2">
					<div className="layer-back-3">
						<img src={ url } alt="kiosk screenshot" onError={({currentTarget }) => { 
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = transparent;
						}} />
						<div className="layer-front-1">
							<div className="layer-front-2">
								<div className="layer-front-3">
								</div>
							</div>
						</div>
					</div>
				</div>
			</Box>
			{this.props.showLicence && <Box sx={{
				color: color + '.contrastText',
				bgcolor: color + '.main',
				borderRadius: 1,
				textAlign: 'center',
				mt: 1,
				p: 1,
				height: 'auto !important',
				maxHeight: 60,
				textOverflow: 'ellipsis',
			}} onClick={() => this.setState({ showLicenseDetail: true })} className='clickable'>
				<Typography variant='body2' sx={{ fontWeight: 'bold', fontSize: '0.65rem' }}>
					{licenseText} {(licenseState == LicenseState.Valid) && <i>{ tags }</i> }
				</Typography>

				{[LicenseState.Valid, LicenseState.Expired].includes(licenseState) && <Typography variant='body2' sx={{ fontSize: '0.65rem' }}>
					{ from ?? '?'}
					{'–'}
					{to ?? '?'}
				</Typography>}
			</Box>}

			{this.state.showLicenseDetail &&
                <Modal title={licenseText} isOpen={this.state.showLicenseDetail} width='min(90vw, 50%)' onClose={() => this.setState({ showLicenseDetail: false })}>
					<Table sx={{
						maxWidth: '100%',
						borderCollapse: 'collapse',
						'& tr': { maxWidth: '100%'},
						'& td': { p: 1 },
						'& tr td:first-of-type': { fontWeight: 'bold' }
					}}>
						<tbody>
							{licenseState !== LicenseState.Valid && <tr>
								<td>{t('license.message')}:</td>
								<td>{license?.status.message ?? placeholder}</td>
							</tr>}
							<tr>
								<td>{t('license.id')}:</td>
								<td>{license?.info?.id ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.identifier')}:</td>
								<td>{license?.info?.identifier ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.from')}:</td>
								<td>{from ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.to')}:</td>
								<td>{to ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.enabled')}:</td>
								<td>{(license?.info?.enabled ? t('common.yes') : t('common.no')) ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.project')}:</td>
								<td>{license?.info?.project_name ?? placeholder} ({license?.info?.product_name ?? placeholder}, {license?.info?.client_name ?? placeholder})</td>
							</tr>
							<tr>
								<td>{t('license.tags')}:</td>
								<td>{(license?.info?.tags || []).join(',') ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.hwidentity')}:</td>
								<td>{license?.info?.hwidentity ?? placeholder}</td>
							</tr>
							<tr>
								<td>{t('license.token')}:</td>
								<td style={{ overflowWrap: 'anywhere' }}>{license?.info?.token ?? placeholder}</td>
							</tr>
						</tbody>
					</Table>
                </Modal>}
		</Box>);
	}

}

export default hoistStatics(withTranslation()(KioskPreview), KioskPreview)