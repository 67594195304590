import { TextField } from "@mui/material";
import { FieldProps, getIn } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon"
import { triggerFormChange } from "./Formik.model";

interface IProps extends FieldProps, WithTranslation {
    convertUTC?: boolean,
    includeZone?: boolean,

    labelKey: string,
    namespace: string,

    minTime?: any,
    maxTime?: any,
    InputProps?: any
}

interface IState {
    convertUTC: boolean,
    includeZone: boolean,
}

function timeToIso(datetime?: DateTime, convertUTC: boolean = true, includeZone: boolean = true): string {
    if (!datetime)
        return '';
    if (convertUTC)
        return datetime.toUTC().toISOTime({ includeOffset: includeZone });
    return datetime.toISOTime({ includeOffset: includeZone });
}

function timeFromIso(datetime?: string, convertUTC: boolean = true): DateTime | undefined {
    if (!datetime)
        return undefined;
    if (convertUTC)
        return DateTime.fromISO(datetime, { zone: 'utc'});
    return DateTime.fromISO(datetime);
}

function onTimeChangeFormikAdapter(value: DateTime, props: FieldProps, convertUTC: boolean, includeZone: boolean) {
    triggerFormChange(props.form, props.field.name, timeToIso(value, convertUTC, includeZone));
}

class MuiTimeField extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            convertUTC: this.props.convertUTC ?? true,
            includeZone: this.props.includeZone ?? true,
        };
    }

    render() {
        const t = this.props.t;
        const form = this.props.form;
        const field = this.props.field;
        
        const errors = getIn(form.errors, field.name);
        const touched = getIn(form.touched, field.name);

        return <TimePicker
            label={t(`${this.props.namespace}.${this.props.labelKey}`, {ns: this.props.namespace})}
            renderInput={(props: any) => 
                <TextField {...props}
                    fullWidth
                    id={field.name}
                    name={field.name}
                    error={touched && Boolean(errors)}
                    helperText={touched && Boolean(errors) ? String(errors) : ''}
                    onBlur={field.onBlur}
                />
            }
            value={timeFromIso(field.value, this.state.convertUTC) ?? ''}
            onChange={(value) => onTimeChangeFormikAdapter(value, this.props, this.state.convertUTC, this.state.includeZone)}
            InputProps={this.props.InputProps}
            // mask="__:__ _M"
            minTime={this.props.minTime}
            maxTime={this.props.maxTime}
        />;
    }
}

export default hoistStatics(withTranslation()(MuiTimeField), MuiTimeField)

export { timeToIso, timeFromIso }