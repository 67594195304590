import http from "../../system/Communicator";
import { ENTITY_VISIBILITY_TYPE, Sorting } from "../../system/CRUDTable";
import ICrudService, { ICrudServiceSmart, ITableEntity } from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { combineQueries } from "../../system/RequestResponse.model";
import { IEntry, ISearchable } from "../../system/SearchResult.model";
import { createDefaultBoard, IBoard } from "./Boards.model";

class BoardService implements ICrudServiceSmart<IBoard>, ISearchable<IBoard> {

	async search(text: string): Promise<IEntry<IBoard>[]> {
		const result = await this.getAll();
		return result.data
			.map((board, index) => { return { entity: board.entity, index: index } })
			.filter(x => x.entity.title.toLocaleLowerCase('cs').includes(text.toLocaleLowerCase('cs')));
	}

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IBoard>, mode?: ENTITY_VISIBILITY_TYPE): Promise<IPagedResponse<ITableEntity<IBoard>>> {
		const pq = pageQuery?.toString() ?? '';
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq, 'mode=' + (mode || ENTITY_VISIBILITY_TYPE.OWNED));

		return (await http.get<IPagedResponse<ITableEntity<IBoard>>>(`/board?${query}`)).data;
	}

	async getAllUserCanUse(): Promise<ITableEntity<IBoard>[]> {
		const query1 = combineQueries('mode=' + (ENTITY_VISIBILITY_TYPE.SUPERVISED), "FE_SILENT=1", "full=false");
		const query2 = combineQueries('mode=' + (ENTITY_VISIBILITY_TYPE.SHARED), "FE_SILENT=1", "full=false");
		let arr: ITableEntity<IBoard>[] = [];

		try {
			let r1 = (await http.get<IPagedResponse<ITableEntity<IBoard>>>(`/board?${query1}`)).data.data;
			arr = arr.concat(r1);
		} catch { }

		try {
			let r2 = (await http.get<IPagedResponse<ITableEntity<IBoard>>>(`/board?${query2}`)).data.data;
			arr = arr.concat(r2);
		} catch { }

		return arr;
	}
	
	async getById(id: Number): Promise<IBoard> {
		return (await http.get<IBoard>(`/board/${id}`)).data;
	}
	
	async create(entity: IBoard): Promise<IBoard> {
		return (await http.post<IBoard>("/board", entity)).data;
	}
	
	async delete(entity: IBoard): Promise<void> {
		await http.delete<void>("/board/" + entity.id);
	}
	
	async edit(entity: IBoard): Promise<IBoard> {
		return (await http.patch<IBoard>("/board/" + entity.id, entity)).data;
	}

	createDefaultEntity = createDefaultBoard;
}
export default new BoardService();