import { DateTime } from "luxon";
import { IIdentifiable } from "../../system/Identifiable.model"

interface IBoardNode extends IIdentifiable {
    title: string,
    showTitle: boolean,
    image: string, // path to media
}

interface IBoardLeafNode extends IBoardNode {
    url: string,
    notifyUntil: string // ISO UTC datetime string
}

interface IBoardInnerNode extends IBoardNode {
    children: (IBoardInnerNode | IBoardLeafNode)[]
}

interface IBoardRootNode extends IBoardInnerNode {
    color: string // full RGB hex (#rrggbb)
}

function isRoot(node: IBoardNode): node is IBoardRootNode {
    return isInner(node) && 'color' in node;
}

function isInner(node: IBoardNode): node is IBoardInnerNode {
    return 'children' in node;
}

function isLeaf(node: IBoardNode): node is IBoardLeafNode {
    return 'url' in node && 'notifyUntil' in node;
}

function toBase(node: IBoardNode): IBoardNode {
    return { id: node.id, title: node.title, showTitle: node.showTitle, image: node.image };
}

function toInner(node: IBoardNode): IBoardInnerNode {
    if (isInner(node))
        return { ...toBase(node), children: node.children };
    return { ...createDefaultBoardInnerNode(), ...toBase(node) };
}

function toRoot(node: IBoardNode): IBoardRootNode {
    if (isRoot(node))
        return { ...toInner(node), color: node.color };
    if (isInner(node))
        return { ...createDefaultBoard(), ...toInner(node) };
    return { ...createDefaultBoard(), ...toBase(node) };
}

function toLeaf(node: IBoardNode): IBoardLeafNode {
    if (isLeaf(node))
        return { ...toBase(node), url: node.url, notifyUntil: node.notifyUntil };
    return { ...createDefaultBoardLeafNode(), ...toBase(node) };
}

function createDefaultBoardNode(): IBoardNode {
    return {
        title: '',
        showTitle: false,
        image: ''
    };
}

function createDefaultBoardInnerNode(): IBoardInnerNode {
    return { ...createDefaultBoardNode(), children: [] };
}

function createDefaultBoard(): IBoardRootNode {
    return { ...createDefaultBoardInnerNode(), color: '#ffffff' };
}

function createDefaultBoardLeafNode(): IBoardLeafNode {
    return {
        ...createDefaultBoardNode(),
        url: '',
        notifyUntil: DateTime.now().plus({ days: 7 }).endOf('day').startOf('minute').toISO()
    };
}

export type { IBoardNode, IBoardLeafNode, IBoardInnerNode, IBoardRootNode, IBoardRootNode as IBoard }
export {
    createDefaultBoard, createDefaultBoardNode, createDefaultBoardInnerNode, createDefaultBoardLeafNode,
    isInner, isRoot, isLeaf,
    toBase, toInner, toLeaf, toRoot
}