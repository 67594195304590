import { IIdentifiable } from "../../system/Identifiable.model";
import { ITag } from "./Tags.model";

interface IScope extends IIdentifiable {
	name: string,
	description: string,
	included?: ITag[],
	excluded?: ITag[]
}

function createDefaultScope(): IScope {
	return {
		name: '',
		description: ''
	};
}

export type { IScope }
export { createDefaultScope }