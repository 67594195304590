import { ImgHTMLAttributes, useState } from 'react'
import placeholder from '../../avatar-placeholder.gif'
import React from 'react'
import { API_URL } from '../../system/Communicator'

interface ImgProps extends ImgHTMLAttributes<any> {
	fallback: string,
}
function ImageWithFallback({ fallback, src, ...props }: ImgProps) {
	const [imgSrc, setImgSrc] = useState<string | undefined>(src)
	const [origSrc, setOrigSrc] = useState<string | undefined>(src)
	if (origSrc !== src) {
		setImgSrc(src);
		setOrigSrc(src);
	}
	const onError = () => setImgSrc(fallback)
	return <img src={imgSrc ? imgSrc : fallback} onError={onError} {...props} />
}


interface IState {
}

interface IProps {
	userId: number
	className?: string
	refreshSeed?: number
}

export default class UserAvatar extends React.Component<IProps, IState> {

	render() {
		return <ImageWithFallback src={API_URL + "/user/" + this.props.userId + "/image?" + (this.props.refreshSeed || 0)} className={this.props.className || "user-avatar"}
			fallback={placeholder} />
	}
}