import React, { useState, useContext } from 'react'
import { CustomThemeContext }  from './CustomThemeProvider';
import { withTranslation } from 'react-i18next';

// eslint-disable-next-line no-unused-vars
export const BrandingContext = React.createContext(
	{
		currentBranding: {} as any,
		setBranding: (x: any) => {},
		t: (brandedKey: string, defaultKey: string) => { return "" },
	},
)

const BrandingProvider = (props : any) => {

	const t = props.t;
	const l = props.i18n.language;

	// eslint-disable-next-line react/prop-types
	const { children } = props
	const currentBranding = {} as any;
	const [branding, _setBranding] = useState(currentBranding)
	const { currentTheme, setTheme } = useContext(CustomThemeContext)
	
	const setBranding = (json : any) => {
		_setBranding(json);
		var newTheme = { 'mode' : currentTheme.mode, 'branding' : json };
		setTheme(newTheme);
	}
	
	const tran = (brandedKey: string, defaultKey: string) => {		
		return ((branding.hasOwnProperty("texts") && 
			(
				(branding["texts"].hasOwnProperty(brandedKey+"["+l+"]") && 
				branding["texts"][brandedKey+"["+l+"]"])
				||
				(branding["texts"].hasOwnProperty(brandedKey) && 
				branding["texts"][brandedKey])
			))
		||
		t(defaultKey));
	}
	
	const contextValue = {
		currentBranding: branding,
		setBranding: setBranding,
		t: tran
	}
	
	return (
    <BrandingContext.Provider value={contextValue}>
		{children}
    </BrandingContext.Provider>
	)
}

export default withTranslation()(BrandingProvider)