import { DateTime } from "luxon"

interface IPeriodicSchedule {
    position: number
    label: string
    timeBegin: string // only local time in ISO format
    timeEnd: string // only local time in ISO format
    
    monday: boolean
    tuesday: boolean
    wednesday: boolean
    thursday: boolean
    friday: boolean
    saturday: boolean
    sunday: boolean
}

interface IScheduleException {
    position: number
    label: string

    begin: string // local date in ISO format
    end: string // local date in ISO format

    timeBegin: string // only local time in ISO format
    timeEnd: string // only local time in ISO format

    state: boolean
}

interface ISchedule {
    config: IPeriodicSchedule[]
    exceptions: IScheduleException[]
}

interface IUptimeSchedule extends ISchedule {}

interface IAudioSchedule extends ISchedule {
    sound: boolean
    volume: number
}

interface IExceptionFilter {
    before: string // local datetime in ISO format
}

function createDefaultPeriodicSchedule(): IPeriodicSchedule {
    return {
        position: 0,
        label: '',
        timeBegin: DateTime.now().toLocal().startOf('day').toISOTime({ includeOffset: false }),
        timeEnd: DateTime.now().toLocal().endOf('day').startOf('minute').toISOTime({ includeOffset: false }),

        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
    };
}

function createDefaultScheduleException(): IScheduleException {
    return {
        position: 0,
        label: '',
        begin: DateTime.now().toLocal().toISODate(),
        end: DateTime.now().toLocal().toISODate(),
        timeBegin: DateTime.now().toLocal().startOf('day').toISOTime({ includeOffset: false }),
        timeEnd: DateTime.now().toLocal().endOf('day').startOf('minute').toISOTime({ includeOffset: false }),
        state: false
    };
}

export type { IPeriodicSchedule, IScheduleException, ISchedule, IUptimeSchedule, IAudioSchedule, IExceptionFilter }
export { createDefaultPeriodicSchedule, createDefaultScheduleException }