import { Autocomplete, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Switch, TextField } from "@mui/material";
import { Field, FieldProps, Form, Formik, getIn } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IKiosk } from "../kiosks/Kiosks.model";
import KiosksService from "../kiosks/Kiosks.service";
import { isAuthorized, IUserContext, permissions } from "../../system/User.model";
import Kiosks from "./Kiosks";
import { ILabeledFieldProps, triggerFormChange } from "../../system/Formik.model";
import { AuthContext } from "../../system/Base";
import FilesService from "../files/Files.service";
import { BE_ROOT } from "../../system/Communicator";
import SaveIcon from "@mui/icons-material/Save";
import SaveCloseButton from "../../system/SaveCloseButton";
import BoltIcon from '@mui/icons-material/Bolt';
import { IPermission } from "../users/Users.model";


interface ICloneSource {
    buttons: IKiosk | null,
    uptimes: IKiosk | null,
    audio: IKiosk | null,
    whitelist: IKiosk | null
}

type ICloneMask = {
    [Property in keyof ICloneSource]: boolean
}

interface IClone {
    source: ICloneSource,
    mask: ICloneMask
}


interface IProps extends WithTranslation {
    kiosk: IKiosk,
    onSubmit: (clone: IClone) => void | Promise<void>
    activePermissions: IPermission[]
}

interface IState {
    kiosks: IKiosk[]
    initialized: boolean
    singleSource: boolean
    clone: IClone
}

class CloneConfig extends React.Component<IProps, IState, WithTranslation> {

    kioskService = KiosksService;
    fileService = FilesService;

    static isEnabled(perm: IPermission[], auth: IUserContext): boolean {
        return isAuthorized(perm, permissions.kiosk.modifyButtons)
            || isAuthorized(perm, permissions.kiosk.modifyUptimes)
            || isAuthorized(perm, permissions.kiosk.modifyAudio)
            || (isAuthorized(auth, permissions.configuration.read) && isAuthorized(auth, permissions.configuration.write));
    }

    constructor(props: IProps) {
        super(props);

        this.state = {
            kiosks: [],
            initialized: false,
            singleSource: true,
            clone: {
                source: {
                    buttons: null,
                    audio: null,
                    uptimes: null,
                    whitelist: null
                },
                mask: {
                    buttons: false,
                    audio: false,
                    uptimes: false,
                    whitelist: false
                }
            }
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.kioskSelect = this.kioskSelect.bind(this);
        this.onKioskSelect = this.onKioskSelect.bind(this);
        this.maskItem = this.maskItem.bind(this);
        this.validate = this.validate.bind(this);
    }

    async componentDidMount() {
        const kiosks = await this.kioskService.getAll();
        this.setState({
            kiosks: kiosks.data,
            initialized: true
        });
    }

    validate(values: IClone): any {
        let errors: any = {};
        const valueSetEmpty = this.props.t('validation.value-set-empty');
        const valueSetEmptyError = { buttons: valueSetEmpty, uptimes: valueSetEmpty, audio: valueSetEmpty, whitelist: valueSetEmpty };

        if (this.state.singleSource) {
            if (!values.source.buttons)
                errors = {...errors, source: { buttons: this.props.t('validation.value-empty') }};
            
            if (![values.mask.buttons, values.mask.uptimes, values.mask.audio, values.mask.whitelist].some((m) => m))
                errors = {...errors, mask: valueSetEmptyError };
        }
        else {
            if (![values.source.buttons, values.source.uptimes, values.source.audio, values.source.whitelist].some((m) => m))
                errors = {...errors, source: valueSetEmptyError };
        }
        return errors;
    }

    async onSubmit(auth: IUserContext, values: IClone): Promise<void> {

        const src = values.source;
        const all: ICloneMask = { buttons: true, uptimes: true, audio: true, whitelist: true} ;
        const mask = this.state.singleSource ? values.mask : all;

        if (mask.buttons && src.buttons && isAuthorized(auth, permissions.kiosk.modifyButtons)) {
            const buttons = await this.kioskService.getButtons(src.buttons);
            await this.kioskService.setButtons(this.props.kiosk, buttons);
        }
        
        if (mask.uptimes && src.uptimes && isAuthorized(auth, permissions.kiosk.modifyUptimes)) {
            const uptimes = await this.kioskService.getUptimeSchedule(src.uptimes);
            await this.kioskService.setUptimeSchedule(this.props.kiosk, uptimes);
        }
        
        if (mask.audio && src.audio && isAuthorized(auth, permissions.kiosk.modifyAudio)) {
            const audio = await this.kioskService.getAudioSchedule(src.audio);
            await this.kioskService.setAudioSchedule(this.props.kiosk, audio);
        }

        if (mask.whitelist && src.whitelist && isAuthorized(auth, permissions.configuration.read) && isAuthorized(auth, permissions.configuration.write)) {
            const dir = 'configuration';
            const whitelistSource = `/whitelist-${src.whitelist.identifier}.txt`;
            const whitelistTarget = `/whitelist-${this.props.kiosk.identifier}.txt`;

            const fileExists = await this.fileService.existsInDirectory(dir, whitelistSource.substring(1));
            if (fileExists) {
                const fileBlob: Blob = (await this.fileService.getBlob(BE_ROOT + '/' + dir + whitelistSource)).data;
                const content = await fileBlob.text();
                this.fileService.setContent(dir, whitelistTarget, content);
            }
        }

        this.props.onSubmit({ ...values, mask });
    }

    private kioskSelect(props: {disabled: boolean} & ILabeledFieldProps<IKiosk, IClone>) {
        const errors = getIn(props.form.errors, props.field.name);
        const touched = getIn(props.form.touched, props.field.name);

        return this.state.kiosks && <Autocomplete
            options={this.state.kiosks}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            getOptionLabel={(kiosk: IKiosk) => kiosk.name}
            disabled={props.disabled}
            value={props.field.value}
            onChange={(_event, value, _reason, _details) => this.onKioskSelect(value, props)}

            renderInput={(params) =>
            <TextField variant='outlined'
                {...params}
                fullWidth

                disabled={props.disabled}
                error={touched && Boolean(errors)}
                helperText={touched && Boolean(errors) ? String(errors) : ''}
                onBlur={props.field.onBlur}

                label={props.labelKey ? this.props.t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace}) : undefined}
                placeholder={this.props.t(`${Kiosks.getLocale()}.clone-input-help`, {ns: Kiosks.getLocale()})}
                inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
            }
        />;
    }

    private onKioskSelect(kiosk: IKiosk | null, props: FieldProps<IKiosk, IClone>) {
        if (this.state.singleSource) {
            triggerFormChange(props.form, 'source.buttons', kiosk);
            triggerFormChange(props.form, 'source.uptimes', kiosk);
            triggerFormChange(props.form, 'source.audio', kiosk);
            triggerFormChange(props.form, 'source.whitelist', kiosk);

        } else triggerFormChange(props.form, props.field.name, kiosk);
    }

    private maskItem(props: {disabled: boolean} & ILabeledFieldProps<boolean, IClone>) {
        const errors = getIn(props.form.errors, props.field.name);
        const touched = getIn(props.form.touched, props.field.name);

        return <FormControl error={touched && Boolean(errors)} sx={{width: '100%'}}>
            <FormControlLabel
                label={this.props.t(`${props.namespace}.${props.labelKey}`, {ns: props.namespace})}
                control={<Checkbox name={props.field.name} disabled={props.disabled} checked={props.field.value} onChange={props.field.onChange} onBlur={props.field.onBlur} />}
            />
            <FormHelperText>{touched && Boolean(errors) ? String(errors) : ''}</FormHelperText>
        </FormControl>;
    }

    render() {
        const t = this.props.t;
        const ns = Kiosks.getLocale();

        return <>
            {!this.state.initialized && <CircularProgress disableShrink />}
            {this.state.initialized && <Box sx={{textAlign: 'left'}}>
                <AuthContext.Consumer>{(auth) =>
                    <Formik initialValues={this.state.clone} onSubmit={(values) => this.onSubmit(auth, values)} validate={this.validate}>
                            <Form className='modal-form'>
                                <AuthContext.Consumer>{(auth) => <>
                                    
                                    <FormControlLabel sx={{ width: '100%', mb: '1rem' }} label={t(`${ns}.single-source`, {ns: ns})}
                                        control={<Switch checked={this.state.singleSource} onChange={(_e, checked) => this.setState({ singleSource: checked })} />} />

                                    {this.state.singleSource && <>
                                        <Field name='source.buttons' labelKey="clone" namespace={ns} component={this.kioskSelect} />

                                    <Field disabled={!isAuthorized(this.props.activePermissions, permissions.kiosk.modifyButtons)}
                                            name='mask.buttons' labelKey="buttons-clone" namespace={ns} component={this.maskItem} />
                                        
                                    <Field disabled={!isAuthorized(this.props.activePermissions, permissions.kiosk.modifyUptimes)}
                                            name='mask.uptimes' labelKey="uptimes-clone" namespace={ns} component={this.maskItem} />
                                        
                                    <Field disabled={!isAuthorized(auth, permissions.configuration.read) && !isAuthorized(auth, permissions.configuration.write)}
                                            name='mask.whitelist' labelKey="whitelist-clone" namespace={ns} component={this.maskItem} />
                                        
                                    <Field disabled={!isAuthorized(this.props.activePermissions, permissions.kiosk.modifyAudio)}
                                            name='mask.audio' labelKey="audio-clone" namespace={ns} component={this.maskItem} />
                                    </>}

                                    {!this.state.singleSource && <>
                                    <Field disabled={!isAuthorized(this.props.activePermissions, permissions.kiosk.modifyButtons)}
                                            name='source.buttons' labelKey="buttons-clone" namespace={ns} component={this.kioskSelect} />

                                    <Field disabled={!isAuthorized(this.props.activePermissions, permissions.kiosk.modifyUptimes)}
                                            name='source.uptimes' labelKey="uptimes-clone" namespace={ns} component={this.kioskSelect} />
                                        
                                    <Field disabled={!isAuthorized(auth, permissions.configuration.read) && !isAuthorized(auth, permissions.configuration.write)}
                                            name='source.whitelist' labelKey="whitelist-clone" namespace={ns} component={this.kioskSelect} />
                                        
                                    <Field disabled={!isAuthorized(this.props.activePermissions, permissions.kiosk.modifyAudio)}
                                            name='source.audio' labelKey="audio-clone" namespace={ns} component={this.kioskSelect} />
                                        </>}
                                    </>}
                                </AuthContext.Consumer>
                                <Button variant='contained' type='submit' startIcon={<BoltIcon />}>
                                    {this.props.t('common.doit')}
                                </Button>
                            </Form>
                    </Formik>
                }</AuthContext.Consumer>
            </Box>}
        </>;
    }
}

/* export default hoistStatics(withTranslation()(styled), styled); */
export default hoistStatics(withTranslation()(CloneConfig), CloneConfig);