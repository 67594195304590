import http from "../../system/Communicator";
import { createDefaultScope, IScope } from "./Scopes.model"
import ICrudService, { ICrudServiceSmart, ITableEntity } from "../../system/ICrudService"
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { IEntry, ISearchable } from "../../system/SearchResult.model";
import { Sorting } from "../../system/CRUDTable";
import { combineQueries } from "../../system/RequestResponse.model";
import { ITag } from "./Tags.model";

interface IScopeService extends ICrudServiceSmart<IScope> {

}

interface IScopeTags {
	includedTagIds: number[],
	excludedTagIds: number[]
}

class ScopeService implements IScopeService, ISearchable<IScope> {

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IScope>): Promise<IPagedResponse<ITableEntity<IScope>>> {
		const pq = pageQuery?.toString() ?? '';
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq);

		let scope = (await http.get<IPagedResponse<ITableEntity<IScope>>>(`/scope?${query}`)).data;
		//return Object.assign(createDefaultScope(), scope);
		return scope;
	}
	
	async create(entity: IScope): Promise<IScope> {
		const k = (await http.post<IScope>("/scope", entity)).data;
		if (entity.included !== undefined || entity.excluded !== undefined) {
			entity.id = k.id;
			await this.setTags(entity, entity.included || [], entity.excluded || []);
			k.included = entity.included;
			k.excluded = entity.excluded;
		}
		return k;
	}

	async setTags(entity: IScope, included: ITag[], excluded: ITag[]): Promise<void> {
		if (!entity.id)
			return;
		let iId: number[] = included.filter(t => t.id !== null && t.id !== undefined).map(t => t.id!);
		let eId: number[] = excluded.filter(t => t.id !== null && t.id !== undefined).map(t => t.id!);

		let ids: IScopeTags = { includedTagIds: iId, excludedTagIds: eId }

		await http.post<IScopeTags>("/scope/" + entity.id + "/set-tags", ids);
	}
	
	async delete(entity: IScope): Promise<void> {
		await http.delete<void>("/scope/" + entity.id!);
	}
	
	async edit(entity: IScope): Promise<IScope> {
		let k = (await http.patch<IScope>("/scope/" + entity.id, this.pure(entity))).data;

		if (entity.included !== undefined || entity.excluded !== undefined) {
			await this.setTags(entity, entity.included || [], entity.excluded || []);
			k.included = entity.included;
			k.excluded = entity.excluded;
		}

		return k;
	}
	
	async search(text: string): Promise<IEntry<IScope>[]> {
		let result = (await http.get<IPagedResponse<IScope>>(`/scope`)).data;
		return result.data
			.map((tag, index) => { return { entity: tag, index: index }})
			.filter(x => x.entity.name.toLowerCase().includes(text.toLowerCase()));
	}

	private pure(entity: IScope): IScope {
		let e = Object.assign({}, entity);
		delete e.included;
		delete e.excluded;
		return e;
	}

	createDefaultEntity = createDefaultScope;
}
export default new ScopeService();
export type { IScopeService }