import { IIdentifiable } from "../../system/Identifiable.model";

interface ITag extends IIdentifiable {
	name: string,
	parent: number | null,
	identifier: string,
}

function createDefaultTag(): ITag {
	return {
		name: '',
		parent: null,
		identifier: '',		
	};
}

export type { ITag }
export { createDefaultTag }