import { Box, Button, Collapse, Grid, IconButtonProps, Table, TableBody, TableCell, TableRow, TextField, Theme, Typography } from "@mui/material";
import React, { ReactElement, Fragment, useContext, ReactNode } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import { IKiosk, IStatus, KioskState, StatusParser } from "../kiosks/Kiosks.model";
import KioskMap from "./KioskMap";
import hoistStatics from 'hoist-non-react-statics';
import './kiosk-detail.css'
import { SvgIconComponent } from "@mui/icons-material";
import MapIcon from '@mui/icons-material/Map';
import WifiIcon from '@mui/icons-material/Wifi';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import AppsIcon from '@mui/icons-material/Apps';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RuleIcon from '@mui/icons-material/Rule';
import { PieChart } from "./PieChart";
import { CRUDForm, IFormConfig } from "../../system/CRUDForm";
import { Modal } from "../../system/Modal";
import { CRUDOperation } from "../../system/CRUDOperation";
import { hideTagsIfCannotModify, kioskAttributes } from "../kiosks/KioskAttributes.model";
import snackNotifications from "../../system/SnackBarUtils";
import Buttons from "./Buttons";
import { withStyles } from 'tss-react/mui';
import ScheduleAudio from "./ScheduleAudio";
import ScheduleUptime from "./ScheduleUptime";
import SoftwareVersionService from "./SoftwareVersion.service";
import KiosksServiceGlobal, { IKioskConfigService } from "../kiosks/Kiosks.service";
import KiosksServiceLocal from "../kiosks/LocalKiosk.service";
import LogoUploader from './LogoUploader';
import FileEditor from "../files/FileEditor";
import KioskPreview from "./KioskPreview";
import SaveIcon from '@mui/icons-material/Save';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from "../../system/Base";
import { isAuthorized, IUserContext, permissions } from "../../system/User.model";
import { IconButton } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { parseDate } from '../../App';
import CloneConfig from "../kiosks/CloneConfig";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import fortesBwLight from '../../fortes-bw-light.png';
import fortesBwDark from '../../fortes-bw-dark.png';
import { CustomThemeContext }  from '../../system/CustomThemeProvider';
import { IShouldCloseContext, ShouldCloseContext } from "../../system/SaveCloseButton";
import { IPermission } from "../users/Users.model";


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));


interface IKioskAttribute {
    name: string,
    value: string | undefined | ReactNode,
    copyable?: boolean,
	extra?: ReactElement
}

interface IKioskBasicInfoProps {
    name: string,
    attributes: IKioskAttribute[]
}

interface IKioskSystemInfoProps {
    titleName: string
    attributes: IKioskAttribute[]
    icon: SvgIconComponent
    additionalContent?: ReactElement
}

interface IProps extends WithTranslation {
    kiosk: IKiosk
    activePermissions: IPermission[]
    kiosks?: IKiosk[]
    onClose(): void
    onRequestReload(): void
    className?: string
}

interface IState {
    swUpToDate: {
        frontend: boolean | undefined,
        backend: boolean | undefined,
        digipanelBranch: boolean | undefined,
        digipanelContent: boolean | undefined,
    } | undefined,
    edit: boolean
    editButtons: boolean
    scheduleUptime: boolean
    scheduleSound: boolean
	uploadLogo: boolean
    showWhitelist: boolean
    showServiceLog: boolean
    showSwVersions: boolean
    cloneConfig: boolean
	serviceLog: string
	logIsSaving: boolean
	logRecentlySaved: boolean	
    showFortouchDetail: boolean
}

class KioskDetail extends React.Component<IProps, IState, WithTranslation> {

    static getNamespace(): string {
        return 'module.dashboard';
    }
	
	timeoutRecentlySaved : any; // timeout
	swVersionService = SoftwareVersionService;

    constructor(props: IProps) {
		super(props);

        this.state = {
            swUpToDate: undefined,
            edit: false,
            editButtons: false,
            scheduleUptime: false,
            scheduleSound: false,
			uploadLogo: false,
            showWhitelist: false,
            showServiceLog: false,
            showSwVersions: false,
            cloneConfig: false,
			serviceLog : this.props.kiosk.notes,
			logIsSaving : false,
			logRecentlySaved : false,
            showFortouchDetail: false,
        };

        this.resyncKiosk = this.resyncKiosk.bind(this);
        this.rebootKiosk = this.rebootKiosk.bind(this);
        this.restartApp = this.restartApp.bind(this);
        this.checkSoftwareVersion = this.checkSoftwareVersion.bind(this);
		this.formatDate = this.formatDate.bind(this);
		this.formatNextActivity = this.formatNextActivity.bind(this);
        this.formatTouchInfo = this.formatTouchInfo.bind(this);
        this.formatMutedInfo = this.formatMutedInfo.bind(this);
        this.formatOrientation = this.formatOrientation.bind(this);
        this.getBasicInfo = this.getBasicInfo.bind(this);
        this.getSystemInfo = this.getSystemInfo.bind(this);
        this.getVersionStyle = this.getVersionStyle.bind(this);
		this.restartTouch = this.restartTouch.bind(this);
	}

    async componentDidMount() {
        await this.checkSoftwareVersion();
    }

    async componentDidUpdate(prevProps: IProps) {
        if (prevProps.kiosk.id === this.props.kiosk.id)
            return;

		this.setState( { serviceLog : this.props.kiosk.notes });
        await this.checkSoftwareVersion();
    }

    async checkSoftwareVersion() {
        const kioskStatus = this.props.kiosk?.status;
        const branchP = this.swVersionService.isDPBranchUpToDate(kioskStatus?.digipanel?.branch);
        const contentP = this.swVersionService.isDPContentUpToDate(kioskStatus?.digipanel?.content);
        const frontendP = this.swVersionService.isFrontendUpToDate(kioskStatus?.frontend?.version);
        const backendP = this.swVersionService.isBackendUpToDate(kioskStatus?.backend?.branch, kioskStatus?.backend?.version);

        const [ digipanelBranch, digipanelContent, frontend, backend ] = await Promise.all([ branchP, contentP, frontendP, backendP ]);

        this.setState({
            swUpToDate: {
                digipanelBranch,
                digipanelContent,
                frontend,
                backend,
            }
        });
    }
	
	relevantKioskService(): IKioskConfigService {
		if (this.isGlobalAdmin())
			return KiosksServiceGlobal;
		else
			return KiosksServiceLocal;
	}

    async resyncKiosk() {
        await this.relevantKioskService().resync(this.props.kiosk);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }
    
    async rebootKiosk() {
        await this.relevantKioskService().reboot(this.props.kiosk);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }

    async restartApp() {
        await this.relevantKioskService().restartApp(this.props.kiosk);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }
	
	async restartTouch() {
        await this.relevantKioskService().restartTouch(this.props.kiosk);
        snackNotifications.info(this.props.t('common.request-sent', 'common'));
    }

	formatDate(dateStr: string | undefined): string | undefined {
        if (!dateStr)
            return undefined;
		const l = this.props.i18n.language;
		return parseDate(dateStr).toLocaleString(l);
    }

    formatDateNoSec(dateStr: string | undefined): string | undefined {
        if (!dateStr)
            return undefined;
        const l = this.props.i18n.language;
        return parseDate(dateStr).toLocaleString(l, { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    }

    formatLastActivity(status?: IStatus): string | undefined {
        if (!status)
            return undefined;

        const t = this.props.t;
        const namespace = KioskDetail.getNamespace();

        const date = this.formatDate(status.date);
        const uptime = t(`${namespace}.uptime`, {ns: namespace});
        const uptimeValue = StatusParser.getKioskState(this.props.kiosk.status) === KioskState.Online
            ? status?.system?.uptime
            : t(`${namespace}.status-false`, {ns: namespace});
        
        return `${date} (${uptime}: ${uptimeValue})`;
    }

    formatNextActivity(scheduler?: IStatus['scheduler']): ReactNode | undefined {
		if (!scheduler || !scheduler.shutdown)
			return undefined;

		const t = this.props.t;
        const namespace = KioskDetail.getNamespace();
		
		const shutdown = scheduler.shutdown;

		// TODO: adapt to new status
		/*let str = '';
		if (shutdown[0]) {
			str += `${t(`${namespace}.status-${shutdown[0].state}`, {ns: namespace})}: `;
			str += this.formatDate(shutdown[0]?.end);
		}
		if (shutdown[1]) {
            str += t(namespace + ".status-and-then") + `${t(`${namespace}.status-${shutdown[1].state}`, {ns: namespace})}: `;
			str += this.formatDate(shutdown[1]?.end);
		}*/
		
        return <>
            {!!shutdown[0] && <span className={"activity-" + shutdown[0].state}>
                {t(`${namespace}.status-${shutdown[0].state}`, { ns: namespace })}
                {t(`${namespace}.status-untill`, { ns: namespace })}
                <b>{this.formatDateNoSec(shutdown[0]?.end)}</b>
            </span>}
            {
                !!shutdown[1] && <>{t(namespace + ".status-and-then", { ns: namespace }) }</>
            }
            {!!shutdown[1] && <span className={"activity-" + shutdown[1].state}>
                {t(`${namespace}.status-${shutdown[1].state}`, { ns: namespace })}
                {t(`${namespace}.status-untill`, { ns: namespace })}
                <b>{this.formatDateNoSec(shutdown[1]?.end)}</b>
            </span>}
        </>;
	}

    formatTouchInfo(touch?: IStatus['touch']): string | undefined {
        if (!touch)
            return undefined;

        const t = this.props.t;
        const namespace = KioskDetail.getNamespace();
        if (!touch.connected)
            return t(`${namespace}.connected-false`, {ns: namespace});
        
        return t(`${namespace}.enabled-${touch.enabled}`, {ns: namespace});
    }

    formatMutedInfo(audio?: IStatus['audio']): string | undefined {
        if (!audio)
            return undefined;
        return this.props.t(`common.${audio.muted ? 'yes' : 'no'}`, {ns: 'common'});
    }

    formatOrientation(screen?: IStatus['screen']): string | undefined {
        const t = this.props.t;
        const namespace = KioskDetail.getNamespace();
        
        if (!screen)
            return undefined;
        if (!screen.orientation)
            return ''; // TODO: check if correct
        return t(`${namespace}.${screen?.orientation}`, {ns: namespace});
    }

    getBasicInfo(kiosk: IKiosk): IKioskBasicInfoProps[] {
        const t = this.props.t;
        let lastSync = kiosk.status?.sync?.last; 
        lastSync = lastSync === 'never' ? t('time.never') : this.formatDate(lastSync);

        let arr = [
            {
                name: 'static',
                attributes: [
                    { name: 'identifier', value: kiosk.identifier, copyable: true },
                    { name: 'description', value: kiosk.description, copyable: true },
                ],
            },
            {
                name: 'dynamic',
                attributes: [
                    { name: 'last-activity', value: this.formatLastActivity(kiosk.status) },
                    { name: 'next-activity', value: this.formatNextActivity(kiosk.status?.scheduler) },
                    { name: 'last-sync', value: lastSync }
                ]
            }
        ];
        
        if (kiosk.contact?.trim())
            arr[0].attributes.push({ name: 'contact', value: kiosk.contact, copyable: false });
        arr[0].attributes.push({ name: 'gps-location', value: kiosk.lat + 'N / ' + kiosk.lon + 'E', copyable: false });

        if (this.isGlobalAdmin())
            arr[0].attributes.push({ name: 'tags', value: kiosk.tags?.map(tag => tag.name).join(', ') ?? '', copyable: false });

		return arr;
    }
	
	isGlobalAdmin() : boolean {
		/* If we know about other kiosks, we are in global. */
		if (this.props.kiosks)
			return true;
		return false;
	}

    getSystemInfo(status: IStatus | undefined, theme: any): IKioskSystemInfoProps[] {
        const network: IKioskSystemInfoProps = {
            titleName: 'network',
            attributes: [
                { name: 'local-ip', value: status?.network?.local_ip?.join(', '), copyable: true },
                { name: 'connected-to', value: status?.network?.essid },
            ],
            icon: WifiIcon
        };

        if (this.isGlobalAdmin())
            network.attributes = [{ name: 'zt-ip', value: status?.network?.zt_ip?.join(', '), copyable: true } as IKioskAttribute].concat(network.attributes);

        return [ network,
        {
            titleName: 'audio',
            attributes: [
                { name: 'volume', value: status?.audio?.volume },
                { name: 'muted', value: this.formatMutedInfo(status?.audio) }
            ],
            icon: VolumeUpIcon
        },
        {
            titleName: 'screen',
            attributes: [
                { name: 'port', value: status?.screen?.port },
                { name: 'resolution', value: status?.screen?.resolution },
                { name: 'orientation', value: this.formatOrientation(status?.screen) },
                { name: 'touch', value: this.formatTouchInfo(status?.touch), extra: (this.isGlobalAdmin() && !!status?.fortouch)? 
					<IconButton size="small" onClick={() => { this.setState({showFortouchDetail: true}); }} title='Fortouch'>
						<img src={ theme.currentTheme.mode == 'dark'? fortesBwLight : fortesBwDark} alt="Fortouch" style={{
							display: "inline-block",
							height: "16px",
							lineHeight: "21px",
					}} /></IconButton>
				: undefined }
            ],
            icon: TabletMacIcon
        },
        {
            titleName: 'system',
            attributes: [
                { name: 'temperature', value: status?.system?.temperature },
                { name: 'load-average', value: status?.system?.load_avg },
                { name: 'ram-free', value: status?.system?.ram_free },
                { name: 'ram-total', value: status?.system?.ram_total },
            ],
            icon: SettingsIcon
        },
        {
            titleName: 'disk-usage-os',
            attributes: [
                { name: 'free-space', value: status?.system?.os_free },
                { name: 'total', value: status?.system?.os_total }
            ],
            icon: StorageIcon,
            additionalContent: <PieChart percent={Number.parseFloat(status?.system?.os_used_percentage ?? '')} />
        },
        {
            titleName: 'disk-usage-data',
            attributes: [
                { name: 'free-space', value: status?.system?.home_free },
                { name: 'total', value: status?.system?.home_total }
            ],
            icon: StorageIcon,
            additionalContent: <PieChart percent={Number.parseFloat(status?.system?.home_used_percentage ?? '')} />
        }];
    }

    getUpdateFormConfig(auth: IUserContext, shouldCloseCtx: IShouldCloseContext): IFormConfig<IKiosk> {
		return {
			title: this.props.t("crud.update") + ": " + (this.props.kiosk.name ?? "???"),
			onSubmit: async (kiosk: IKiosk) => {
                if (!isAuthorized(auth, permissions.kiosk.modifyTags)) {
                    // A bit of a hack: Remove tags to avoid modifying them in kiosk service
                    delete kiosk.tags;
                }
                var r = await this.relevantKioskService().edit(kiosk);
                if (shouldCloseCtx.shouldClose)
				    this.setState({ edit: false });
                this.props.onRequestReload();
                snackNotifications.success(this.props.t('common.request-completed'));
				return r;
			}
		};
	}
	
	
	
	async markRecentlySaved() {
		this.setState({ logIsSaving : false, logRecentlySaved : true });
		this.timeoutRecentlySaved = window.setTimeout(()=>{
			this.setState({ logRecentlySaved : false });
			this.timeoutRecentlySaved = null;
		}, 2000);
	}
	
	async saveLog() {
		this.cleanRecentlySavedInterval();
		this.setState({ logIsSaving : true }, async ()=>{
		
			// TODO: This should be one route at BE. We do Get before Update so that our
			// log change doesn't rewrite anything, but if there's a dedicated route
			// in the future, use that instead.
			if (this.props.kiosk && this.props.kiosk.id)
			{
				let x = await KiosksServiceGlobal.getById(this.props.kiosk!.id!);
				x.notes = this.state.serviceLog;
				await KiosksServiceGlobal.edit(x);
				
				this.markRecentlySaved();
			}
		})
	}
	
	cleanRecentlySavedInterval()
	{
		if (this.timeoutRecentlySaved) {
			window.clearInterval(this.timeoutRecentlySaved);
			this.timeoutRecentlySaved = null;
		}
	}

    copyButton(att: IKioskAttribute) {
        return att.value && att.copyable &&
            <IconButton sx={{ marginTop: '-3px', marginBottom: '-3px' }} size="small" onClick={() => this.copyToClipboard(att)} title={this.props.t('clipboard.copy')}>
                <ContentCopyIcon sx={{ fontSize: '1em' }} />
            </IconButton>;
    }
	
	copyToClipboard(attr: IKioskAttribute) {
        const ns = KioskDetail.getNamespace();

        if (!("clipboard" in navigator) && !("execCommand" in document)) {
            if (!window.isSecureContext)
                snackNotifications.error(this.props.t(`${ns}.${attr.name}`, {ns: ns}) + ': ' + this.props.t('clipboard.unsafe'));
            else
                snackNotifications.error(this.props.t(`${ns}.${attr.name}`, {ns: ns}) + ': ' + this.props.t('clipboard.unsupported'));
            return;
        }

        if ("clipboard" in navigator) {
            navigator.clipboard.writeText(attr.value+'' ?? '');
        } else {
            console.warn("Using deprecated copying.");
            const textarea = document.createElement("textarea");
            textarea.textContent = attr.value+'' ?? '';
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand("copy");
            document.body.removeChild(textarea);
        }

        snackNotifications.success(this.props.t(`${ns}.${attr.name}`, {ns: ns}) + ': ' + this.props.t('clipboard.copied'));
    }

    getVersionStyle(upToDate: boolean | undefined) {
        return ({ color: (!upToDate ? 'warning.main' : '') })
    }

    render() {
        const kiosk = this.props.kiosk;
        const basicInfo = this.getBasicInfo(this.props.kiosk);
        const t = this.props.i18n.t;
        const ns = KioskDetail.getNamespace();

        const columnSize = { xs: 12, sm: 12, md: 6, lg: 4, xl: 3 };
        const buttonArea = (columnSize: object) => <AuthContext.Consumer>{(auth) => {
            const permAuth = this.isGlobalAdmin() ? this.props.activePermissions : auth
            return (<Grid className="kiosk-option-buttons" container spacing={2}>
                {this.isGlobalAdmin() && <Grid item {...columnSize}>
                    <Button variant="contained" color="info" startIcon={<SyncAltIcon />} disabled={!isAuthorized(permAuth, permissions.kiosk.resync)}
                        onClick={this.resyncKiosk}>
                        {t(`${ns}.sync-data`, { ns: ns }) + ''}
                    </Button>
                </Grid>}
                <Grid item {...columnSize}>
                    <Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} disabled={!isAuthorized(permAuth, permissions.kiosk.restart)}
                        onClick={this.restartApp}>
                        {t(`${ns}.restart-app`, { ns: ns }) + ''}
                    </Button>
                </Grid>
                {this.isGlobalAdmin() && <Grid item {...columnSize}>
                    <Button variant="contained" color="primary" startIcon={<EditIcon />} disabled={!isAuthorized(permAuth, permissions.kiosk.update)}
                        onClick={() => this.setState({ edit: true })}>
                        {t(`${ns}.edit-info`, { ns: ns }) + ''}
                    </Button>
                </Grid>}
                <Grid item {...columnSize}>
                    <Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} disabled={!isAuthorized(permAuth, permissions.kiosk.reboot)}
                        onClick={this.rebootKiosk}>
                        {t(`${ns}.reboot`, { ns: ns }) + ''}
                    </Button>
                </Grid>
                {this.isGlobalAdmin() && <Grid item {...columnSize}>
                    <Button variant="contained" color="primary" startIcon={<AppsIcon />}
                        disabled={!isAuthorized(permAuth, permissions.kiosk.modifyButtons) || !isAuthorized(auth, permissions.media.read) || !isAuthorized(auth, permissions.content.read)}
                        onClick={() => this.setState({ editButtons: true })}>
                        {t(`${ns}.edit-buttons`, { ns: ns }) + ''}
                    </Button>
                </Grid>}
                {this.isGlobalAdmin() && <Grid item {...columnSize}>
                    <Button variant="contained" startIcon={<RuleIcon />} onClick={() => this.setState({ showWhitelist: true })}
                        disabled={/*!isAuthorized(auth, permissions.configuration.read)*/ !isAuthorized(auth, permissions.kiosk.modifyWhitelist)}>
                        {t(`${ns}.show-whitelist`, { ns: ns }) + ''}
                    </Button>
                </Grid>}
                {this.isGlobalAdmin() && <Grid item {...columnSize}>
                    <Button variant="contained" color="primary" startIcon={<ScheduleIcon />} disabled={!isAuthorized(permAuth, permissions.kiosk.modifyUptimes)}
                        onClick={() => this.setState({ scheduleUptime: true })}>
                        {t(`${ns}.schedule-uptime`, { ns: ns }) + ''}
                    </Button>
                </Grid>}
                {this.isGlobalAdmin() && <Grid item {...columnSize}>
                    <Button variant="contained" color="primary" startIcon={<VolumeUpIcon />} disabled={!isAuthorized(permAuth, permissions.kiosk.modifyAudio)}
                        onClick={() => this.setState({ scheduleSound: true })}>
                        {t(`${ns}.schedule-sound`, { ns: ns }) + ''}
                    </Button>
                </Grid>}
                {!this.isGlobalAdmin() && <Grid item {...columnSize}>
                <Button variant="contained" color="primary" startIcon={<AddPhotoAlternateIcon />} onClick={() => this.setState({ uploadLogo: true })}
                        disabled={!isAuthorized(auth, permissions.kiosk.modifyLogo)}>
                    {t(`${ns}.upload-logo`, { ns: ns }) + ''}
                </Button>
            </Grid>}
                {this.isGlobalAdmin() && CloneConfig.isEnabled(this.props.activePermissions, auth) && <Grid item {...columnSize}>
                    <Button variant="contained" startIcon={<SettingsSuggestIcon />} onClick={
                        () => { this.setState({ cloneConfig: true }); }
                    }>
                        {t(`module.kiosks.clone-config`, { ns: 'module.kiosks' }) + ''}
                    </Button>
                </Grid>}

            </Grid>)
        }
        }</AuthContext.Consumer>
		
        const cellStyle = { border: 0, pl: 0, pr: 0 };
        const rowStyle = { borderBottom: 1, borderColor: 'divider', display: { xs: 'flex', lg: 'table-row' }, flexDirection: { xs: 'column', lg: 'unset' } };
        const upToDate = this.state.swUpToDate?.backend && this.state.swUpToDate?.frontend
            && this.state.swUpToDate?.digipanelBranch && this.state.swUpToDate?.digipanelContent;
        
		const mainArea = <>
		<Grid container spacing={2} className="kiosk-detail-main-grid">
                <AuthContext.Consumer>{(auth) => 
                    <Grid item xs={12} md={5} xl={2} sx={{ height: { md: 'auto' } }}>
                        {this.isGlobalAdmin() && <KioskPreview kioskId={this.props.kiosk.id || -1} status={kiosk.status} showLicence={isAuthorized(auth, permissions.kiosk.readSystem)} />}
                        {!this.isGlobalAdmin() && <KioskPreview status={kiosk.status} showLicence={false} />}
                    </Grid>
                }</AuthContext.Consumer>
            <Grid item xs={12} md={7} xl={7}>
                <Table className='kioskInfo' size="small"><TableBody>
                        {basicInfo.map((section) => <Fragment key={section.name}><AuthContext.Consumer>{(auth) => {
                            return section.attributes.map(
                                (att) => {
                                    if (att.name !== "last-sync" || isAuthorized(auth, permissions.kiosk.readSystem))
                                        return <TableRow key={att.name} sx={rowStyle}>
                                            <TableCell sx={cellStyle}>{t(`${ns}.${att.name}`, { ns: ns }) + ':'}</TableCell>
                                            <TableCell sx={cellStyle}>{att.value ?? '?'}{this.copyButton(att)}</TableCell>
                                        </TableRow>
                                    else
                                        return null;
                                }
                            )
                        }}</AuthContext.Consumer></Fragment>
                        )}
                    <AuthContext.Consumer>{(auth) => (this.isGlobalAdmin() || auth.id === 1) && (isAuthorized(auth, permissions.kiosk.readSystem)) && <>
                    <TableRow sx={rowStyle} className='version'> 
                        <TableCell sx={cellStyle}>{t('common.software-version') + ':'}</TableCell>
                        <TableCell sx={cellStyle} className="clickable" onClick={() => this.setState({ showSwVersions: true })}>
                            <div style={{ display: 'inline-block' }}>
                                <Typography variant="body2" sx={this.getVersionStyle(upToDate)}>
                                    {upToDate ? t('common.up-to-date')+'' : t('common.outdated')+''}
                                </Typography>
                                {!upToDate && <WarningIcon color="warning" fontSize="small" />}
                                {upToDate && <CheckIcon color="success" fontSize="small" />}
                            </div>
                        </TableCell>
                    </TableRow>
                </>}</AuthContext.Consumer>
                </TableBody></Table>
            </Grid>
            <Grid item xl={3} sx={{ display: { xs: 'none', xl: 'block' }}}>
                {this.isGlobalAdmin() &&
                    <KioskMap kiosks={this.props.kiosks!} selectedKiosk={kiosk} />}
            
                {!this.isGlobalAdmin() &&
                    <div className="local-side-menu">
                    { buttonArea({}) }
                    </div>}
            </Grid>
        </Grid>
        </>;
        
		const serviceLog = this.isGlobalAdmin() && <AuthContext.Consumer>{(auth) => (<>
			<Typography variant="h4" style={{margin: "15px 0"}}>
				{t(`${ns}.service-log`, { ns: ns }) + ''}

                <ExpandMore
                    expand={this.state.showServiceLog}
                    onClick={() => this.setState({ showServiceLog: !this.state.showServiceLog })}>
                    <ExpandMoreIcon />
                </ExpandMore>
			</Typography>
            <Collapse in={this.state.showServiceLog} timeout="auto" unmountOnExit>
                <TextField multiline rows={5} style={{width: "100%"}} disabled={this.state.logIsSaving || !isAuthorized(auth, permissions.kiosk.update)}
                    value={this.state.serviceLog} onChange={ (e)=>this.setState({ serviceLog : e.target.value }) } />
                
                <Button disabled={this.state.logIsSaving || !isAuthorized(auth, permissions.kiosk.update)}
                    color="primary" variant="contained" aria-label="save file" style={ {float : "right"} } onClick={ ()=>{ this.saveLog(); } }
                    startIcon={ 
                        this.state.logIsSaving? <span style={{zoom : 0.5}}><CircularProgress /></span> : 
                        this.state.logRecentlySaved? <CheckIcon /> : <SaveIcon />
                    }>
                    {this.props.t('common.save')}
                </Button>
            </Collapse>
        </>)}</AuthContext.Consumer>;
        
        const systemInfo = <CustomThemeContext.Consumer>{theme => <>
		<Typography variant="h4" style={{margin: "15px 0"}}>
            {t(`${ns}.system-info`, { ns: ns }) + ''}
        </Typography>
        <Grid container spacing={2} >
            {this.getSystemInfo(kiosk.status, theme).map((props: IKioskSystemInfoProps) =>
            <Grid key={props.titleName} item xs={12} sm={6} lg={4}>
                <div className="kiosk-info-card">
                    <h4>{t(`${ns}.${props.titleName}`, {ns: ns}) + ''}</h4>
                    <div className="content">

                        {props.additionalContent &&
                        <div className="additional-content">
                            {props.additionalContent}
                        </div>}
                        <div className="attributes">
                            {props.attributes.map((att) => 
                            <span key={att.name}>
                                <p>{t(`${ns}.${att.name}`, {ns: ns}) + ':'}</p>
                                <p>{att.value ?? '?'}{this.copyButton(att)}{att.extra}</p>
                            </span>)}
                        </div>
                    </div>
                    <props.icon className="kiosk-card-icon" />
                </div>
            </Grid>)}
        </Grid>
		</>}</CustomThemeContext.Consumer>;
		
        const placeholder = '?';

        return <ShouldCloseContext.Consumer>{shouldCloseCtx => <div className={this.props.className}>
            {this.isGlobalAdmin() && <Button sx={{ display: { xs: 'inline-flex', lg: 'none' }, width: { xs: '100%', md: '42%' }, mb: 3 }}
                size='large' variant='outlined' onClick={this.props.onClose} startIcon={<MapIcon />}>
                {t(`${ns}.back-to-map`, { ns }) + ''}
            </Button>}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', columnGap: 3, mb: "15px" }}>
                <Typography variant="h3">
                    {kiosk.name}
                    {this.isGlobalAdmin() && <span>&nbsp;<IconButton color="primary" aria-label="local admin" component="label" size="large"
                        title={t(`${ns}.go-to-local`, { ns: ns })} onClick={() => window.open(StatusParser.getLocalAddress(kiosk.status))}>
                        <LaunchIcon />
                    </IconButton></span>}
                </Typography>
                {this.isGlobalAdmin() && <Button sx={{ float: "right", display: { xs: 'none', lg: 'inline-flex' }, flexGrow: { lg: 1, xl: 0 }, margin: 'auto 0', width: { xl: '25%' } }}
                    size='large' variant='outlined' onClick={this.props.onClose} startIcon={<MapIcon />}>
                    {t(`${ns}.back-to-map`, { ns }) + ''}
                </Button>}
            </Box>

            {mainArea}
            <Box sx={{ display: { xl: !this.isGlobalAdmin() ? 'none' : 'block' } }}>
                <Typography variant="h4" style={{ margin: "15px 0" }}>
                    {t(`${ns}.options`, { ns: ns }) + ''}
                </Typography>
                {buttonArea(columnSize)}
            </Box>
            {/* { serviceLog } */}
            <AuthContext.Consumer>{(auth) => { if (isAuthorized(auth, permissions.kiosk.readSystem)) return systemInfo; else return null; }}</AuthContext.Consumer>

            <AuthContext.Consumer>{(auth) => {
                const updateFormConfig = this.getUpdateFormConfig(auth, shouldCloseCtx);

                return this.state.edit &&
                    <Modal title={updateFormConfig.title} isOpen={this.state.edit} onClose={() => this.setState({ edit: false })}>
                        <AuthContext.Consumer>{(auth) => (

                            <CRUDForm {...updateFormConfig}
                                entity={this.props.kiosk}
                                role={CRUDOperation.Update}
                                attributes={hideTagsIfCannotModify(auth, kioskAttributes)} />
                        )}
                        </AuthContext.Consumer>
                    </Modal>
            }}
            </AuthContext.Consumer>

            {this.state.editButtons &&
                <Modal title={t(`${ns}.edit-buttons`, { ns: ns }) + ': ' + this.props.kiosk.name} isOpen={this.state.editButtons}
                    onClose={() => this.setState({ editButtons: false })} height='90vh' width={{ xs: 'min(90vw, 360px)', md: 'auto' }}>
                    <Buttons kiosk={this.props.kiosk} onSubmit={() => {
                        snackNotifications.success(this.props.t('common.request-completed'));
                        if (shouldCloseCtx.shouldClose) this.setState({ editButtons: false });
                    }} />
                </Modal>}

            {this.state.scheduleUptime &&
                <Modal title={t(`${ns}.schedule-uptime`, { ns: ns }) + ': ' + this.props.kiosk.name} isOpen={this.state.scheduleUptime}
                    onClose={() => this.setState({ scheduleUptime: false })}>
                    <ScheduleUptime kiosk={this.props.kiosk} kioskService={this.relevantKioskService()} onSubmit={() => {
                        snackNotifications.success(this.props.t('common.request-completed'));
                        shouldCloseCtx.shouldClose && this.setState({ scheduleUptime: false })
                    }
                    } />
                </Modal>}

            {this.state.uploadLogo &&
                <Modal title={t(`${ns}.upload-logo`, { ns: ns }) + ': ' + this.props.kiosk.name} isOpen={this.state.uploadLogo}
                    onClose={() => this.setState({ uploadLogo: false })}>
                    <LogoUploader />
                </Modal>}

            {this.state.scheduleSound &&
                <Modal title={t(`${ns}.schedule-sound`, { ns: ns }) + ': ' + this.props.kiosk.name} isOpen={this.state.scheduleSound}
                    onClose={() => this.setState({ scheduleSound: false })}>
                    <ScheduleAudio kiosk={this.props.kiosk} kioskService={this.relevantKioskService()} onSubmit={() => {
                        snackNotifications.success(this.props.t('common.request-completed'));
                        shouldCloseCtx.shouldClose && this.setState({ scheduleSound: false })
                    }
                    } />
                </Modal>}

            {this.state.showWhitelist &&

                <Modal title={t(`${ns}.show-whitelist`, { ns: ns }) + ': ' + this.props.kiosk.name} isOpen={this.state.showWhitelist}
                    width={{ xs: '90vw', md: '70vw' }} height={{ xs: '90vh', md: '80vh' }} onClose={() => this.setState({ showWhitelist: false })}>
                    <AuthContext.Consumer>{auth =>
                        <FileEditor directory="configuration" highlightLanguage="ini" /*path={`/whitelist-${this.props.kiosk.identifier}.txt`}*/
                            path={'/kiosk/' + this.props.kiosk.id + '/whitelist'} apiMode={ true }
                            readonly={!isAuthorized(auth, permissions.configuration.write)} onSubmit={() => {
                                snackNotifications.success(this.props.t('common.request-completed'));
                                shouldCloseCtx.shouldClose && this.setState({ showWhitelist: false })
                            }
                            } />}
                    </AuthContext.Consumer>
                </Modal>}

            {this.state.cloneConfig &&
                <Modal title={t(`module.kiosks.clone-config`, { ns: 'module.kiosks' }) + ': ' + this.props.kiosk.name}
                    isOpen={this.state.cloneConfig} onClose={() => this.setState({ cloneConfig: false })}>
                    <CloneConfig kiosk={this.props.kiosk} activePermissions={this.props.activePermissions}
                        onSubmit={() => { snackNotifications.success(this.props.t('common.request-completed')); this.setState({ cloneConfig: false }); }} />
                </Modal>}

            {this.state.showSwVersions &&
                <Modal title={t('common.software-version')} isOpen={this.state.showSwVersions} width='min(90vw, 50%)' onClose={() => this.setState({ showSwVersions: false })}>
                    <Table sx={{
                        maxWidth: '100%',
                        borderCollapse: 'collapse',
                        fontSize: "14px",
                        '& tr': { maxWidth: '100%' },
                        '& td': { p: 1 },
                        '& tr td:first-of-type': { fontWeight: 'bold' }
                    }}>
                        <tbody>
                            <tr>
                                <td>{t(`${ns}.digipanel-branch`, { ns }) + ''}:</td>
                                <td><Typography variant="body2" sx={this.getVersionStyle(this.state.swUpToDate?.digipanelBranch)}>
                                    {kiosk.status?.digipanel?.branch}
                                </Typography></td>
                            </tr>
                            <tr>
                                <td>{t(`${ns}.digipanel-content`, { ns }) + ''}:</td>
                                <td><Typography variant="body2" sx={this.getVersionStyle(this.state.swUpToDate?.digipanelContent)}>
                                    {kiosk.status?.digipanel?.content}
                                </Typography></td>
                            </tr>
                            <tr>
                                <td>{t(`${ns}.frontend`, { ns }) + ''}:</td>
                                <td><Typography variant="body2" sx={this.getVersionStyle(this.state.swUpToDate?.frontend)}>
                                    {kiosk.status?.frontend?.version ?? '?'}
                                </Typography></td>
                            </tr>
                            <tr>
                                <td>{t(`${ns}.backend`, { ns }) + ''}:</td>
                                <td><Typography variant="body2" sx={this.getVersionStyle(this.state.swUpToDate?.backend)}>
                                    {kiosk.status?.backend?.branch} {kiosk.status?.backend?.version ?? '?'}
                                </Typography></td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal>}

            {this.state.showFortouchDetail && <AuthContext.Consumer>{auth =>
                <Modal title="Fortouch" isOpen={this.state.showFortouchDetail} width='min(90vw, 50%)' onClose={() => this.setState({ showFortouchDetail: false })}>
                    <Table sx={{
                        maxWidth: '100%',
                        borderCollapse: 'collapse',
                        '& tr': { maxWidth: '100%' },
                        '& td': { p: 1 },
                        '& tr td:first-of-type': { fontWeight: 'bold' }
                    }}>
                        <tbody>
                            <tr>
                                <td>{t(`${ns}.fortouch.connected`, { ns }) + ''}:</td>
                                <td>{(kiosk.status?.fortouch?.connected ? this.props.t('common.yes') : this.props.t('common.no')) ?? placeholder}</td>
                            </tr>
                            <tr>
                                <td>{t(`${ns}.fortouch.enabled`, { ns }) + ''}:</td>
                                <td>{(kiosk.status?.fortouch?.enabled ? this.props.t('common.yes') : this.props.t('common.no')) ?? placeholder}</td>
                            </tr>
                            <tr>
                                <td>{t(`${ns}.fortouch.device_name`, { ns }) + ''}:</td>
                                <td>{kiosk.status?.fortouch?.device_name ?? placeholder}</td>
                            </tr>
                            <tr>
                                <td>{t(`${ns}.fortouch.device_node`, { ns }) + ''}:</td>
                                <td>{kiosk.status?.fortouch?.device_node ?? placeholder}</td>
                            </tr>
                        </tbody>
                    </Table><br />
                    <Button variant="contained" color="secondary" startIcon={<RestartAltIcon />} disabled={!isAuthorized(auth, permissions.kiosk.restart) /* FIXME: This will be different permission once added on BE */}
                        onClick={this.restartTouch}>
                        {t(`${ns}.fortouch.restart`, { ns: ns }) + ''}
                    </Button>
                </Modal>}</AuthContext.Consumer>}
        </div>}</ShouldCloseContext.Consumer>
    }
}

// Describe some tag styles using MUI theme:
const styledKioskDetail = withStyles(KioskDetail, (theme: Theme, _props) => ({
    root: {
        '& .kiosk-info-card': {
            backgroundColor: theme.palette.background.default,
            boxShadow: theme.shadows[4],
            borderColor: theme.palette.divider,
        },
        '& .kiosk-info-card h4': {
            color: theme.palette.getContrastText(theme.palette.background.default),
        },
        '& .kiosk-card-icon': {
            color: theme.palette.getContrastText(theme.palette.background.default),
        }
	  }
    })
);

export default hoistStatics(withTranslation()(styledKioskDetail), styledKioskDetail)