import { DateTime } from "luxon";
import { CRUDOperation } from "./CRUDOperation";
import { IDuallyIdentifiable } from "./Identifiable.model";

export function isValidIdentifier<TEntity extends IDuallyIdentifiable>(e: TEntity, k: keyof TEntity, o: CRUDOperation): string | undefined {
    if (typeof e[k] !== 'string')
        return 'validation.value-empty';

    const value = String(e[k]); // there's probably no better way how to tell the compiler to treat e[k] as string
    const matches = value.match('[a-z]([a-z0-9\-]*[a-z0-9])?');

    if (!matches || matches[0] !== value)
        return 'validation.identifier-contents';
    if (value.length === 0)
        return 'validation.identifier-empty';
    if (value.length > 64)
        return 'validation.indentifier-length';
    return undefined;
}

export function isValidColorSimple(value?: string): string | undefined {
    if (!value)
        return 'validation.value-empty';

    const matches = value.match('#([0-9a-fA-F]{3}){1,2}');

    if (!matches || matches[0] !== value)
        return 'validation.hexadecimal-color-code';
    return undefined;
}

export function isValidColor<TEntity>(e: TEntity, k: keyof TEntity, o: CRUDOperation): string | undefined {
    if (typeof e[k] !== 'string')
        return 'validation.value-empty';

    return isValidColorSimple(String(e[k]));
}

export function isValidTimeRange(start?: DateTime, end?: DateTime) {
    if (!start || !end)
        return 'validation.interval-empty';

    if (end < start)
        return 'validation.interval-invalid';
    return undefined;
}