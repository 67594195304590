interface IEnvVariable
{
    name: string;
    value: string;
}

function createDefaultEnvVariable(): IEnvVariable {
    return { name: "", value: "" }
}

export type { IEnvVariable }
export { createDefaultEnvVariable}