import http from "../../system/Communicator";
import { createDefaultKiosk, IKiosk, IKioskFilter } from "./Kiosks.model"
import ICrudService from "../../system/ICrudService"
import { ITag } from "../tags/Tags.model";
import { IPagedResponse, PageQuery } from "../../system/Pagination.model";
import { IButton } from "../dashboard/Button.model";
import { IAudioSchedule, IUptimeSchedule } from "../dashboard/Schedule.model";
import { IEntry, ISearchable } from "../../system/SearchResult.model";
import { Sorting } from "../../system/CRUDTable";
import { combineQueries, encodeAsParams } from "../../system/RequestResponse.model";
import { IPermission } from "../users/Users.model";

interface IKioskTags {
	kioskId: number,
	tagIds: number[]
}

interface IKiosksService extends ICrudService<IKiosk>, ISearchable<IKiosk> {
	getAll(pageQuery?: PageQuery, sort?: Sorting<IKiosk>, withRelatedData?: boolean): Promise<IPagedResponse<IKiosk>>;
	getById(id: Number, withRelatedData: boolean): Promise<IKiosk>;
	
	search(text: string): Promise<IEntry<IKiosk>[]>;
}

interface IKioskConfigService {
	edit(entity: IKiosk): Promise<IKiosk>;

	getTags(entity: IKiosk): Promise<ITag[]>;
	setTags(entity: IKiosk, tags: ITag[]): Promise<void>;

	getAudioSchedule(entity: IKiosk): Promise<IAudioSchedule>;
	setAudioSchedule(entity: IKiosk, schedule: IAudioSchedule): Promise<void>;

	getUptimeSchedule(entity: IKiosk): Promise<IUptimeSchedule>;
	setUptimeSchedule(entity: IKiosk, schedule: IUptimeSchedule): Promise<void>;

	reboot(entity: IKiosk): Promise<void>;
	resync(entity: IKiosk): Promise<void>;
	restartApp(entity: IKiosk): Promise<void>;

	getButtons(entity: IKiosk): Promise<IButton[]>;
	setButtons(entity: IKiosk, buttons: IButton[]): Promise<void>;
	
	restartTouch(entity: IKiosk): Promise<void>;
}

class KiosksService implements IKiosksService, IKioskConfigService {

	async getAll(pageQuery?: PageQuery, sort?: Sorting<IKiosk>, withRelatedData: boolean = true): Promise<IPagedResponse<IKiosk>> {
		const pq = pageQuery?.toString() ?? '';
		const sq = sort?.toString() ?? '';
		const query = combineQueries(pq, sq, `enrich=${withRelatedData}`);

		let kiosks = (await http.get<IPagedResponse<IKiosk>>(`/kiosk?${query}`)).data;
		return kiosks;
	}
	
	async search(text: string): Promise<IEntry<IKiosk>[]> {
		let kiosks = (await http.get<IPagedResponse<IKiosk>>(`/kiosk?enrich=true`)).data;
		return kiosks.data
			.map((kiosk, index) => { return { entity: kiosk, index: index }})
			.filter(x => {
			try {
				let t = text.toLowerCase();
				if (!x || !x.entity || !x.entity.identifier || !x.entity.name)
				{
					return false;
				}
				return x.entity.identifier.toLowerCase().includes(t)
					|| x.entity.name.toLowerCase().includes(t)
					|| x.entity.description.toLowerCase().includes(t)
					|| x.entity.contact?.toLowerCase().includes(t)
			} catch (e) { /* I give up. I have no idea why it crashes here when no results. */ return false; }
			});
	}
	
	async getById(id: Number, withRelatedData: boolean = true): Promise<IKiosk> {
		return (await http.get<IKiosk>(`/kiosk/${id}?enrich=${withRelatedData}`)).data;
	}

	/*async getTagged(include: IKioskFilter['include'], exclude: IKioskFilter['exclude']): Promise<IKiosk[]> {
		const q = encodeAsParams({ include, exclude }, { include: [], exclude: [] });
		return (await http.get<IKiosk[]>(`/kiosk/tagged?${q}`)).data;
	}*/
	async getScoped(scopes: IKioskFilter['scopes']): Promise<IKiosk[]> {
		const q = encodeAsParams({ scopes }, { scopes: [] });
		return (await http.get<IKiosk[]>(`/kiosk/scoped?${q}`)).data;
	}
	
	async create(entity: IKiosk): Promise<IKiosk> {
		const kiosk = (await http.post<IKiosk>("/kiosk", this.pure(entity))).data;
		if (entity.tags !== undefined)
			await this.setTags(kiosk, entity.tags);
		kiosk.tags = entity.tags;
		return kiosk;
	}
	
	async delete(entity: IKiosk): Promise<void> {
		await http.delete<IKiosk>("/kiosk/" + entity.id);
	}
	
	async edit(entity: IKiosk): Promise<IKiosk> {
		let k = (await http.patch<IKiosk>("/kiosk/" + entity.id, this.pure(entity))).data;
		
		if (entity.tags !== undefined) {
			await this.setTags(entity, entity.tags);
			k.tags = entity.tags;
		}
		return k;
	}

	async getTags(entity: IKiosk): Promise<ITag[]> {
		return (await http.get<ITag[]>("/kiosk/" + entity.id + "/tags/")).data;
	}

	async setTags(entity: IKiosk, tags: ITag[]): Promise<void> {
		if (!entity.id)
			return;
		let kioskTags: IKioskTags = { kioskId: entity.id, tagIds: [] };
		kioskTags.tagIds = tags.filter(t => t.id !== null && t.id !== undefined).map(t => t.id!);

		await http.post<IKioskTags>("/kiosk/" + entity.id + "/set-tags", kioskTags);
	}

	async getAudioSchedule(entity: IKiosk): Promise<IAudioSchedule> {
		return (await http.get<IAudioSchedule>("/kiosk/" + entity.id +"/audio-schedule")).data;
	}

	async setAudioSchedule(entity: IKiosk, schedule: IAudioSchedule): Promise<void> {
		return (await http.post<void>("/kiosk/" + entity.id +"/audio-schedule", schedule)).data;
	}

	async getUptimeSchedule(entity: IKiosk): Promise<IUptimeSchedule> {
		return (await http.get<IUptimeSchedule>("/kiosk/" + entity.id +"/uptime-schedule")).data;
	}

	async setUptimeSchedule(entity: IKiosk, schedule: IUptimeSchedule): Promise<void> {
		return (await http.post<void>("/kiosk/" + entity.id +"/uptime-schedule", schedule)).data;
	}

	async reboot(entity: IKiosk): Promise<void> {
		await http.post<void>("/kiosk/" + entity.id +"/reboot");
	}

	async resync(entity: IKiosk): Promise<void> {
		await http.post<void>("/kiosk/" + entity.id +"/resync");
	}

	async restartApp(entity: IKiosk): Promise<void> {
		await http.post<void>("/kiosk/" + entity.id +"/restart");
	}
	
	async restartTouch(entity: IKiosk): Promise<void> {
		await http.post<void>("/kiosk/" + entity.id +"/restart-touch");
	}

	async getButtons(entity: IKiosk): Promise<IButton[]> {
		return (await http.get<IButton[]>("/kiosk/" + entity.id +"/buttons")).data;
	}
	
	async setButtons(entity: IKiosk, buttons: IButton[]): Promise<void> {
		return (await http.post<void>("/kiosk/" + entity.id +"/buttons", buttons)).data;
	}

	createDefaultEntity = createDefaultKiosk;

	private pure(entity: IKiosk): IKiosk {
		let e = Object.assign({}, entity);
		delete e.tags;
		delete e.status;
		return e;
	}

	async getActivePermissions(entity: IKiosk): Promise<IPermission[]> {
		return (await http.get<IPermission[]>("/kiosk/" + entity.id + "/permissions")).data;
	}
}
export default new KiosksService();
export type { IKiosksService, IKioskConfigService }