import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Typography, Input } from '@mui/material';
import FileExplorer from './FileExplorer'
import FileEditor from './FileEditor'
import {ISearchResult} from "../../system/SearchResult.model"
import IModule, { ModuleState } from "../../system/IModule"
import { IUserContext } from '../../system/User.model'
import { setPageTitle } from '../../App'

interface IState {
	testArray : string[]
	testFile? : string
	testFolder? : string,
	something : any,
	something2? : string
}
interface IProps extends WithTranslation { }

@IModule
class Files extends React.Component<IProps, IState, WithTranslation> {

	public static menu() {
		return {
			title : "Files",
			route : "/files",
			icon : <div style={{fontSize: "14px", lineHeight: "14px"}}>test<br />files</div>,
			weight : 200
		};
	}
	
	public static getLocale() { return "module.files";	}
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Files.getLocale()+".title", { ns: Files.getLocale() }));
	}
	
	public static isEnabled(auth: IUserContext) {	
		return ModuleState.DISABLED;
	}	
	
	public static async search(input : string) : Promise<ISearchResult[]> {
		await new Promise(r => setTimeout(r, 2000));
		return [
	{ title : "Zkouška", description : "Tohle je nějaká zkouška", link:"/files/aaa" },
	{ title : "Další test", description : "Tohle je test", link:"/files/bbb" },
	{ title : "Test", description : "Tohle je test", link:"/files/ccc" },
	{ title : "Test X", description : "Tohle je test X", link:"/files/ddd" },
	{ title : "Test 42", description : "Tohle je nějaký velmi dlouhý popisek. Jak si s tím asi prohlížeč poradí? Ještě to trochu natáhneme.", link:"/files/eee" },
	{ title : "Tohle je nějaký velmi dlouhý poposek vyhledané položky v search baru", description : "Tohle je test", link:"/files/fff" },
	{ title : "Bla bla", description : "Tohle je test" , customClick: ()=>{ alert("tohle je CUSTOM click!") } },						
];
	}
	
	constructor(props: IProps) {		
		super(props);
		this.state = {
			testArray : ["/MEDIA/test.png", "/MEDIA/logo2.png"],
			testFile : undefined,
			testFolder : undefined,
			something : undefined,
			something2 : undefined
		}
	}
	
	render() {
		return (<Fragment>
		<Typography variant="h2">
			Files
		</Typography>
		
		{/* This is the full page file explorer: */}
		<FileExplorer folder="configuration" mode="full" />
		{/* Might need to fix the height with CSS later. */}
		
		<br />
		
		{/* This is form example with dialogs: */}
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div>
		<h3>Form example</h3>
		<table style={{border : "1px solid", padding: "20px"}}>
		<tbody>
			<tr>
				<td>Some text input:</td>
				<td><Input type="text" /></td>
			</tr>
			<tr>
				<td>Some other input:</td>
				<td><Input type="text" /></td>
			</tr>
			<tr>
				<td>Choose files:</td>
				<td>
					{/* Form for selecting multiple files. */}
					<FileExplorer folder="media"
						mode="input"
						output="multipleFiles"
						value={this.state.testArray}
						onChange={(val) => this.setState({testArray : val as string[]})}
						filter={["txt", ".PDF", "*.JpEg", "png"]}
						/>
				</td>
			</tr>
			<tr>
				<td>Choose ONE file:</td>
				<td>
					{/* Form for selecting one specific file. */}
					<FileExplorer folder="media"
						mode="input"
						output="singleFile"
						value={this.state.testFile}
						onChange={(val) => this.setState({testFile : val as string})}
						filter={["*.PNG"]}
						/>
				</td>
			</tr>
			<tr>
				<td>Select folder:</td>
				<td>
					{/* Form for selecting one specific file. */}
					<FileExplorer folder="media"
						mode="input"
						output="folder"
						value={this.state.testFolder}
						onChange={(val) => this.setState({testFolder : val as string})}
						filter={["*.PNG"]}
						/>
				</td>
			</tr>
			<tr>
				<td>Undefined output:</td>
				<td>
					{/* Form for selecting one specific file. */}
					<FileExplorer folder="media"
						mode="input"
						value={this.state.something}
						onChange={(val) => this.setState({something : val as string})}
						filter={["*.PNG"]}
						/>
				</td>
			</tr>
			<tr>
				<td>File or folder:</td>
				<td>
					{/* Form for file OR folder */}
					<FileExplorer folder="MEDIA"
						mode="input"
						output="fileOrFolder"
						value={this.state.something2}
						onChange={(val) => this.setState({something2 : val as string})}
						/>
				</td>
			</tr>
			<tr>
				<td>Some number:</td>
				<td><Input type="number" /></td>
			</tr>
		</tbody>
		</table></div></div>
		<br /><br />
		
		<FileEditor directory="CONFIGURATION" path="/whitelist.txt" />
		
		<br /><br />
		
		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(Files), Files)