import React from 'react';
import { Typography } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next'
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { setPageTitle } from '../App'

interface IState { }
interface IProps extends WithTranslation { }

class Page404 extends React.Component<IProps, IState, WithTranslation> {

	componentDidMount()
	{
		setPageTitle(this.props.t("error404.title"));
	}
	
	render() {
		const t = this.props.t;
	
		return (<div className="error-page page-404">
		<div style={{position: "relative"}}>
			<DoNotDisturbIcon className="page-icon" />
			<DoNotDisturbIcon className="page-icon-shadow" />
		</div>
		<Typography variant="h1">
			{t("error404.title")}
		</Typography>
		<Typography variant="h6">
			{t("error404.description")}
		</Typography>
		</div>);
	}
}

export default withTranslation()(Page404);