import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TagService from './Tags.service';
import { ITag } from './Tags.model';
import { FieldInputProps, FormikProps } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { withTranslation, WithTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import Tags from './Tags';
import { triggerFormChange } from '../../system/Formik.model';


interface IState { 
	tags: ITag[]
}

interface IProps extends WithTranslation {
	field: FieldInputProps<ITag[]>,
    form: FormikProps<any>, // can be used versatilely
    labelKey?: string,
    namespace?: string,
	className: string,
	disabled?: boolean
}

class TagSelect extends React.Component<IProps, IState, WithTranslation> {

	constructor(props : IProps) {
		super(props);
		this.state = {
			tags: []
		}

		this.setSelected = this.setSelected.bind(this);
		this.tagsEqual = this.tagsEqual.bind(this);
	}

	async componentDidMount() {
		var all = (await TagService.getAll()).data;
		this.setState({
			tags: all,
		});
	}

	tagsEqual(a: ITag, b: ITag) {
		return a.id === b.id;
	}

	setSelected(tags?: ITag[]) {
		triggerFormChange(this.props.form, this.props.field.name, tags, 'tagSelect');
	}

	render() {
		return <Box className={'tagSelect ' + this.props.className}>
			{ this.state.tags === null && (<Box sx={{ display: 'flex' }}>
				<CircularProgress />
			</Box>) }
		
			{ this.state.tags && <Autocomplete
				options={this.state.tags}
				isOptionEqualToValue={this.tagsEqual}
				getOptionLabel={(tag: ITag) => tag.name}
				autoHighlight
				filterSelectedOptions
				multiple

				disabled={this.props.disabled}
				value={this.props.field.value}
				onChange={(_event, value, _reason, _details) => this.setSelected(value)}

				renderInput={(params) =>
				<TextField variant='outlined'
					{...params}
					fullWidth
					label={this.props.labelKey ? this.props.t(`${this.props.namespace}.${this.props.labelKey}`, {ns: this.props.namespace}) : undefined}
					placeholder={this.props.t(`${Tags.getLocale()}.tag-input-help`, {ns: Tags.getLocale()})}
					className='entityInputField'
					inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
				}
			/>}
		</Box>
	}
}

export default hoistStatics(withTranslation()(TagSelect), TagSelect)