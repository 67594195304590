import { IUserContext } from './User.model'
import {ISearchResult} from "./SearchResult.model"

export enum ModuleState {
	ENABLED,
	NO_PERMISSIONS,
	DISABLED,
	ENABLED_HIDDEN,
}

interface IModuleT {
	menu: (t: ((x:string, y:any)=>string)) => { title : string, route : string, icon : any, weight: number }
	getLocale: () => string | null
	search: (input : string) => Promise<ISearchResult[] | null> | null
	isEnabled: (auth: IUserContext) => ModuleState
	
	new (...args: any[]): any;
}

/*
	TypeScript is absolutely idiotic and doesn't allow static methods in interfaces 
	or static abstract methods in abstract classes. So we need to export not the interface
	itself, but some wrapper like this. Then we can't use "class OurClass implements IModule",
	instead we need to use this decorator: "@IModule \n class OurClass".
*/

export default function IModule(constructor: IModuleT) { }