import React, { HTMLInputTypeAttribute } from 'react';
import UserService, { IUserService } from './Users.service';
import { withTranslation, WithTranslation } from 'react-i18next'
import { IErrors } from '../../system/CRUDForm';
import { IPasswordChange } from './Users.model';
import SaveIcon from '@mui/icons-material/Save';
import { Field, Form, Formik } from 'formik';
import { Button, IconButton, InputAdornment } from '@mui/material';
import hoistStatics from 'hoist-non-react-statics';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import MuiField from '../../system/MuiField';
import { UserContext } from '../../system/User.model';
import { AuthContext } from '../../system/Base';

interface IState {
	entity: IPasswordChange,
    attributes: IAttribute[]
}
interface IProps extends WithTranslation {
    userId: number,
    onDone: () => void
}

interface IAttribute {
    name: string,
    labelKey: string,
    type: HTMLInputTypeAttribute
}

class PasswordChange extends React.Component<IProps, IState, WithTranslation> {
	
	userService: IUserService;

	public constructor(props: IProps) {
		super(props);

		this.state = {
			entity: {
                id: this.props.userId,
                password: '',
                newPassword: '',
                newPasswordCheck: ''
            },
            attributes: [
                { name: 'password', labelKey: 'old-password', type: 'password' },
                { name: 'newPassword', labelKey: 'new-password', type: 'password' },
                { name: 'newPasswordCheck', labelKey: 'confirm-new-password', type: 'password' },
            ]
		};

		this.userService = UserService;

		this.validate = this.validate.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.display = this.display.bind(this);
	}

	validate(values: IPasswordChange) {
		let errors = {} as IErrors<IPasswordChange>;
        const t = this.props.t;

        if (!values.password)
            errors.password = t('validation.value-empty');
        
        if (!values.newPassword)
            errors.newPassword = t('validation.value-empty');

        if (!values.newPasswordCheck
            || values.newPasswordCheck !== values.newPassword)
            errors.newPasswordCheck = t('validation.password-mismatch');

		return errors;
	}

    display(attr: IAttribute, display: boolean) {
        const newType: HTMLInputTypeAttribute = display ? 'text' : 'password'; 
        const newAttributes = this.state.attributes.map(a => {
            if (a.name !== attr.name)
                return a;
            return { ...attr, type: newType };
        });
        this.setState({ attributes: newAttributes });
    }

	async changePassword(passwordChange: IPasswordChange, userId: number) {
		await this.userService.changePassword(passwordChange);
        this.props.onDone();
	}
	
	render() {
	
		const t = this.props.t;
        const ns = 'module.users';
        const submit = <Button sx={{ mt: '1rem' }} variant="contained" type="submit" startIcon={<SaveIcon />}>
                {t('common.save', {ns: 'common'})}
            </Button>;

        const form = <Form className="modal-form">
                {this.state.attributes.map(a => 
                    <div key={a.name} className="field-container">
                        <Field name={a.name} type={a.type} labelKey={a.labelKey} namespace={ns} component={MuiField}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => this.display(a, a.type === 'password')}>
                                        {a.type === 'password' ? <Visibility  /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                              }}
                        />
                    </div>
                )}
            {submit}
        </Form>;

        return <AuthContext.Consumer>{auth =>
            <Formik
                initialValues={this.state.entity}
                validate={this.validate}
                onSubmit={async (p: IPasswordChange) => await this.changePassword(p, auth.id)}>
                {form}
            </Formik>
        }</AuthContext.Consumer>;
	}
}

export default hoistStatics(withTranslation()(PasswordChange), PasswordChange)