import { IIdentifiable } from "../../system/Identifiable.model";
import { IScope } from "../tags/Scopes.model";
import { IPermission } from "./Users.model";

interface IRole extends IIdentifiable {
	name: string,
	description: string,
	permissions?: IPermission[]
}

/*interface IRole extends IIdentifiable {
	entity: IRoleEntity,
}*/

interface IScopedRole {
	role: IRole,
	scope: IScope | null
}

interface IScopedRolePrimitive {
	user: number,
	role: number,
	scope: number
}

function createDefaultRole(): IRole {
	return {
		//entity: {
			name: '',
			description: '',
			permissions: []
		//}
	};
}

export type { IRole, IScopedRole, IScopedRolePrimitive/*, IRoleEntity*/ }
export { createDefaultRole }