import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { AuthContext } from "./Base";
import { IEntity } from "./CRUDForm";
import { ListEditor } from "./ListEditor";
import MuiField from "./MuiField";
import { isAuthorized } from "./User.model";
import { Box, Button, Card, CardActions, CardContent, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { WithTranslation, withTranslation } from "react-i18next";
import { IUser } from "../modules/users/Users.model";
import React from "react";
import UserService, { ISharePrimitive, IUserService } from "../modules/users/Users.service";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import UserStrip from "../modules/profile/UserStrip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { CRUDButton } from "./CRUDButton";
import FileService from '../modules/files/Files.service';

export enum SharingMode {
    CHANGE,
    ADD,
    DELETE
}

interface IPropsShare extends WithTranslation {
    onDone?(values: ISharePrimitive[]): void | Promise<void>,
    resourceId?: number,
    sharingMode: SharingMode,
    library?: string | undefined,
    paths?: string[] | undefined,
    showRecursiveOption: boolean,
}

interface IPropsChown extends WithTranslation {
    onDone?(newOwnerId: number, recursive: boolean): void | Promise<void>,
    resourceIds?: number[],
    oldOwnerId: number,
    showRecursiveOption: boolean,
    library?: string | undefined,
    paths?: string[] | undefined
}

interface IStateShare {
    initShares: IShareFormData;
    userList?: IUser[];
    addRemUserId: number;
    addRemMode: "READ_ONLY" | "READ_WRITE";
    recursive: boolean;
}

interface IStateChown {
    userList?: IUser[];
    newOwnerId: number;
    recursive: boolean;
}

interface IShareFormData {
    shares: ISharePrimitive[];
}

class SharePanelCl extends React.Component<IPropsShare, IStateShare, WithTranslation> {
    userService: IUserService;

    public constructor(props: IPropsShare) {
        super(props);

        this.state = {
            initShares: { shares: [] },
            userList: undefined,
            addRemUserId: 0,
            addRemMode: "READ_ONLY",
            recursive: false
        };

        this.userService = UserService;
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.sharingMode === SharingMode.CHANGE) {
            const users = await (await this.userService.getAll()).data;
            if (this.props.resourceId) {
                const myShares = await (await this.userService.getShares(this.props.resourceId));
                this.setState({
                    userList: users,
                    initShares: { shares: myShares }
                });
            } else {
                if (this.props.library && this.props.paths?.length === 1 && this.props.sharingMode === SharingMode.CHANGE) {
                    const myShares = await (await this.userService.getSharesFS(this.props.library, this.props.paths[0]));
                    this.setState({
                        userList: users,
                        initShares: { shares: myShares }
                    });
                }
            }
        } else {
            const users = await (await this.userService.getAll()).data;
            this.setState({
                userList: users
            });
        }
    }

    doingSubmit = false;
    async onFormSubmit(values: IShareFormData) {
        if (this.doingSubmit) return;
        this.doingSubmit = true;
        if (this.props.resourceId && this.props.sharingMode === SharingMode.CHANGE) {
            // Resource ID sharing
            await this.userService.setShares(this.props.resourceId, values.shares);
        } else {
            // File system sharing
            if (this.props.paths && this.props.library) {
                if (this.props.sharingMode === SharingMode.CHANGE && this.props.paths.length === 1) {
                    await (this.userService.setSharesFS(this.props.library, this.props.paths[0], values.shares));
                }
                if (this.props.sharingMode === SharingMode.ADD) {
                    for (let path of this.props.paths) {
                        await (this.userService.addSharesFS(this.props.library, path, [{ mode: this.state.addRemMode, userId: this.state.addRemUserId }], this.state.recursive));
                    }
                }
                if (this.props.sharingMode === SharingMode.DELETE) {
                    for (let path of this.props.paths) {
                        await (this.userService.delSharesFS(this.props.library, path, [{ mode: this.state.addRemMode, userId: this.state.addRemUserId }], this.state.recursive));
                    }
                }
            }
        }
        await this.props.onDone?.(values.shares);
        this.doingSubmit = false;
    }

    render() {
        const t = this.props.t;
        const ns = 'module.users';

        const form = (props: FormikProps<IShareFormData>) => {
            return <Form>
                {this.props.sharingMode === SharingMode.CHANGE &&
                    <FieldArray
                        name="shares"
                        render={arrayHelpers => (
                            <Box>
                                {props.values.shares.map((value, index) => (<Card key={index} variant="outlined" sx={{ minWidth: 275, mb: '1rem', boxShadow: 2 }}>
                                    <CardContent key={"x-" + index}>
                                        <Field name={`shares.${index}.userId`} component={MuiField} variant='select' labelKey="set-share.user" namespace="module.users" value={value.userId}>
                                            {this.state.userList!.map((user, ind) => <MenuItem key={ind} value={user.id!}><UserStrip user={user} /></MenuItem>)}
                                        </Field>
                                        <Field name={`shares.${index}.mode`} component={MuiField} variant='select' labelKey="set-share.mode" namespace="module.users" value={value.mode}>
                                            <MenuItem value="READ_ONLY">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <VisibilityIcon sx={{ mr: '10px' }} />
                                                    <div>
                                                        {t("share.mode.read-only", { ns: ns })}
                                                    </div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value="READ_WRITE">
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <EditIcon sx={{ mr: '10px' }} />
                                                    <div>
                                                        {t("share.mode.read-write", { ns: ns })}
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        </Field>
                                    </CardContent>

                                    <CardActions disableSpacing key={"y-" + index}>
                                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                                ))}
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                                    <Button variant="outlined"
                                        startIcon={<AddIcon />}
                                        onClick={() => { arrayHelpers.push({ userId: 0, mode: "READ_ONLY" }); }}>
                                        {t(`${ns}.add-share`, { ns: ns })}
                                    </Button>
                                    <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
                                        {t("common.save", { ns: 'common' })}
                                    </Button>
                                </Box>
                            </Box>
                        )} />
                }
            </Form>
        }

        if (this.props.sharingMode === SharingMode.CHANGE)
            return this.state.userList !== undefined && this.state.userList !== null && (<Formik
                initialValues={this.state.initShares}
                onSubmit={this.onFormSubmit}
                component={form}
            >
            </Formik>);
        else {
            return this.state.userList !== undefined && this.state.userList !== null && <>
                <Box>
                    <FormControl fullWidth>
                        <InputLabel id="share-user-id-select-label">{t("module.users.set-share.user", { ns: ns })}</InputLabel>
                        <Select labelId="share-user-id-select-label" label={t("module.users.set-share.user", { ns: ns })} value={this.state.addRemUserId}
                            onChange={(e => { this.setState({ addRemUserId: Number(e.target.value) }) })}>
                            {this.state.userList!.map((user, ind) => <MenuItem key={ind} value={user.id!}><UserStrip user={user} /></MenuItem>)}
                        </Select>
                    </FormControl>
                    {this.props.sharingMode === SharingMode.ADD &&
                        <FormControl fullWidth>
                            <InputLabel id="share-mode-select-label">{t("module.users.set-share.mode", { ns: ns })}</InputLabel>
                            <Select labelId="share-mode-select-label" label={t("module.users.set-share.mode", { ns: ns })} value={this.state.addRemMode}
                                onChange={(e => { this.setState({ addRemMode: e.target.value as "READ_ONLY" | "READ_WRITE" }) })}>
                                <MenuItem value="READ_ONLY">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <VisibilityIcon sx={{ mr: '10px' }} />
                                        <div>
                                            {t("share.mode.read-only", { ns: ns })}
                                        </div>
                                    </div>
                                </MenuItem>
                                <MenuItem value="READ_WRITE">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <EditIcon sx={{ mr: '10px' }} />
                                        <div>
                                            {t("share.mode.read-write", { ns: ns })}
                                        </div>
                                    </div>
                                </MenuItem>
                            </Select>
                        </FormControl>}
                        {this.props.showRecursiveOption &&
                            <FormControlLabel control={
                                <Checkbox checked={this.state.recursive} onChange={(e) => { this.setState({ recursive: e.target.checked }); }} />
                            } label={t("module.files.apply-recursively", { ns: "module.files" })} />
                        }
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                    <Button variant="contained" type="submit" startIcon={<SaveIcon />} onClick={() => { this.onFormSubmit({} as IShareFormData) }}>
                        {t("common.save", { ns: 'common' })}
                    </Button>
                </Box>
            </>
        }
    }
}

class ChangeOwnerPanelCl extends React.Component<IPropsChown, IStateChown, WithTranslation> {
    userService: IUserService;

    async componentDidMount() {
        const users = await (await this.userService.getAll()).data;
        this.setState({
            userList: users
        });
    }

    public constructor(props: IPropsChown) {
        super(props);

        this.state = {
            userList: undefined,
            newOwnerId: this.props.oldOwnerId,
            recursive: false
        };

        this.userService = UserService;
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    doingSubmit = false;
    async onFormSubmit(newOwnerId: number) {
        if (this.doingSubmit) return;
        this.doingSubmit = true;
        if (this.props.library && this.props.paths && this.props.paths.length) {
            for (let i = 0; i < this.props.paths.length; i++) {
                let item = this.props.paths[i];
                let recursive = this.state.recursive;
                await FileService.changeOwnerFS(this.props.library, item, newOwnerId, recursive);
            }
        }
        else {
            if (this.props.resourceIds) {
                for (let i = 0; i < this.props.resourceIds.length; i++) {
                    let item = this.props.resourceIds[i];
                    await FileService.changeOwner(item, newOwnerId);
                }
            }
        }
        await this.props.onDone?.(newOwnerId, this.state.recursive);
        this.doingSubmit = false;
    }

    render() {
        const t = this.props.t;
        const ns = 'module.files';
        return <><div key="chown-item" className="field-container">
			    <TextField
				    fullWidth select
				    value={this.state.newOwnerId} onChange={(e) => { this.setState({ newOwnerId: Number(e.target.value) }) }}
                    label={t("module.files.new-owner", {ns: ns})}>

				    {this.state.userList?.map(u => {
					    return (<MenuItem value={u.id!} key={u.id!}><UserStrip user={u} /></MenuItem>)
				    })}
                </TextField>
            {this.props.showRecursiveOption &&
                <FormControlLabel control={
                    <Checkbox checked={this.state.recursive} onChange={(e) => { this.setState({ recursive: e.target.checked }); }} />
                } label={t("module.files.apply-recursively", { ns: ns })} />
                }
		    </div>
		    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                <CRUDButton variant="contained" onClick={() => { this.onFormSubmit(this.state.newOwnerId); }} />
            </Box>
        </>
    }
}


/*const exp = {
    ChangeOwnerPanel: withTranslation()(ChangeOwnerPanel),
    SharePanel: withTranslation()(SharePanel) 
};

export default exp;*/

export const ChangeOwnerPanel = withTranslation()(ChangeOwnerPanelCl);
export const SharePanel = withTranslation()(SharePanelCl);