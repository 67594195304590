import http from "../../system/Communicator";
import { IEnvVariable } from "./Admin.model";

class AdminService {

	async getInfo(): Promise<any> {
		let info = (await http.get<any>(`/admin/info`)).data;
		return info;
	}
	
	async getEnvVariables(): Promise<IEnvVariable[]> {
		return (await http.get<IEnvVariable[]>(`/admin/env-variables`)).data;
	}

    async setEnvVariables(variables: IEnvVariable[]): Promise<IEnvVariable[]> {
		return (await http.post<IEnvVariable[]>(`/admin/env-variables`, variables)).data;
	}

	async addEnvVariable(variable: IEnvVariable) {
		await http.post<IEnvVariable>(`/admin/env-variable`, variable);
	}
	
	async deleteEnvVariable(variable: IEnvVariable): Promise<void> {
		await http.delete<IEnvVariable>(`/admin/env-variable${variable.name}`);
	}
}
export default new AdminService();