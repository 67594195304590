import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Schedule from "./Schedule";
import { IUptimeSchedule } from "./Schedule.model";
import { Box } from "@mui/system";
import { IKioskConfigService } from "../kiosks/Kiosks.service";
import { IKiosk } from "../kiosks/Kiosks.model";

interface IState {
    values: IUptimeSchedule
    initialized: boolean
}

interface IProps extends WithTranslation {
    kiosk: IKiosk
    onSubmit: (values: IUptimeSchedule) => void
	kioskService: IKioskConfigService,
}

class ScheduleUptime extends React.Component<IProps, IState, WithTranslation> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            values: { config: [], exceptions: []},
            initialized: false
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    async componentDidMount() {
        const schedule = await this.props.kioskService.getUptimeSchedule(this.props.kiosk);
        this.setState({ values: schedule, initialized: true });
    }

    async onFormSubmit(values: IUptimeSchedule) {
        await this.props.kioskService.setUptimeSchedule(this.props.kiosk, values);
        this.props.onSubmit(values);
    }

    render() {
        const t = this.props.t;
        const ns = 'module.dashboard';

        return this.state.initialized && <Schedule values={this.state.values} onSubmit={this.onFormSubmit}>
            <Box sx={{mt: '1rem', mb: '2rem'}}>
                {t(`${ns}.default-kiosk-off`, {ns: ns})}
            </Box>
        </Schedule>;
    }
}

export default hoistStatics(withTranslation()(ScheduleUptime), ScheduleUptime)