import { Theme } from "@mui/material";
import React from "react";
import { withStyles } from "tss-react/mui";

interface IProps {
    percent: number,
    className?: string
}

interface IState {
}

class PieChart extends React.Component<IProps, IState> {

    render() {
        return <svg className={this.props.className} viewBox="0 0 42 42" width="100%" height="100%">
                <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" strokeWidth="5" stroke="#CCC"></circle>
                <circle cx="21" cy="21" r="15.91549430918954" fill="transparent" strokeWidth="5" stroke="#212342" strokeDasharray={`${this.props.percent} ${100 - this.props.percent}`} strokeDashoffset="0"></circle>
                <text className="label" x="21" y="21" textAnchor="middle" dominantBaseline="middle" fontSize="7">{this.props.percent + '%'}</text>
            </svg>
    }
}

// Describe some tag styles using MUI theme:
const styled = withStyles(PieChart, (theme: Theme, _props) => ({
    root: {
        '& .label': {
            fill: theme.palette.getContrastText(theme.palette.background.default),
        }
	  }
    })
);

export { styled as PieChart }