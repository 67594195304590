import { DateTime } from "luxon"
import { IIdentifiable } from "../../system/Identifiable.model"
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from "../../system/User.model"
import { ITag } from "../tags/Tags.model"
import { IScope } from "../tags/Scopes.model"

interface IPlaylist extends IIdentifiable {
	name: string,
	slides?: (ISlide | IRssSlide | IBannerSlide)[],
    schedule?: (IScheduleRecord | ITaggedScheduleRecord)[]
}

enum SlideType {
    Media = "media", Web = "web", Rss = "rss", Banner = "banner"
}

interface ISlide {
    position: number,
    type: SlideType,
    duration: number,
    url: string
}

interface IRssSlide extends ISlide {
    count: number,
    background: string
}

interface IBannerSlide extends ISlide {
    title: string,
    description: string,
    color: string,
    background: string
}

interface IScheduleRecord {
    area: string,
    begin: string, // ISO with timezone
    end: string // ISO with timezone
}

interface ITaggedScheduleRecord extends IScheduleRecord {
    scopes: IScope[]
    /*include: ITag[],
    exclude: ITag[]*/
}

function isTagged(record: IScheduleRecord | ITaggedScheduleRecord): record is ITaggedScheduleRecord {
    return 'scopes' in record/*&& 'exclude' in record*/;
}

interface ITaggedScheduleRecordDTO extends IScheduleRecord {
    scopes: number[]
    /*include: number[], // tag ids
    exclude: number[] // tag ids*/
}

function createDefaultPlaylist(): IPlaylist {
    return {
        name: '',
        slides: [],
        schedule: []
    };
}

function createDefaultSlide(): ISlide {
    return {
        position: 0,
        type: SlideType.Media,
        url: '',
        duration: 5000
    };
}

function canEditSlides(auth: IUserContext): boolean {
    return isAuthorized(auth, permissions.playlist.update)
        && isAuthorized(auth, permissions.content.read)
        && isAuthorized(auth, permissions.media.read);
}

function canEditSchedule(auth: IUserContext, contentAreas: string[]): boolean {
    return isAuthorized(auth, permissions.playlist.update)
        && (!hasController(auth, controllerKeys.tag) || isAuthorized(auth, permissions.tag.read))
        && canManageAreas(auth, contentAreas);
}

function canManageAreas(auth: IUserContext, contentAreas: string[]): boolean {
    return contentAreas.some((area) => isAuthorized(auth, permissions.playlist.manageArea + area));
}

// Include and exclude are ignored by local backend
function createDefaultScheduleRecord(auth: IUserContext, contentAreas: string[]): ITaggedScheduleRecord {
    const area = contentAreas.find((area) => isAuthorized(auth, permissions.playlist.manageArea + area));

    if (!area)
        throw new Error('The user is not allowed to create schedules records.');

    return {
        area: area,
        begin: DateTime.now().startOf('day').toUTC().toISO(),
        end: DateTime.now().startOf('day').toUTC().toISO(),
        scopes: []
        /*include: [],
        exclude: []*/
    }
}

function isRssSlide(slide: ISlide): slide is IRssSlide {
    return slide.type === SlideType.Rss && 'count' in slide && 'background' in slide;
}

function isBannerSlide(slide: ISlide): slide is IBannerSlide {
    return slide.type === SlideType.Banner && 'title' in slide && 'description' in slide && 'color' in slide;
}

function toBase(slide: ISlide, type: SlideType): ISlide {
    return { 
        position: slide.position,
        type: type,
        url: slide.url,
        duration: slide.duration
    };
}

function toMedia(slide: ISlide): ISlide {
    return toBase(slide, SlideType.Media);
}

function toWeb(slide: ISlide): ISlide {
    return toBase(slide, SlideType.Web);
}

function toRss(slide: ISlide): IRssSlide {
    const base = toBase(slide, SlideType.Rss);

    if (isRssSlide(slide))
        return {...base, count: slide.count, background: slide.background} as IRssSlide;
    return {...base, count: 1, background: ''} as IRssSlide;
}

function toBanner(slide: ISlide): IBannerSlide {
    const base = toBase(slide, SlideType.Banner);

    if (isBannerSlide(slide))
        return {...base, title: slide.title, description: slide.description, color: slide.color, background: slide.background } as IBannerSlide;
    return {...base, title: '', description: '', color: '#ffffff'} as IBannerSlide;
}

/* type slideTypeMap = {
    [SlideType.Media]: ISlide,
    [SlideType.Web]: ISlide,
    [SlideType.Rss]: IRssSlide,
    [SlideType.Banner]: IBannerSlide
} */

interface IAddFilesRequest {
    files: string[], // paths
    playlist: string, // name
    isNew: boolean,
    requested: string // ISO
}

function isValid(fileReq: IAddFilesRequest) {
    const hasAll = fileReq.files && fileReq.playlist && fileReq.isNew !== undefined && fileReq.isNew !== null && fileReq.requested;
    return hasAll && DateTime.fromISO(fileReq.requested).valueOf() + 15000/*ms*/ > DateTime.now().valueOf();
}

export type { IPlaylist, ISlide, IRssSlide, IBannerSlide, IScheduleRecord, ITaggedScheduleRecord, ITaggedScheduleRecordDTO, IAddFilesRequest }
export {
    SlideType, toMedia, toWeb, toRss, toBanner, isRssSlide, isBannerSlide,
    isTagged,
    createDefaultPlaylist, createDefaultSlide, createDefaultScheduleRecord,
    canManageAreas, canEditSlides, canEditSchedule,
    isValid
 }