import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { Box, Button, Card, CardActions, CardContent, IconButton, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { IRole, IScopedRolePrimitive, createDefaultRole } from './Role.model';
import { IScope } from '../tags/Scopes.model';
import MuiField from '../../system/MuiField';
import RoleService, { IRoleService } from './Roles.service';
import ScopesService, { IScopeService } from '../tags/Scopes.service';
import SaveIcon from '@mui/icons-material/Save';
import UserService, { IUserService } from './Users.service';
import { ITableEntity } from '../../system/ICrudService';
import { ControllerDeployment, IUserContext, controllerKeys, hasController } from '../../system/User.model';

interface IProps extends WithTranslation {
    onDone?(values: IScopedRolePrimitive[]): void | Promise<void>,
    userId: number,
    isGlobal: boolean
}

interface IState {
    initRoles: IRoleFormData;
    roleList?: ITableEntity<IRole>[];
    scopeList?: IScope[];
}

interface IRoleFormData {
    roles: IScopedRolePrimitive[];
}

export class RolesChange extends React.Component<IProps, IState, WithTranslation> {

    roleService: IRoleService;
    scopeService: IScopeService;
    userService: IUserService;

    public constructor(props: IProps) {
        super(props);

        this.state = {
            initRoles: { roles: []},
            roleList: undefined,
            scopeList: undefined,
        };

        this.roleService = RoleService;
        this.scopeService = ScopesService;
        this.userService = UserService;
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    isGlobal(auth: IUserContext) {
        return hasController(auth, controllerKeys.kiosk, ControllerDeployment.Global);
    }

    async componentDidMount() {
        const roles = await (await this.roleService.getAll()).data;
        const scopes = (this.props.isGlobal) ? await (await this.scopeService.getAll()).data : [];
        const myRoles = await (await this.userService.getUserRoles(this.props.userId));
        this.setState({
            roleList: roles,
            scopeList: scopes.map(x => x.entity),
            initRoles: { roles: myRoles }
        });
    }

    doingSubmit = false;
    async onFormSubmit(values: IRoleFormData) {
        if (this.doingSubmit) return;
        this.doingSubmit = true;

        await this.userService.setUserRoles(this.props.userId, values.roles);

        await this.props.onDone?.(values.roles);
        this.doingSubmit = false;
    }

    render() {
        const t = this.props.t;
        const ns = 'module.users';

        const form = (props: FormikProps<IRoleFormData>) => {
            return <Form>
                <FieldArray
                    name="roles"
                    render={arrayHelpers => (
                        <Box>
                            {props.values.roles.map((value, index) => (
                                <Card key={index} variant="outlined" sx={{ minWidth: 275, mb: '1rem', boxShadow: 2 }}>
                                    <CardContent>
                                        <Field name={`roles.${index}.role`} component={MuiField} variant='select' labelKey="set-role.role" namespace="module.users" value={value.role}>
                                            {this.state.roleList!.map((role, ix) => <MenuItem key={ix} value={role.entity.id!}>{role.entity.name}</MenuItem>)}
                                        </Field>
                                        { this.props.isGlobal &&
                                            <Field name={`roles.${index}.scope`} component={MuiField} variant='select' labelKey="set-role.scope" namespace="module.users" value={value.scope}>
                                                {this.state.scopeList!.map((scope, ix) => <MenuItem key={ix} value={scope.id!}>{scope.name}</MenuItem>)}
                                            </Field>
                                        }
                                    </CardContent>

                                    <CardActions disableSpacing>
                                        <IconButton onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            ))}
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', columnGap: '1rem', rowGap: '8px' }}>
                                <Button variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() => { arrayHelpers.push({ user: this.props.userId, role: 0, scope: 0 }); }}>
                                    {t(`${ns}.add-user-role`, { ns: ns })}
                                </Button>
                                <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
                                    {t("common.save", { ns: 'common' })}
                                </Button>
                            </Box>
                        </Box>
                    )} />
            </Form>
        }

        return this.state.roleList !== undefined && this.state.scopeList !== undefined && (<Formik
            initialValues={this.state.initRoles}
            onSubmit={this.onFormSubmit}
            component={form}
            >
        </Formik>);
    }
}

export default withTranslation()(RolesChange);