import { TextField } from "@mui/material";
import { FieldProps, getIn } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React, { HTMLInputTypeAttribute, ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

interface IProps extends FieldProps, WithTranslation {
    labelKey: string,
    namespace: string,

    fullWidth?: boolean,
    type?: HTMLInputTypeAttribute,
    variant?: 'input' | 'select' | 'textarea',
    disabled?: boolean,
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>,
    InputProps?: any,
    
    children?: ReactNode[]
}

interface IState {
}

class MuiField extends React.Component<IProps, IState> {

    static defaultProps: Partial<IProps> = {
        disabled: false,
        fullWidth: true,
    };

    constructor(props: IProps) {
        super(props);

        this.state = {};
    }

    render() {
        const t = this.props.t;
        const form = this.props.form;
        const field = this.props.field;
        
        const errors = getIn(form.errors, field.name);
        const touched = getIn(form.touched, field.name);

        return <TextField
            fullWidth={this.props.fullWidth}
            id={field.name}
            name={field.name}
            type={this.props.type}
            label={t(`${this.props.namespace}.${this.props.labelKey}`, {ns: this.props.namespace})}
            disabled={this.props.disabled}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            defaultChecked={field.checked}
            error={touched && Boolean(errors)}
            helperText={touched && Boolean(errors) ? String(errors) : ''}
            select={this.props.variant === 'select'}
            multiline={this.props.variant === 'textarea'}
            inputProps={this.props.inputProps}
            InputProps={this.props.InputProps}
            >
                {this.props.children}
        </TextField>;
    }
}

export default hoistStatics(withTranslation()(MuiField), MuiField)