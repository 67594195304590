import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Field, FieldInputProps, FormikProps, getIn } from 'formik';
import hoistStatics from 'hoist-non-react-statics';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AuthContext } from '../../system/Base';
import { triggerFormChange } from '../../system/Formik.model';
import { isAuthorized, permissions } from '../../system/User.model';
import TagSelect from '../tags/TagSelect';
import Kiosks from './Kiosks';
import { IKiosk, IPreKioskFilter } from './Kiosks.model';
import KiosksService from './Kiosks.service';
import ScopeSelect from '../tags/ScopeSelect';

interface IState { 
	kiosks: IKiosk[],
    specificKiosks: boolean,
}

interface IProps extends WithTranslation {
	field: FieldInputProps<IPreKioskFilter>,
    form: FormikProps<any>, // can be used versatilely
    labelKey?: string,
    namespace?: string,
	className: string
}

class KioskSelect extends React.Component<IProps, IState, WithTranslation>
{
    constructor(props : IProps) {
		super(props);
		this.state = {
			kiosks: [],
            specificKiosks: true,
		}

		this.setSelected = this.setSelected.bind(this);
		this.kiosksEqual = this.kiosksEqual.bind(this);
		this.getNamePrefix = this.getNamePrefix.bind(this);
	}

	async componentDidMount() {
		var all = (await KiosksService.getAll()).data;
		this.setState({
			kiosks: all,
		});
	}

	kiosksEqual(a: IKiosk, b: IKiosk) {
		return a.id === b.id;
	}

	getNamePrefix(): string {
		return this.props.field.name ? this.props.field.name + '.' : '';
	}

    changeMode(specificKiosks: boolean) {
		const prefix = this.getNamePrefix();
        this.setState({ specificKiosks }, () => {
            if (specificKiosks) {
                triggerFormChange(this.props.form, prefix + 'include', [], 'kioskSelect');
                triggerFormChange(this.props.form, prefix + 'exclude', [], 'kioskSelect');
            }
            else triggerFormChange(this.props.form, prefix + 'kiosks', [], 'kioskSelect');
        });
    }

	setSelected(kiosks?: IKiosk[]) {
		triggerFormChange(this.props.form, this.getNamePrefix() + 'kiosks', kiosks, 'kioskSelect');
	}

    render() {
        const t = this.props.t;
        const ns = Kiosks.getLocale();
		const namePrefix = this.getNamePrefix();

        return <AuthContext.Consumer>{auth => <>
            <RadioGroup value={this.state.specificKiosks} sx={{display: 'flex', flexDirection: 'row', mb: '8px'}}>
                <FormControlLabel value={true} control={<Radio />} onChange={(_, checked) => this.changeMode(checked)}
                    label={t(`${ns}.specific-kiosks`, {ns})} />
                <FormControlLabel value={false} control={<Radio />} onChange={(_, checked) => this.changeMode(!checked)}
                    disabled={!isAuthorized(auth, permissions.scope.read)} label={t(`${ns}.by-scopes`, {ns})} />
            </RadioGroup>


            { this.state.kiosks && this.state.specificKiosks && <Autocomplete
				options={this.state.kiosks}
				isOptionEqualToValue={this.kiosksEqual}
				getOptionLabel={(kiosk: IKiosk) => kiosk.name}
				autoHighlight
				filterSelectedOptions
				multiple

				value={getIn(this.props.field.value, 'kiosks')}
				onChange={(_event, value, _reason, _details) => this.setSelected(value)}

				renderInput={(params) =>
				<TextField variant='outlined'
					{...params}
					fullWidth
					label={t(`${ns}.kiosks`, {ns})}
					placeholder={t(`${ns}.kiosk-input-help`, {ns})}
					className='entityInputField'
					inputProps={{ ...params.inputProps, autoComplete: 'off' }} />
				}
			/>}

			{!this.state.specificKiosks &&
				<Field name={namePrefix + 'scopes'} labelKey='scopes' namespace={ns} component={ScopeSelect} />
				/*<>
                <Field name={namePrefix + 'include'} labelKey='include-tags' namespace={ns} component={TagSelect} />
                <Field disabled={getIn(this.props.field.value, 'include')?.length === 0 ?? true}
                    name={namePrefix + 'exclude'} labelKey='exclude-tags' namespace={ns} component={TagSelect} />
            </>*/}
		</>}</AuthContext.Consumer>;
    }
}

export default hoistStatics(withTranslation()(KioskSelect), KioskSelect)