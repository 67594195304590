import { OptionsObject, SnackbarKey, useSnackbar, WithSnackbarProps } from "notistack"
import React from "react"
import { Fragment } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import i18n from "i18next";

// Must be imported at least once in the app to initialize the ref
let snackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  snackbarRef = useSnackbar()
  return null
}

const tran = (msg: string, type: string) => {
    let prefix = "snack." + type + ".";

    if (!!msg && msg.startsWith && msg.startsWith('MISSING_PERMISSION_')) {
        let tr = i18n.t(prefix + 'MISSING_PERMISSION_');
        let x = msg.substring(19);
        return tr + i18n.t('module.users.permission.specific.' + x.toLowerCase().replaceAll('_', '-'), { ns: 'module.users' });
    } else {
        let tr = i18n.t(prefix + msg);
        if (tr.startsWith(prefix))
            return msg;
        else
            return tr;
    }
}

const snackNotifications = {
  success(msg: string, options: OptionsObject = {}): void {
    this.toast(tran(msg, "success"), { ...options, variant: "success", 
            autoHideDuration: 10000,
            action: (key) => (
                <Fragment>				
                    <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            ) })
  },
  warning(msg: string, options: OptionsObject = {}): void {
    this.toast(tran(msg, "warning"), { ...options, variant: "warning",
            autoHideDuration: 10000,
            action: (key) => (
                <Fragment>				
                    <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            ) })
  },
  info(msg: string, options: OptionsObject = {}): void {
    this.toast(tran(msg, "info"), { ...options, variant: "info",
            autoHideDuration: 10000,
            action: (key) => (
                <Fragment>				
                    <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                        <CloseIcon />
                    </IconButton>
                </Fragment>
            ) })
  },
  error(msg: string, options: OptionsObject = {}): void {
    this.toast(tran(msg, "error"), { ...options, variant: "error",
            autoHideDuration: 10000,
            action: (key) => (
            <Fragment>				
                <IconButton className="notify-close-button" onClick={() => snackbarRef.closeSnackbar(key)}>
                    <CloseIcon />
                </IconButton>
            </Fragment>
        )});
  },
  toast(msg: string, options: OptionsObject = {}): SnackbarKey {
    return snackbarRef.enqueueSnackbar(msg, options)
  },
}
// Export no-named default so consumer can name as desired/required
export default snackNotifications