import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next'
import { Field, FieldProps, getIn } from 'formik';
import { InputAdornment, MenuItem } from '@mui/material';
import hoistStatics from 'hoist-non-react-statics';
import { createDefaultSlide, IBannerSlide, IPlaylist, IRssSlide, isBannerSlide, ISlide, isRssSlide, SlideType, toBanner, toMedia, toRss, toWeb } from './Playlists.model';
import PlaylistService from "./Playlist.service";
import Playlists from './Playlists';
import MuiField from '../../system/MuiField';
import { isVideo, mediaFilter } from '../files/Files.model';
import { triggerFormChange } from '../../system/Formik.model';
import { ColorPickerField, ContentPicker, FilePickerField, MediaPicker } from '../../system/Formik.AdaptedFields';
import { ListEditor } from '../../system/ListEditor';

interface IState {
}
interface IProps extends WithTranslation {
    entity: IPlaylist,
    className?: string,
    onDone: () => void | Promise<void>
    readOnly?: boolean
}

class SlidesChange extends React.Component<IProps, IState, WithTranslation> {
	
	playlistService = PlaylistService;
    slideTypes: SlideType[] = [ SlideType.Media, SlideType.Web, SlideType.Rss, SlideType.Banner ];

	public constructor(props: IProps) {
		super(props);
		this.state = {};

        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.changeSlideType = this.changeSlideType.bind(this);
        this.onSlideTypeChange = this.onSlideTypeChange.bind(this);
        this.onSlideDurationChange = this.onSlideDurationChange.bind(this);
        this.validate = this.validate.bind(this);

        this.isVideo = this.isVideo.bind(this);

        this.duration = this.duration.bind(this);
        this.typeSelect = this.typeSelect.bind(this);
        this.slideForm = this.slideForm.bind(this);
        this.slideName = this.slideName.bind(this);
	}

	async onSubmit(slides: IPlaylist['slides']) {
        // cut off redundant properties:
        const corrected = slides?.map((slide, index) => {
            const s = this.changeSlideType(slide, slide.type);
            s.position = index;
            return s;
        });
        
		await this.playlistService.setSlides(this.props.entity, corrected ?? []);
        await this.props.onDone();
    }

    async onClose() {
        await this.props.onDone();
    }

    onSlideDurationChange(props: FieldProps, e: React.ChangeEvent<any>) {
        // convert seconds (display) back to milliseconds (data model)
        triggerFormChange(props.form, props.field.name, e.target.value * 1000);
    }

    onSlideTypeChange(slideNamePath: string, props: FieldProps, e: React.ChangeEvent<any>) {
        const slide = getIn(props.form.values, slideNamePath);
        const correctType = this.changeSlideType(slide, e.target.value) as any;

        for (const property in correctType) {
            // set values for new properties from derived classes
            // (necessary to avoid 'changing uncontrolled component to controlled' error)
            if (property !== 'type')
                props.form.setFieldValue(`${slideNamePath}.${property}`, correctType[property]);
        }
        props.field.onChange(e);
    }

    changeSlideType(slide: ISlide, type: SlideType): ISlide {
        if (type === SlideType.Web) return toWeb(slide);
        if (type === SlideType.Rss) return toRss(slide);
        if (type === SlideType.Banner) return toBanner(slide);
        return toMedia(slide);
    }

    // This is a temporary solution, so return type is not specified.
    // It's basically a deep version of IErrors<IPlaylist>.
    validate(slides: IPlaylist['slides']): any {
        const t = this.props.t;

        let slideErrs: any = {};
        for (let i = 0; i < (slides?.length ?? 0); ++i) {

            const slide = slides![i];
            
            if (!slide.url) slideErrs[i] = {...slideErrs[i], url: t('validation.value-empty')};
            if (!slide.duration) slideErrs[i] = {...slideErrs[i], duration: t('validation.value-empty')};

            if (isRssSlide(slide)) {
                if (!slide.count) slideErrs[i] = {...slideErrs[i], count: t('validation.value-empty')};
                if (!slide.background) slideErrs[i] = {...slideErrs[i], background: t('validation.value-empty')};
            }
        }
        return Object.keys(slideErrs).length > 0 ? slideErrs : undefined;
    }

    private typeSelect(props: any) {
        const t = this.props.t;
        const ns = Playlists.getLocale();

        let slideNamePath = props.inputProps?.slideNamePath;

        return <Field {...props.field} disabled={this.props.readOnly} labelKey="slide-type" namespace={ns} variant='select' component={MuiField}
            field={{...props.field, onChange: (e: React.ChangeEvent<any>) => this.onSlideTypeChange(slideNamePath, props, e)}}>

            {this.slideTypes.map((type) =>
                <MenuItem key={type} value={type}>
                    {t(`${ns}.${type}`, {ns: ns})}
                </MenuItem>
            )}
        </Field>;
    }

    private duration( props: any) {
        const t = this.props.t;
        const ns = Playlists.getLocale();

        let slide: ISlide | IBannerSlide | IRssSlide = props.inputProps?.slide;

        const field = {
            ...props.field,
            value: slide.duration / 1000.0, // convert milliseconds (data model) to seconds (display)
            onChange: (e: React.ChangeEvent<any>) => this.onSlideDurationChange(props, e)
        };

        return <MuiField key="durationInput" disabled={this.props.readOnly} type="number" labelKey="duration" namespace={ns} {...props} field={field}
            InputProps={{ endAdornment: <InputAdornment position="end">
                {slide.type === SlideType.Rss ? t(`${ns}.seconds-per-article`, {ns: ns}) : t('time.seconds', {ns: 'common'})}
            </InputAdornment>}} />;
    }

    private isVideo(slide: ISlide): boolean {
        if (slide.type !== SlideType.Media)
            return false;
        
        return isVideo(slide.url);
    }

    private slideName(slide: ISlide | IRssSlide | IBannerSlide) {
        const t = this.props.t;
        const ns = Playlists.getLocale();
        
        if (slide.type === SlideType.Media) {
            const filepathBits = slide.url.split('/');
            return filepathBits.length > 1 ? filepathBits[filepathBits.length - 1] : `(${t('file')})`;
        }
        
        const title = slide.type === SlideType.Rss ?
             t(`${ns}.${slide.type}`, {ns: ns}) + ' '
             : '';
        
        if (slide.type === SlideType.Web || slide.type === SlideType.Rss) {
            try {
                return title + new URL(slide.url).hostname;
            }
            catch (e) {
                return title + `(${t('url')})`;
            }
        }

        if (isBannerSlide(slide))
            return t(`${ns}.${slide.type}`, {ns: ns}) + ' ' + (slide.title ? slide.title : `(${t('title')})`);

        return '?'; // should not happen
    }

    private slideForm(slide: ISlide | IRssSlide | IBannerSlide, slideNamePath: string) {
        const t = this.props.t;
        const ns = Playlists.getLocale();

        return <>
            <Field disabled={this.props.readOnly} name={`${slideNamePath}.type`} inputProps={{ slideNamePath: slideNamePath }} component={ this.typeSelect } /> 

            {(slide.type === SlideType.Web || slide.type === SlideType.Rss) && <Field disabled={this.props.readOnly}
                name={`${slideNamePath}.url`} labelKey={slide.type === SlideType.Rss ? 'rss-address' : 'web-url'} namespace={ns} component={MuiField} />}
            
            {slide.type === SlideType.Media && <Field disabled={this.props.readOnly} name={`${slideNamePath}.url`} labelKey='media-file' namespace={ns}
                filter={mediaFilter} filePicker={MediaPicker} component={FilePickerField} />}

            {slide.type === SlideType.Banner && <Field disabled={this.props.readOnly} name={`${slideNamePath}.url`} labelKey='content-target' namespace={ns}
                filePicker={ContentPicker} component={FilePickerField} />}

            {!this.isVideo(slide) && <Field disabled={this.props.readOnly} name={`${slideNamePath}.duration`} inputProps={{ min: 0, slide: slide }} component={this.duration} />}
            
            {slide.type === SlideType.Rss && <>
                <Field disabled={this.props.readOnly} name={`${slideNamePath}.count`} type="number" labelKey="article-count" namespace={ns} component={MuiField} inputProps={{ min: 0 }}
                    InputProps={{ endAdornment: <InputAdornment position="end">{t(`${ns}.articles`, {ns: ns})}</InputAdornment>}} />
                
                <Field disabled={this.props.readOnly} name={`${slideNamePath}.background`} labelKey='background' namespace={ns}
                    filter={mediaFilter} filePicker={MediaPicker} component={FilePickerField} />
            </>}

            {slide.type === SlideType.Banner && <>
                <Field disabled={this.props.readOnly} name={`${slideNamePath}.title`} labelKey="title" namespace={ns} component={MuiField} />
                <Field disabled={this.props.readOnly} name={`${slideNamePath}.description`} labelKey="description" namespace={ns} component={MuiField} />
                <Field disabled={this.props.readOnly} name={`${slideNamePath}.color`} labelKey="color" namespace={ns} component={ColorPickerField} />

                <Field disabled={this.props.readOnly} name={`${slideNamePath}.background`} labelKey='background' namespace={ns}
                    filter={mediaFilter} filePicker={MediaPicker} component={FilePickerField} />
            </>}

        </>;
    }
	
	render() {
        return <>
            <ListEditor<ISlide | IRssSlide | IBannerSlide> role='update'
                readOnly={this.props.readOnly}
                items={this.props.entity.slides ?? []}
                addLabelKey='module.playlists.add-slide'
                addLabelNs={Playlists.getLocale()}
                expandListLabelKey='module.playlists.expand-slides'
                collapseListLabelKey='module.playlists.collapse-slides'
                listLabelNs={Playlists.getLocale()}
                itemForm={this.slideForm}
                itemName={this.slideName}
                createItem={createDefaultSlide}
                validate={this.validate}
                onSubmit={this.onSubmit}
                onClose={this.onClose} />
            </>;
	}
}

export default hoistStatics(withTranslation()(SlidesChange), SlidesChange)