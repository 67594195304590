import { Box, Typography } from "@mui/material";

export interface ITabPanelProps {
	children?: React.ReactNode;
	index: number;
	state: number;
}

export function fireResize() {
	window.dispatchEvent(new CustomEvent('resize'));
	setTimeout(() => { window.dispatchEvent(new CustomEvent('resize')); }, 1000);
}

export default function TabPanel(props: ITabPanelProps) {
	const { children, index, state, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={state !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{state === index && (
				<Box sx={{ p: 3 }}>
					{/*<Typography>{children}</Typography>*/}
					{children}
				</Box>
			)}
		</div>
	);
}