import { ImgHTMLAttributes, useState } from 'react'
import placeholder from '../../avatar-placeholder.gif'
import React from 'react'
import UserAvatar from './UserAvatar'
import { IUser } from '../users/Users.model'

interface IState {
}

interface IProps {
	user: IUser
}

export default class UserStrip extends React.Component<IProps, IState> {

	render() {
		return <span className="user-strip"><UserAvatar userId={this.props.user.id!} />{this.props.user.name}</span>
	}
}