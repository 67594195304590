import React from "react"
import { IAttribute } from "./CRUDAttribute"
import { CRUDForm, IEntity, IFormConfig } from "./CRUDForm"
import { Modal } from "./Modal"
import { CRUDButton } from "./CRUDButton"
import { IIdentifiable } from "./Identifiable.model"
import { CRUDOperation } from "./CRUDOperation"
import { IPagedResponse, PageQuery, Pagination } from "./Pagination.model"
import { Box, ButtonGroup, Pagination as MUIPagination, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
/**
 * TODO: fix access to translations.
 * Using i18next directly is probably ugly. However:
 * - withTranslation() breaks type parameter checking
 * - useTranslation() can be used only in functional components
 * - <Trans> and <Translation> return Element, not string
 */
 import i18next from "i18next";
import { ICrudPermissions, UserContext, isAuthorized, permissions } from "./User.model"
import { AuthContext } from "./Base"
import { IShouldCloseContext, ShouldCloseContext } from "./SaveCloseButton"
import snackNotifications from "./SnackBarUtils"
import { ITableEntity } from "./ICrudService"
import { IPermission } from "../modules/users/Users.model"

export enum ENTITY_VISIBILITY_TYPE {
    OWNED = "OWNED",
    SUPERVISED = "SUPERVISED",
    SHARED = "SHARED"
}

export class Sorting<TEntity extends IEntity> {
    field: keyof TEntity;
    direction: 'ascending' | 'descending';
  
    constructor(attribute: keyof TEntity, ascending: boolean) {
      this.field = attribute;
      this.direction = ascending ? 'ascending' : 'descending';
    }
  
    toString(): string {
      return `sort=${this.direction === 'ascending' ? '' : '-'}${String(this.field)}`;
    }
}

export function isCrudButtonDisabled(activePermissions: IPermission[] | undefined, auth: UserContext, permission: string) {
    if (activePermissions !== undefined) {
        return !isAuthorized(activePermissions!, permission);
    } else {
        return !isAuthorized(auth, permission);
    }

}

interface ITableConfig<TEntity extends IEntity> {
    sort?: Sorting<TEntity>
    page?: PageQuery
    mode?: ENTITY_VISIBILITY_TYPE
}

interface IState<TEntity extends IEntity> {
    entities: ITableEntity<TEntity>[],
    allEntities: ITableEntity<TEntity>[],
    attributes: IAttribute<TEntity>[],
    sort: Sorting<TEntity>,
    pagination: Pagination,
    createShown: boolean,
    showShown: boolean,
    updateShown: boolean,
    deleteShown: boolean,
    shareShown: boolean,
    changeOwnerShown: boolean,
    currentEntity: ITableEntity<TEntity> | null,
    selectedPageEntry?: number,
    activePermissions?: IPermission[]
}

interface IProps<TEntity extends IEntity> {
	fetchItems: (_: ITableConfig<TEntity>) => Promise<IPagedResponse<TEntity | ITableEntity<TEntity>>>,
    createDefaultEntity: () => TEntity,
    createForm: IFormConfig<TEntity> | null,
    updateForm: IFormConfig<TEntity> | null,
    deleteForm: IFormConfig<TEntity> | null,
    shareForm: IFormConfig<TEntity> | null,
    showForm: IFormConfig<TEntity> | null,
    changeOwnerForm: IFormConfig<TEntity> | null,
    permissions: ICrudPermissions,
    children: React.ReactElement<IAttribute<TEntity>>[],
    firstRecordPermanent?: boolean,
    refreshSeed?: any,
    ownMode?: ENTITY_VISIBILITY_TYPE,
    hideActions: boolean,
    customLock?: (entity: TEntity, all: ITableEntity<TEntity>[]) => boolean,
    customEditMethod?: (e: TEntity, readOnly: boolean) => void
}

export type { ITableConfig }

export class CRUDTable<TEntity extends IIdentifiable & IEntity> extends React.Component<IProps<TEntity>, IState<TEntity>> {
	
    isRefreshing: boolean = false;
    selectedEntryRef: React.RefObject<any>;

    constructor(props: IProps<TEntity>) {		
		super(props);

        const attrs = this.props.children.map(child => child.props);
		this.state = {
            entities: [],
            allEntities: [],
            attributes: attrs,
            sort: {field: 'id', direction: 'ascending'},
            pagination: new Pagination(0, null, 20),
            createShown: false,
            showShown: false,
            updateShown: false,
            deleteShown: false,
            shareShown: false,
            changeOwnerShown: false,
            currentEntity: null,
		};

        this.sort = this.sort.bind(this);
        this.changePage = this.changePage.bind(this);
        this.showShow = this.showShow.bind(this);
        this.showCreate = this.showCreate.bind(this);
        this.showUpdate = this.showUpdate.bind(this);
        this.showDelete = this.showDelete.bind(this);
        this.showShare = this.showShare.bind(this);
        this.showChangeOwner = this.showChangeOwner.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onShowDone = this.onShowDone.bind(this);
        this.onShare = this.onShare.bind(this);
        this.onChangeOwner = this.onChangeOwner.bind(this);
        this.selectEntry = this.selectEntry.bind(this);
        this.refresh = this.refresh.bind(this);

        this.selectedEntryRef = React.createRef();
	}

    async componentDidMount() {
        await this.refresh();
    }

    async componentDidUpdate(prevProps: IProps<TEntity>) {
        if (prevProps.refreshSeed !== this.props.refreshSeed) {
            const attrs = this.props.children.map(child => child.props);
            this.setState({ attributes: attrs });
            await this.refresh();
        }
    }

    async selectEntry(entry: number) {
        if (this.isRefreshing) {
            setTimeout(() => this.selectEntry(entry), 100);
            return;
        }
            
        const { page, position } = this.state.pagination.getPagePosition(entry);
            
        this.setState(() => ({ selectedPageEntry: position }), async () => {
            await this.refresh({ field: 'id', direction: 'ascending' }, page);
        });
    }

    async refresh(sort?: Sorting<TEntity>, page?: PageQuery) {
        this.isRefreshing = true;

        const p = page ?? this.state.pagination.getQuery();
        const newSort = sort ?? this.state.sort;
        const pagedResponse = await this.props.fetchItems({ sort: newSort, page: p, mode: this.props.ownMode });
        let allResponse!: IPagedResponse<TEntity | ITableEntity<TEntity>>;
        if (this.props.customLock)
            allResponse = await this.props.fetchItems({ mode: this.props.ownMode });

        let data = pagedResponse.data;
        let entityList: ITableEntity<TEntity>[];
        let entityListAll: ITableEntity<TEntity>[];

        let isSmart = (entity: ITableEntity<TEntity> | TEntity): entity is ITableEntity<TEntity> => {
            return entity.entity !== undefined;
        }

        if (data.length && isSmart(data[0])) {
            // ITableEntity<TEntity>
            entityList = data as ITableEntity<TEntity>[];
            if (this.props.customLock)
                entityListAll = data as ITableEntity<TEntity>[];
        } else {
            // TEntity
            entityList = data.map(x => { return { entity: x as TEntity, activePermissions: undefined } as ITableEntity<TEntity> });
            if (this.props.customLock)
                entityListAll = allResponse.data.map(x => { return { entity: x as TEntity, activePermissions: undefined } as ITableEntity<TEntity> });
        }

        this.setState(() => ({
            entities: entityList,
            allEntities: entityListAll,
            sort: newSort,
            pagination: new Pagination(pagedResponse.total, pagedResponse.current, p.limit)
        }), () => {
            this.isRefreshing = false;
        });
    }

    async changePage(_event: React.ChangeEvent<unknown>, newPage: number) {
        this.setState(() => ({selectedPageEntry: undefined}));
        await this.refresh(undefined, new PageQuery(newPage, this.state.pagination.perPage));
    }

    async sort(attribute: IAttribute<TEntity>) {
        const sameAsc = this.state.sort.field === attribute.name && this.state.sort.direction === 'ascending';
        const newSort = new Sorting<TEntity>(attribute.name || "", !sameAsc);

        this.setState(() => ({selectedPageEntry: undefined}));
        await this.refresh(newSort);
    }

    showCreate(entity: ITableEntity<TEntity> | null) {
        this.setState(() => ({
            currentEntity: entity,
            createShown: Boolean(entity)
        }));
    }

    showUpdate(entity: ITableEntity<TEntity> | null) {
        this.setState(() => ({
            currentEntity: entity,
            updateShown: Boolean(entity)
        }));
    }
    showShow(entity: ITableEntity<TEntity> | null) {
        this.setState(() => ({
            currentEntity: entity,
            showShown: Boolean(entity)
        }));
    }

    showDelete(entity: ITableEntity<TEntity> | null) {
        this.setState(() => ({
            currentEntity: entity,
            deleteShown: Boolean(entity)
        }));
    }

    showShare(entity: ITableEntity<TEntity> | null) {
        this.setState(() => ({
            currentEntity: entity,
            shareShown: Boolean(entity)
        }));
    }

    showChangeOwner(entity: ITableEntity<TEntity> | null) {
        this.setState(() => ({
            currentEntity: entity,
            changeOwnerShown: Boolean(entity)
        }));
    }

    async onCreate(entity: TEntity) {
        await this.props.createForm?.onSubmit(entity);
        this.showCreate(null);
        await this.refresh();
    }

    async onUpdate(entity: TEntity, closeCtx: IShouldCloseContext) {
        await this.props.updateForm?.onSubmit(entity);
        if (closeCtx.shouldClose)
            this.showUpdate(null);
        else
            snackNotifications.success(i18next.t('common.request-completed'));
        await this.refresh();
    }

    async onDelete(entity: TEntity) {
        await this.props.deleteForm?.onSubmit(entity);
        this.showDelete(null);
        await this.refresh();
    }

    async onShare(entity: TEntity) {
        await this.props.shareForm?.onSubmit(entity);
        this.showShare(null);
        await this.refresh();
    }

    async onShowDone(entity: TEntity) {
        this.showShow(null);
    }

    async onChangeOwner(entity: TEntity) {
        await this.props.changeOwnerForm?.onSubmit(entity);
        this.showChangeOwner(null);
        await this.refresh();
    }

	render() {
        const borderColorStyle = { borderColor: 'divider' };

        let header = <TableHead sx={{backgroundColor: 'divider', opacity: 0.6}}><TableRow sx={borderColorStyle}>
            {this.state.attributes.filter(a => !a.hideInTable).map((a, ind) =>
                <TableCell key={a.name?.toString() || "cell-"+ind.toString()} sx={borderColorStyle}
                    className={a.sortable ? 'sortable' : ''} onClick={async () => a.sortable && await this.sort(a)}>

                    {i18next.t(`${a.namespace}.${a.labelKey}`, {ns: a.namespace}) + ''}
                    {this.state.sort.field === a.name &&
                        <span>{this.state.sort.direction === 'ascending' ? '▲' : '▼'}</span>}
                </TableCell>)}
            {(this.props.updateForm || this.props.deleteForm || this.props.shareForm || this.props.changeOwnerForm) && !this.props.hideActions && <TableCell key="actions" sx={borderColorStyle}>
                {i18next.t('crud.actions') + ''}
            </TableCell>}
        </TableRow></TableHead>;
        
        let body = <TableBody>
            {this.state.entities.map((e, i) =>
                <TableRow key={e.entity.id} selected={i === this.state.selectedPageEntry} sx={borderColorStyle}
                    ref={i === this.state.selectedPageEntry ? this.selectedEntryRef : undefined}>
                    {
                        this.state.attributes.filter(attr => !attr.hideInTable).map((attr, ind) => {
                            return <TableCell key={attr.name?.toString() || "cell-" + ind.toString()} sx={borderColorStyle}>
                                {attr.tableValueResolver ? attr.tableValueResolver(e.entity, attr.name || "", e.activePermissions, e, {
                                    updateAction: () => { this.showUpdate(e); },
                                    deleteAction: () => { this.showDelete(e); },
                                    shareAction: () => { this.showShare(e); },
                                    changeOwnerAction: () => { this.showChangeOwner(e); }
                                }) : String(e.entity[attr.name || ""])}
                            </TableCell>
                        })
                    }
                    {(this.props.updateForm || this.props.deleteForm || this.props.shareForm || this.props.changeOwnerForm) && !this.props.hideActions &&
                        <AuthContext.Consumer>{auth => {
                            let canUpdate = !isCrudButtonDisabled(e.activePermissions, auth, this.props.permissions.update)
                                || (this.props.customLock?.(e.entity, this.state.allEntities))
                            let canShow = !isCrudButtonDisabled(e.activePermissions, auth, this.props.permissions.read)
                                || (this.props.customLock?.(e.entity, this.state.allEntities))
                            return <TableCell key="actions" sx={borderColorStyle}>
                            <ButtonGroup variant="outlined">
                                { (this.props.updateForm || (!canShow && !canUpdate)) &&
                                    <CRUDButton variant="outlined" role="update" disabled={!canUpdate}
                                        onClick={this.props.customEditMethod ? () => this.props.customEditMethod?.(e.entity, false) : () => this.showUpdate(e)} />}
                                {(this.props.showForm && (!canUpdate && canShow)) &&
                                    <CRUDButton variant="outlined" role="show" disabled={ !canShow }
                                        onClick={this.props.customEditMethod ? () => this.props.customEditMethod?.(e.entity, true) : () => this.showShow(e)} />}
                                {this.props.deleteForm &&
                                    <CRUDButton variant="outlined" role="delete" disabled={
                                        isCrudButtonDisabled(e.activePermissions, auth, this.props.permissions.delete)
                                        || (this.props.firstRecordPermanent && e.entity.id === 1)
                                        || (this.props.customLock?.(e.entity, this.state.allEntities))
                                    }
                                        onClick={() => this.showDelete(e)} />}
                                {this.props.shareForm &&
                                    <CRUDButton variant="outlined" role="share" disabled={
                                        this.props.customLock?.(e.entity, this.state.allEntities)
                                        || !isAuthorized(auth, permissions.user.read)
                                    }
                                        onClick={() => this.showShare(e)} />}
                                {this.props.changeOwnerForm &&
                                    <CRUDButton variant="outlined" role="changeowner" disabled={
                                        this.props.customLock?.(e.entity, this.state.allEntities)
                                        || !isAuthorized(auth, permissions.user.read)
                                    }
                                        onClick={() => this.showChangeOwner(e)} />}
                            </ButtonGroup>
                        </TableCell>
                        } }
                        </AuthContext.Consumer>}
                </TableRow>
            )}
        </TableBody>;

        if (this.state.selectedPageEntry !== undefined && this.selectedEntryRef.current)
            this.selectedEntryRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

        return <>
            <ShouldCloseContext.Consumer>{shouldCloseCtx => <AuthContext.Consumer>{auth => (<>
                {this.props.createForm && <CRUDButton variant="contained" role="create"
                    disabled={!isAuthorized(auth, this.props.permissions.create)} onClick={() => this.showCreate({ entity: this.props.createDefaultEntity() })} />}

                {this.props.createForm && this.state.createShown && this.state.currentEntity &&
                    <Modal title={this.props.createForm.title} isOpen={this.state.createShown} onClose={() => this.showCreate(null)}>
                        <CRUDForm {...this.props.createForm}
                            onSubmit={this.onCreate}
                            entity={this.state.currentEntity.entity}
                            resourceId={this.state.currentEntity.access?.record.recordId || 0}
                            role={CRUDOperation.Create}
                            attributes={this.state.attributes} />
                    </Modal>}

                <MUIPagination className="crud-pagination"
                    count={this.state.pagination.totalPages}
                    page={this.state.pagination.currentPage}
                    onChange={this.changePage} />

                {isAuthorized(auth, this.props.permissions.read) && <Box sx={{ overflow: 'auto', clear: 'both', border: 1, borderColor: 'divider', boxShadow: 4 }}>
                    <Table className="crud-table" stickyHeader sx={{ border: 'none' }}>
                        {header}
                        {body}
                    </Table>
                </Box>}

                {this.props.updateForm && this.state.updateShown && this.state.currentEntity &&
                    <Modal title={this.props.updateForm.title} isOpen={this.state.updateShown} onClose={() => this.showUpdate(null)}>
                        <CRUDForm {...this.props.updateForm}
                            onSubmit={(x) => { return this.onUpdate(x, shouldCloseCtx); }}
                            entity={this.state.currentEntity.entity}
                            resourceId={this.state.currentEntity.access?.record.recordId || 0}
                            role={CRUDOperation.Update}
                            attributes={this.state.attributes} />
                    </Modal>}

                {this.props.showForm && this.state.showShown && this.state.currentEntity &&
                    <Modal title={this.props.showForm.title} isOpen={this.state.showShown} onClose={() => this.showShow(null)}>
                        <CRUDForm {...this.props.showForm}
                            onSubmit={this.onShowDone}
                            entity={this.state.currentEntity.entity}
                            resourceId={this.state.currentEntity.access?.record.recordId || 0}
                            role={CRUDOperation.Show}
                            attributes={this.state.attributes} />
                    </Modal>}

                {this.props.deleteForm && this.state.deleteShown && this.state.currentEntity &&
                    <Modal title={this.props.deleteForm.title} isOpen={this.state.deleteShown} onClose={() => this.showDelete(null)}>
                        <CRUDForm {...this.props.deleteForm}
                            onSubmit={this.onDelete}
                            entity={this.state.currentEntity.entity}
                            resourceId={this.state.currentEntity.access?.record.recordId || 0}
                            role={CRUDOperation.Delete}
                            attributes={this.state.attributes} />
                    </Modal>}
                {this.props.shareForm && this.state.shareShown && this.state.currentEntity &&
                    <Modal title={this.props.shareForm.title} isOpen={this.state.shareShown} onClose={() => this.showShare(null)}>
                        <CRUDForm {...this.props.shareForm}
                            onSubmit={this.onShare}
                            entity={this.state.currentEntity.entity}
                            resourceId={this.state.currentEntity.access?.record.recordId /* Naming is weird. It's resourceId */ || 0}
                            role={CRUDOperation.Share}
                            attributes={this.state.attributes} />
                    </Modal>}
                {this.props.changeOwnerForm && this.state.changeOwnerShown && this.state.currentEntity &&
                    <Modal title={this.props.changeOwnerForm.title} isOpen={this.state.changeOwnerShown} onClose={() => this.showChangeOwner(null)}>
                        <CRUDForm {...this.props.changeOwnerForm}
                            onSubmit={this.onChangeOwner}
                            entity={this.state.currentEntity.entity}
                            resourceId={this.state.currentEntity.access?.record.recordId /* Naming is weird. It's resourceId */ || 0}
                            ownerId={this.state.currentEntity.access?.record.owner.id || 0}
                            role={CRUDOperation.ChangeOwner}
                            attributes={this.state.attributes} />
                    </Modal>}

                {isAuthorized(auth, this.props.permissions.read) && <MUIPagination className="crud-pagination"
                    count={this.state.pagination.totalPages}
                    page={this.state.pagination.currentPage}
                    disabled={!isAuthorized(auth, this.props.permissions.read)}
                    onChange={this.changePage} />}

            </>)}
            </AuthContext.Consumer>}</ShouldCloseContext.Consumer>
        </>;
    }
}