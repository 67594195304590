import React, { Fragment } from 'react';
import { ITag } from '../tags/Tags.model';
import { withTranslation, WithTranslation } from 'react-i18next'
import hoistStatics from 'hoist-non-react-statics';
import { CustomThemeContext }  from '../../system/CustomThemeProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { IKiosk, StatusParser, TagNode, TagTree } from './Kiosks.model';
import { InputAdornment, TextField, Box, Tabs, Tab, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { load, save } from '../../App';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import UserService, { IUserService } from '../users/Users.service';
import { FieldArray, FieldArrayRenderProps, Formik, FormikHelpers, FormikValues } from 'formik';
import Kiosks from './Kiosks';
import { ListItem } from '../../system/ListEditor';

interface IState {
	tree?: TagTree,
	searchTerm: string,
	page: number,
	favouriteTags: number[],
	tagOrders: Map<number, number>,
	collapsed: Map<number, boolean>,
	draggingId: number | null,
}

interface IChildContext {
	parentId: string,
	index: number,
	total: number,
	arrayHelpers: FieldArrayRenderProps
}

interface IProps extends WithTranslation {
	kiosks?: IKiosk[]
	tags: ITag[]
	onTagClick? : (id: number) => void
	onKioskClick? : (id: number) => void
	mode? : Mode
	paged?: string
	dashboardMode?: boolean
}

export enum Mode {
	Tags = 1,
	Kiosks = 2
}

interface Branches {
	components: JSX.Element[] | null,
	totalKioskCount: number
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

class KioskTree extends React.Component<IProps, IState, WithTranslation> {

	selectedTagRef: React.RefObject<HTMLDivElement>;
	
	public static defaultProps = {
		mode : Mode.Kiosks
	}
	
	constructor(props : IProps) {
		super(props);

		this.state = {
			searchTerm: '',
			page: 0,
			collapsed: new Map<number, boolean>(),
			tagOrders: new Map<number, number>(),
			favouriteTags: [],
			draggingId: null
		};

		this.drawTreeOrderable = this.drawTreeOrderable.bind(this);
		this.drawKiosks = this.drawKiosks.bind(this);
		this.search = this.search.bind(this);
		this.matchesSearch = this.matchesSearch.bind(this);
		this.fixPage = this.fixPage.bind(this);
		this.toggleFavouriteTag = this.toggleFavouriteTag.bind(this);
		this.setCollapsed = this.setCollapsed.bind(this);
		this.dragStart = this.dragStart.bind(this);
		this.ordered = this.ordered.bind(this);

		this.selectedTagRef = React.createRef<HTMLDivElement>();
	}
	
	async componentDidMount()
	{
		if (this.props.paged)
		{
			let last = load("lastTreeIndex_" + this.props.paged);
			if (last !== null)
				this.setState({ page : Number(last) });
		}
		this.setTreeFromProps();

		let userData = await UserService.getUserData();
		if (!userData.favouriteTags)
			userData.favouriteTags = [];
		if (!userData.tagOrders)
			userData.tagOrders = new Map<number, number>();
		else
			userData.tagOrders = new Map<number, number>(JSON.parse(userData.tagOrders));
		this.setState({
			favouriteTags: userData.favouriteTags,
			tagOrders: userData.tagOrders
		});
	}

	setTreeFromProps() {
		const tree = new TagTree(this.props.tags, this.props.kiosks ?? [], this.state.favouriteTags, this.props.dashboardMode);
		this.setState({
			tree: tree
		});
		this.fixPage();
	}

	componentDidUpdate(prevProps: IProps) {
		if (this.props.kiosks === prevProps.kiosks
			&& this.props.tags === prevProps.tags)
			return;
		this.setTreeFromProps();
	}

	search(term: string) {
		this.setState({
			searchTerm: term
		})
	}

	matchesSearch(kiosk: IKiosk) {
		const term = this.state.searchTerm.trim().toLocaleLowerCase('cs');
		if (!term)
			return true;
		
		return kiosk.name.toLocaleLowerCase('cs').includes(term)
			|| kiosk.identifier.toLocaleLowerCase('cs').includes(term)
			|| kiosk.description.toLocaleLowerCase('cs').includes(term);
	}

	toggleFavouriteTag(tagId: number) {
		if (this.state.favouriteTags.includes(tagId)) {
			this.setState({ favouriteTags: this.state.favouriteTags.filter((x: any) => x as number !== tagId) },
				async () => { this.setTreeFromProps(); await UserService.setUserData("favouriteTags", this.state.favouriteTags); });
		} else {
			this.setState({ favouriteTags: [...this.state.favouriteTags, tagId] },
				async () => { this.setTreeFromProps(); await UserService.setUserData("favouriteTags", this.state.favouriteTags); });
		}
	}

	setCollapsed(id: number, collapsed: boolean) {
		const copy = new Map<number, boolean>(this.state.collapsed.entries());
		copy.set(id, collapsed);
		this.setState({ collapsed: copy });
	}

	dragStart(id: number) {
		this.setCollapsed(id, true);
		this.setState({ draggingId: id });
	}

	getPropertyPath(boardNamePath: string): string {
		return boardNamePath === '' ? '' : `${boardNamePath}.`;
	}

	findIdInTree(roots: TagNode[], id: number): TagNode | null {
		for (let i = 0; i < roots.length; i++) {
			if (roots[i].tag.id === id)
				return roots[i];
			let nested = this.findIdInTree(roots[i].children, id);
			if (nested !== null)
				return nested;
		}

		return null;
	}

	moveNode(arrayHelpers: FieldArrayRenderProps, oldIndex: number, newIndex: number, namePath: string) {
		arrayHelpers.move(oldIndex, newIndex);
		const copy = new Map<number, number>(this.state.tagOrders.entries());

		// All orderes bigger then our old order but smaller or equal of our new order needs to be shifted by -1.
		// All orderes smaller then our old order but bigger or equal of our new order needs to be shifted by +1.
		const node = this.findIdInTree(this.state.tree?.roots!, this.state.draggingId!);
		let parent = null;
		if (node) {
			parent = this.findIdInTree(this.state.tree?.roots!, node.tag.parent!);
			parent?.children.map(sibling => {
				if (sibling.tag.id !== this.state.draggingId && this.state.tagOrders.has(sibling.tag.id!)) {
					const ord = this.state.tagOrders.get(sibling.tag.id!)!;
					if (ord > oldIndex && ord <= newIndex) {
						copy.set(sibling.tag.id!, ord - 1);
					}
					if (ord < oldIndex && ord >= newIndex) {
						copy.set(sibling.tag.id!, ord + 1);
					}
				}
			});
		}

		// Finally change our own order:
		copy.set(this.state.draggingId!, newIndex);

		// There is another problem. In very rare ocasions we can have two tags with same order. This can only happen after
		// a parent of a tag has been changed to another parent, with a child occupying the same ordering slot already.
		// For this edge-case we need to search all the siblings one more time and if there is an ordering-slot used more
		// than once, fix it:
		let usedSlots: number[] = [];
		const myOrderedSiblings = parent?.children.filter(x => copy.has(x.tag.id!)) || [];
		myOrderedSiblings.forEach(sibling => {
				const ord = copy.get(sibling.tag.id!)!;
				//console.log("Handling sibling ord: ", ord, " -id: ", sibling.tag.id!);
				if (usedSlots.includes(ord)) {
					// We have a problem. Push every slot bigger than this:
					let toAdd : number[] = [];
					myOrderedSiblings.forEach(s => {
						const o = copy.get(s.tag.id!)!;
						if (o > ord) {
							copy.set(s.tag.id!, o + 1);
							usedSlots.filter(x => x === o).map(x => toAdd.push(x + 1));
							usedSlots = usedSlots.filter(x => x !== o);
						}
					})
					usedSlots = usedSlots.concat(toAdd);
					// Now my slot:
					usedSlots.push(ord + 1);
					copy.set(sibling.tag.id!, ord + 1);
					// We could still have duplicites but that will be resolved in the next iterations...
				} else {
					usedSlots.push(ord);
				}
		});

		// Another problem is when we have ordering slots go beyond the maximum number of siblings. This can happen when
		// a tag is removed or moved to another parent. We need to start squeezing the orders.
		let max = 0;
		usedSlots.forEach(slot => { if (slot > max) max = slot; });
		const allLen = (parent?.children.length || 0);
		const debt = max - (allLen - 1);
		let toAdd: number[] = [];
		const fixSibling = (sibling: TagNode, hole: number) => {
			const ord = copy.get(sibling.tag.id!)!;
			if (ord > hole) {
				copy.set(sibling.tag.id!, ord - 1);
				usedSlots = usedSlots.filter(x => x !== ord);
				toAdd.push(ord - 1);
			}
		}
		for (let i = 0; i < debt; i++) {
			// Squish cycle. Find first "hole" in the order and make everything bigger then the hole index smaller to fill it:
			let hole = -1;
			for (let j = 0; j < allLen; j++) {
				if (!usedSlots.includes(j)) {
					hole = j; break;
				}
			}
			if (hole > -1) { // Mathematically impssible not to be, but I don't trust Math.
				myOrderedSiblings.forEach(x => fixSibling(x, hole));
			}
		}
		usedSlots = usedSlots.concat(toAdd);

		// Finally set the new state:
		this.setState({ tagOrders: copy });

		// Fire and forget - save into database:
		UserService.setUserData("tagOrders", JSON.stringify(Array.from(copy.entries())));
	}

	drawTreeOrderable(node: TagNode, namePath: string, childContext?: IChildContext): JSX.Element {
		const childless = ('children' in node) && node.children.length === 0 && ('kiosks' in node) && node.kiosks.length === 0;
		const cc = childContext;
		const ns = Kiosks.getLocale();
		const propertyPath = this.getPropertyPath(namePath);

		return <Box className={`branch${childless ? ' childless' : ''}`} key={node.tag.id}>
			{!childless &&
				<input type="checkbox"
					checked={this.state.collapsed.has(node.tag.id!) && this.state.collapsed.get(node.tag.id!)}
					onChange={(e) => this.setCollapsed(node.tag.id!, e.target.checked)} />}

				<ListItem
					ref={this.selectedTagRef}
					item={node}
					index={cc?.index ?? 0}
					id={String(node.tag.id)}
					acceptType={cc?.parentId}
					name={(node) => node.tag.name ? node.tag.name : this.props.t(`${ns}.untitled`, { ns: ns }) }
					selected={false} touched={false} error={''}
					selectItem={() => this.props.onTagClick?.(node.tag.id!)}
					moveItem={(index, newIndex) => cc && this.moveNode(cc?.arrayHelpers, index, newIndex, namePath)}
					removeItem={() => { }}
					additionalActions={<span className={'star-wrapper' + (this.state.favouriteTags.includes(node.tag.id!) ? ' active' : '')}>
						<span>
							<IconButton color={this.state.favouriteTags.includes(node.tag.id!) ? 'warning' : 'primary'} aria-label="add to favourites" size="small" onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); this.toggleFavouriteTag(node.tag.id!); return false; }}>
								{this.state.favouriteTags.includes(node.tag.id!) && <StarIcon />}
								{!this.state.favouriteTags.includes(node.tag.id!) && <StarBorderIcon />}
							</IconButton>
						</span>
					</span>}
					dragBegin={(item, _) => this.dragStart(Number(item.id))}
			/>
			{('children' in node) && <FieldArray name={`${propertyPath}children`}
				render={arrayHelpers => {
					return (
						<>
							{this.ordered(node.children).map((child, i) => <React.Fragment key={i}>
								{this.drawTreeOrderable(child, `${propertyPath}children.${i}`, { parentId: String(node.tag.id), index: i, total: node.children.length, arrayHelpers })}
							</React.Fragment>)}

							{this.props.mode === Mode.Kiosks && this.drawKiosks(node.kiosks)}
						</>
					)
			}} />}
		</Box>
	}
	
	// Vykreslení stromu (rekurzivně):
	/*drawTree(nodes: TagNode[]): Branches {
		var branches = [];
		var totalKioskCount = 0;

		for (let node of nodes) {
			let tag = node.tag;
			let btn = (<input type="checkbox" />);
			let subBranches = this.drawTree(node.children);
			let sub = subBranches.components;
			let kiosks = this.props.mode === Mode.Kiosks ? this.drawKiosks(node.kiosks) : null;
			let len = 0;
			if (kiosks && kiosks.length)
				len = kiosks.length;
			let thisKioskCount = subBranches.totalKioskCount + len;

			var comp = (<Box key={tag.id} className={"branch" + (sub === null && kiosks === null ? " childless" : "")}>
				{btn}
				<span
					className={"title" + (this.props.onTagClick ? " clickable" : "")}
					onClick={() => this.props.onTagClick?.(tag.id!)}>
					<span>{tag.name}</span>
					<span className="desc">
						{ (thisKioskCount > 0 ? " ("+ thisKioskCount +")" : "")}
					</span>
					<span className={'star-wrapper' + (this.state.favouriteTags.includes(tag.id!) ? ' active' : '')}>
						<span>
							<IconButton color={this.state.favouriteTags.includes(tag.id!) ? 'warning' : 'primary' } aria-label="add to favourites" size="small" onClick={() => { this.toggleFavouriteTag(tag.id!) }}>
								{this.state.favouriteTags.includes(tag.id!) && <StarIcon /> }
								{!this.state.favouriteTags.includes(tag.id!) && <StarBorderIcon /> }
							</IconButton>
						</span>
					</span>
				</span>
				{sub}
				{kiosks && (<div className='kiosks'>
					{kiosks}
				</div>)}
			</Box>);
			
			totalKioskCount += thisKioskCount;
			branches.push(comp);
		}
		return { components: (branches.length > 0? branches : null), totalKioskCount: totalKioskCount };
	}*/
	
	a11yProps(index: number) {
		return {
			id: `tag-tree-paged-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}
	
	tabPanel(props: TabPanelProps) {
	  const { children, value, index, ...other } = props;

	  return (<div
		  className="tree-content paged"
		  role="tabpanel"
		  hidden={value !== index}
		  id={`simple-tabpanel-${index}`}
		  aria-labelledby={`simple-tab-${index}`}
		  {...other}
		>
		  {value === index && children}
		</div>);
	}

	nestDepth(tree: TagNode[], node: TagNode | null): number {
		if (!node || !node.tag || !node.tag.parent)
			return 0;
		else
			return 1 + this.nestDepth(tree, this.findIdInTree(tree, node.tag.parent));
	}

	ordered(tags: TagNode[], tabMode?: boolean): TagNode[] {

		if (!tabMode) {
			let arr = [];
			//let reserved = 0;
			// Make an array of unordered tags:
			for (let i = 0; i < tags.length; i++) {
				if (!this.state.tagOrders.has(tags[i].tag.id!)) {
					arr.push(tags[i]);
					if (!this.state.favouriteTags.includes(tags[i].tag.id!)) {
						// True root, not favourite
						/*reserved++;*/
					}
				}
			}
			// Now put ordered tags at correct positions:
			let toPut: { [key: number]: TagNode } = {};
			for (let i = 0; i < tags.length; i++) {
				const id = tags[i].tag.id!;
				if (this.state.tagOrders.has(id)) {
					const ord = this.state.tagOrders.get(id)!;
					//const nest = this.nestDepth(this.state.tree?.roots!, tags[i]);
					const prio = (ord * 1000) + /*(nest * 1000000) +*/ i;
					//console.log(tags[i].tag.name, prio, ' / ', nest, ' ++ ', ord);
					toPut[prio] = tags[i];
				}
			}
			let keys = Object.keys(toPut).map(x => Number(x));
			keys.sort(function (a, b) { return a - b });
			for (const key of keys) {
				arr.splice((Math.floor(key / 1000))/* + reserved*/, 0, toPut[key]);
			}

			return arr;
		} else {
			// Tab mode works differently - we need to sort based on nest even for the unsorted tags because we have
			// favourited tags from all over the tree:
			let buckets: { [key: number]: any } = {};
			let toPut: { [key: number]: any } = {};
			for (let i = 0; i < tags.length; i++) {
				if (!this.state.tagOrders.has(tags[i].tag.id!)) {
					const nestLevel = this.nestDepth(this.state.tree?.roots!, tags[i]);
					if (!buckets[nestLevel]) {
						buckets[nestLevel] = [];
					}
					buckets[nestLevel].push(tags[i]);
				}
			}
			for (let i = 0; i < tags.length; i++) {
				const id = tags[i].tag.id!;
				if (this.state.tagOrders.has(id)) {
					const nestLevel = this.nestDepth(this.state.tree?.roots!, tags[i]);
					if (!buckets[nestLevel]) {
						buckets[nestLevel] = [];
					}
					if (!toPut[nestLevel]) {
						toPut[nestLevel] = {};
					}
					const ord = this.state.tagOrders.get(id)!;
					const prio = (ord * 1000) + i;
					toPut[nestLevel][prio] = tags[i];
				}
			}
			/*console.log('=====> ');
			console.log(Object.assign({}, buckets));
			console.log(Object.assign({}, toPut));*/
			let bucKeys = Object.keys(buckets).map(x => Number(x));
			bucKeys.sort(function (a, b) { return a - b });
			let arr : TagNode[] = [];
			for (const nestLevel of bucKeys) {
				//console.log(nestLevel);
				if (nestLevel in toPut) {
					let keys = Object.keys(toPut[nestLevel]).map(x => Number(x));
					keys.sort(function (a, b) { return a - b });
					for (const key of keys) {
						buckets[nestLevel].splice((Math.floor(key / 1000) % 1000), 0, toPut[nestLevel][key]);
					}
				}
				arr = arr.concat(buckets[nestLevel]);
			}
			//console.log(Object.assign({}, arr));
			return arr;

		}
	}
	
	drawTreePaged(nodes: TagNode[]) { 
		return <>
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={this.state.page} onChange={(e, v)=>{ this.setState({page: v}); save("lastTreeIndex_" + this.props.paged, v); }}
				variant="scrollable"
				scrollButtons="auto"
				allowScrollButtonsMobile
				>
				{ this.ordered(nodes, true).map((node, index) => {
					return <Tab key={index} label={node.tag.name} {...this.a11yProps(index)} />
				})}
			</Tabs>		
			{ this.ordered(nodes, true).map((node, index)=>{
				return (<Formik
					key={index}
					initialValues={node}
					onSubmit={() => { }}
					validate={() => { }}
				><this.tabPanel value={this.state.page} index={index}>
						<FieldArray name={'root-children'}
							render={arrayHelpers => {
								return (
									<>
										{this.ordered(node.children).map((child, i) => <React.Fragment key={i}>
											{this.drawTreeOrderable(child, `root-children.${i}`, { parentId: String(node.tag.id), index: i, total: node.children.length, arrayHelpers })}
										</React.Fragment>)}
									</>
								)
							}} />
						{this.props.mode === Mode.Kiosks && this.drawKiosks(node.kiosks)}
				</this.tabPanel></Formik>)
			})}
		</Box>
		</>;
	}

	drawKiosks(kiosks: IKiosk[]): JSX.Element | null {
		if (!kiosks)
			return null;
			
		kiosks.sort((a, b) => a.name > b.name ? 1 : -1);
		
		let components = Array<JSX.Element>();
		for (const kiosk of kiosks)
		{
			if (!this.matchesSearch(kiosk))
				continue;

			let comp = (<div key={kiosk.id} className={"kiosk"}>
				<span
					className={"kiosk-title" + (this.props.onKioskClick ? " clickable" : "")}
					onClick={() => {
						if (kiosk.id && this.props.onKioskClick)
							this.props.onKioskClick?.(kiosk.id);
					}}>
					<span className={`kiosk-status kiosk-color ${StatusParser.getKioskState(kiosk.status)}`}></span>
					<span>{kiosk.name}</span>
				</span>
			</div>);
			components.push(comp);
		}
		return components.length > 0 ? (<div className='kiosks'>{components}</div>) : null;
	}

	fixPage() {
		if (this.props.paged && this.state.tree && this.state.tree.roots && this.state.page > this.state.tree!.roots!.length - 1 && this.state.page !== 0)
			this.setState({ page: 0 });
	}

	render() {	

		const t = this.props.t;
		// const l = this.props.i18n.language;

		return (<CustomThemeContext.Consumer>{theme =>
		(<Fragment>
				{/* Máme už data: */}
			{this.state.tree && (<div className={"tree" + (this.props.mode === Mode.Tags ? ' mode-tags' : ' mode-kiosks')}>
					{this.props.mode !== Mode.Tags && <TextField value={this.state.searchTerm} onChange={(e) => this.search(e.target.value)}
						variant="outlined"
						className="tree-search"
						label={t('module.kiosks.search', {ns: 'module.kiosks'})}
						InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
						)
					}} />}
				{this.props.paged && this.drawTreePaged(this.state.tree.roots) }
				{!this.props.paged && <Formik
					initialValues={this.state.tree}
					onSubmit={() => { }}
					validate={() => { }}
				><div className="tree-content">
					{
						this.ordered(this.state.tree.roots).map((root, i) => <React.Fragment key={i}>
							{this.drawTreeOrderable(root, 'children.' + i)}
						</React.Fragment>
						)
						
					}
					</div></Formik>
				}
			</div>)}

			{ !this.state.tree && <div>Missing tree state.</div>}
				
				{/* Nemáme ještě data: */}
				{ !this.props.tags && (
					<CircularProgress />
				)}
				
			</Fragment>)}
		</CustomThemeContext.Consumer>);
	}
}

export default hoistStatics(withTranslation()(KioskTree), KioskTree)