interface IButton {
    position: number,
    label: string,
    icon: string,
    mount: ButtonMount,
    path: string
}

enum ButtonMount {
    Local = 'local', Global = 'global', Combined = 'combined', Url = 'url', LocalBoard = 'local-board', GlobalBoard = 'global-board'
}

function createDefaultButton(): IButton {
    return {
        position: 0,
        label: '',
        icon: '',
        mount: ButtonMount.Local,
        path: ''
    }
}

function stripSpan(text: string) {
    return text.replaceAll(/<\/?span>/g, '').trim();
}

export type { IButton }
export { ButtonMount, createDefaultButton, stripSpan }