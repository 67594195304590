import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Typography } from '@mui/material';
import FileExplorer from '../files/FileExplorer'
import IModule, { ModuleState } from "../../system/IModule"
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import FileService from '../files/Files.service';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { setPageTitle } from '../../App'
import { AuthContext } from '../../system/Base';

interface IState { }
interface IProps extends WithTranslation { }

@IModule
class Content extends React.Component<IProps, IState, WithTranslation> { 

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Content.getLocale()+".title", {ns:Content.getLocale()})) || "???",
			route : "/content", 
			icon : <FileCopyIcon />,
			weight : 20
		};
	}
	
	public static getLocale() { return "module.content"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Content.getLocale()+".title", { ns: Content.getLocale() }));
	}
	
	public static async search(input : string) {
		let results = await FileService.search("content", input);
		if (results && results.map)
			return results.map(result => { return {
				title: result.name,
				description: result.path,
				icon: <InsertDriveFileIcon />,
				link: "/content?search-file-path=" + result.path
			}});
		else return [];
	}
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.content))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.content.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}
	
	render() {
		return (<Fragment>
		<Typography variant="h2">
			{ this.props.t(Content.getLocale()+".title", {ns:Content.getLocale()}) }
		</Typography>
		
		<AuthContext.Consumer>{auth => {
            const readonly = !isAuthorized(auth, permissions.content.write);
			return <>
				<FileExplorer folder="content" mode="full" readonly={readonly} allowCreate={[".link", ".txt"]} />
			</>
		}}
		</AuthContext.Consumer>

		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(Content), Content)