import { TextField } from "@mui/material";
import { FieldProps, getIn } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon"
import { triggerFormChange } from "./Formik.model";

interface IProps extends FieldProps, WithTranslation {
    convertUTC?: boolean,

    labelKey: string,
    namespace: string,

    minDate?: any,
    maxDate?: any,
    InputProps?: any
}

interface IState {
    convertUTC: boolean
}

function dateToIso(datetime?: DateTime, convertUTC: boolean = true): string {
    if (!datetime)
        return '';
    if (convertUTC)
        return datetime.toUTC().toISODate();
    return datetime.toISODate();
}

function dateFromIso(datetime?: string, convertUTC: boolean = true): DateTime | undefined {
    if (!datetime)
        return undefined;
    if (convertUTC)
        return DateTime.fromISO(datetime, { zone: 'utc'});
    return DateTime.fromISO(datetime);
}

function onDateChangeFormikAdapter(value: DateTime, props: FieldProps, convertUTC: boolean) {
    triggerFormChange(props.form, props.field.name, dateToIso(value, convertUTC));
}

class MuiDateField extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            convertUTC: this.props.convertUTC ?? true
        };
    }

    render() {
        const t = this.props.t;
        const form = this.props.form;
        const field = this.props.field;
        
        const errors = getIn(form.errors, field.name);
        const touched = getIn(form.touched, field.name);

        return <DatePicker
            label={t(`${this.props.namespace}.${this.props.labelKey}`, {ns: this.props.namespace})}
            renderInput={(props: any) => 
                <TextField {...props}
                    fullWidth
                    id={field.name}
                    name={field.name}
                    error={touched && Boolean(errors)}
                    helperText={touched && Boolean(errors) ? String(errors) : ''}
                    onBlur={field.onBlur}
                />
            }
            value={field.value}
            onChange={(value) => onDateChangeFormikAdapter(value, this.props, this.state.convertUTC)}
            InputProps={this.props.InputProps}
            mask="___/__/__ __:__ _M"
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
        />;
    }
}

export default hoistStatics(withTranslation()(MuiDateField), MuiDateField)

export { dateToIso, dateFromIso }