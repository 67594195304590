export enum INotificationTrigger {
    'PERIODIC' = 0,
    'ON_CHANGE' = 1
}

interface INotificationSubscription {
    id: number,
    userId: number,
    provider: string, // Module name
    trigger: INotificationTrigger,
    triggerParameter: string,
}

interface INotificationProvider {
    module: string,
    identifier: string,
    supportedTriggers: INotificationTrigger[],
}

export type { INotificationSubscription, INotificationProvider }