import { FormControlLabel, FormLabel, Radio, RadioGroup, Slider, Stack } from "@mui/material";
import { Field, FieldProps } from "formik";
import hoistStatics from "hoist-non-react-statics";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import Schedule, { strToBool } from "./Schedule";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import { IAudioSchedule } from "./Schedule.model";
import { Box } from "@mui/system";
import { IKioskConfigService } from "../kiosks/Kiosks.service";
import { IKiosk } from "../kiosks/Kiosks.model";

interface ILabeledFieldProps extends FieldProps, WithTranslation {
    labelKey: string,
    namespace: string,
}

interface IState {
    values: IAudioSchedule
    initialized: boolean
}

interface IProps extends WithTranslation {
    kiosk: IKiosk
    onSubmit: (values: IAudioSchedule) => void
	kioskService: IKioskConfigService,
}

class ScheduleAudio extends React.Component<IProps, IState, WithTranslation> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            values: { sound: true, volume: 50, config: [], exceptions: []},
            initialized: false
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    async componentDidMount() {
        const schedule = await this.props.kioskService.getAudioSchedule(this.props.kiosk);
        this.setState({ values: schedule, initialized: true });
    }

    async onFormSubmit(values: IAudioSchedule) {
        values.sound = strToBool(values.sound);
        
        await this.props.kioskService.setAudioSchedule(this.props.kiosk, values);
        this.props.onSubmit(values);
    }

    render() {
        const t = this.props.t;
        const ns = 'module.dashboard';

        const defaultEffect = (props: FieldProps) => <>
            <RadioGroup {...props.field}>
                <FormControlLabel value='true' control={<Radio />} label={t(`${ns}.default-sound-on`, {ns: ns})} />
                <FormControlLabel value='false' control={<Radio />} label={t(`${ns}.default-sound-off`, {ns: ns})} />
            </RadioGroup>
        </>;

        const volume = (props: ILabeledFieldProps) => <>
            <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <FormLabel>{t(`${ns}.${props.labelKey}`, {ns: ns})}</FormLabel>
                <VolumeMuteIcon />
                <Slider {...props.field} valueLabelDisplay='auto' min={0} max={100} step={1} />
                <VolumeUpIcon />
            </Stack>
            <em>({t(`${ns}.if-not-muted`, {ns: ns})})</em>
        </>;

        return this.state.initialized && <Schedule values={this.state.values} onSubmit={this.onFormSubmit}>
            <Field name='sound' labelKey='default-effect' namespace={ns} component={defaultEffect} />
            <Box sx={{mt: '1rem', mb: '2rem'}}>
                <Field name='volume' labelKey='volume' namespace={ns} component={volume} />
            </Box>
        </Schedule>;
    }
}

export default hoistStatics(withTranslation()(ScheduleAudio), ScheduleAudio)