import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, Typography, MenuItem, IconButton, Menu } from '@mui/material';
import { BrandingContext }  from './BrandingProvider';
import { CustomThemeContext }  from './CustomThemeProvider';

import LanguageIcon from '@mui/icons-material/Language';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';

import CloudOffIcon from '@mui/icons-material/CloudOff';
import http, { BE_ROOT } from "./Communicator";
import snackNotifications from "./SnackBarUtils";
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps extends WithTranslation {
	setLanguage : (x: string) => void,
	currentLanguage : string
}

interface IState {
	count: number
	btnEnabled: boolean
	languageAnchorElement : any
	currentLanguage : string
}

class KioskOffline extends React.Component<IProps, IState, WithTranslation> {

	interval : any;
	
	constructor(props: IProps)
	{
		super(props);
		this.state = { count: 5, languageAnchorElement: null, currentLanguage: this.props.currentLanguage || "en", btnEnabled: true };
		
		this.countDown = this.countDown.bind(this);
		this.doRefresh = this.doRefresh.bind(this);
		this.handleLangMenuOpen = this.handleLangMenuOpen.bind(this);
		this.handleMenuClose = this.handleMenuClose.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
	}

	countDown()
	{
		this.setState({count: this.state.count - 1});
		if (this.state.count <= 1)
			this.doRefresh();
	}

	componentDidMount()
	{
		this.interval = setInterval(this.countDown, 1000);
	}
	
	componentWillUnmount()
	{
		clearInterval(this.interval);
	}
	
	handleLangMenuOpen(e: any) {
		this.setState({languageAnchorElement : e.currentTarget});
	}
	handleMenuClose(e : any, after : any = null) {
		if (typeof(after) === "function")
			this.setState({languageAnchorElement : null}, after);
		else
			this.setState({languageAnchorElement : null});
	};
	
	handleLanguageChange(e: any, lang: string)
	{
		this.props.setLanguage(lang);
		this.setState({ currentLanguage: lang });
		this.handleMenuClose(e);
	}
	
	async doRefresh(manual: boolean = false) {
		// Do a fake request to see if backend is connected or not:
		this.setState({ count: 5 });
		if (manual)
			this.setState({btnEnabled: false});
		var res = await http.get(BE_ROOT + "/api/ping?" + Date.now());
		if (manual && (!res || !res.status || res.status !== 200)) {
			snackNotifications.error(this.props.t("offline.title"));
			this.setState({ btnEnabled: true});
		}
		if (res && res.status && res.status === 200) {
			window.location.reload();
		}
	}

	render() {			
		const t = this.props.t;
		
		const langMenuId = 'offline-language-menu';
			
		return (
		<CustomThemeContext.Consumer>{ theme => (
		<BrandingContext.Consumer>{ branding => ( 
			<div className='dp2-login offline'>
				{ /* We are not going to import the image. We need lazy loading for branding. */ }
				<img className="logo" src="./branding/login-logo.png" />
				<div className="kiosk-offline-center">
					<div className="kiosk-offline-box">
						<span className="loaddot d1" />
						<span className="loaddot d2" />
						<span className="loaddot d3" />
						<span className="loaddot d4" />
						<span className="loaddot d5" />
						
						<span className="offline-menu">
						
							<IconButton size="large" aria-label="switch theme" color="inherit"
							onClick={ ()=>{theme.setTheme(
											{
												'mode' : theme.currentTheme.mode === "dark" ? "light" : "dark",
												'branding' : theme.currentTheme.branding
											});} }>
								<InvertColorsIcon />
							</IconButton>
							
							<IconButton size="large" aria-label="switch language" color="inherit" aria-controls={langMenuId} aria-haspopup="true"
								onClick={this.handleLangMenuOpen}>
								<LanguageIcon />
							</IconButton>
						
						</span>
						
						<Typography className="instructions" color="textPrimary" variant="h3">
							<CloudOffIcon fontSize="large" />
						</Typography>
						<Typography className="mega-title" color="textPrimary" variant="h1">
							{t("offline.title")}
						</Typography>
						<Typography className="instructions" color="textPrimary" variant="h2">
							{t("offline.subtitle")}
						</Typography>
						{this.state.btnEnabled &&
							<Button className="offline-again" onClick={() => this.doRefresh(true)} disabled={!this.state.btnEnabled} startIcon={<SyncIcon />}>{t("offline.button")}</Button>}

						{!this.state.btnEnabled && <div className="offline-again" style={{ marginTop: '25px' }}><CircularProgress /></div> }
						
						<Menu anchorEl={this.state.languageAnchorElement} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} id={langMenuId} keepMounted
							transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={Boolean(this.state.languageAnchorElement)} onClose={this.handleMenuClose}>
							<MenuItem selected={this.state.currentLanguage === "en"} onClick={(e)=>{  this.handleLanguageChange(e, "en"); }} value="en"><ListItemIcon>{this.state.currentLanguage === "en" && <CheckIcon />}</ListItemIcon>English</MenuItem>
							<MenuItem selected={this.state.currentLanguage === "cs"} onClick={(e)=>{  this.handleLanguageChange(e, "cs"); }} value="cs"><ListItemIcon>{this.state.currentLanguage === "cs" && <CheckIcon />}</ListItemIcon>Čeština</MenuItem>
							<MenuItem selected={this.state.currentLanguage === "sk"} onClick={(e) => { this.handleLanguageChange(e, "sk"); }} value="sk"><ListItemIcon>{this.state.currentLanguage === "sk" && <CheckIcon />}</ListItemIcon>Slovenčina</MenuItem>
						</Menu>
					</div>
				</div>
			</div>
		)}</BrandingContext.Consumer>
		)}</CustomThemeContext.Consumer>
		);
	}
}

export default withTranslation()(KioskOffline);