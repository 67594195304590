import React, { useState, useContext } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrandingContext }  from './BrandingProvider';


export interface ICustomThemeContext {
    currentTheme: {
      mode: string,
      branding: any // TODO
    },
    setTheme: (x: ICustomThemeContext['currentTheme']) => void
};

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext<ICustomThemeContext>(
  {
    currentTheme: { mode: 'light', branding: { } },
    setTheme: (x: { mode: string, branding: any }) => { }
  },
)

const CustomThemeProvider = (props : any) => {

const themeLight = {
  palette: {
    primary: {
      main: '#1b1d40',
    },
    secondary: {
      main: '#d92a55',
    },
    success: {
      main: '#00B0A6',
      contrastText: '#fff',
    },
    warning: {
      main: '#FFA500',
      contrastText: '#fff',
    },
    error: {
      main: '#d92a55',
      contrastText: '#fff',
    },
  },
};

const themeDark = {
  palette: {
    mode: 'dark',
    primary: {
        main: '#00B0A6',
    },
    secondary: {
      main: '#d92a55',
    },
    success: {
      main: '#00B0A6',
      contrastText: '#000',
    },
    warning: {
      main: '#FFA500',
      contrastText: '#000',
    },
    error: {
      main: '#d92a55',
      contrastText: '#000',
    },
  },
};

  // eslint-disable-next-line react/prop-types
  const { children } = props

  // Read current theme from localStorage or maybe from an api
  const { currentBranding, setBranding } = useContext(BrandingContext);
  const currentTheme = { 'mode' : localStorage.getItem('dp2_theme') || 'light', 'branding' : currentBranding };

  // State to hold the selected theme name
  const [themeData, _setThemeData] = useState(currentTheme)

  // Wrap _setThemeData to store new theme names in localStorage
  const setThemeData = (data : { mode : string, branding : any }) => {
    localStorage.setItem('dp2_theme', data.mode);
	// We need a new object othervise react won't re-render:
	var newData = { 'mode' : data.mode, 'branding' : data.branding };
    _setThemeData(newData);
  }  

  const contextValue = {
    currentTheme: themeData,
    setTheme: setThemeData
  }
  
  var theme = themeLight;
  if (currentTheme.mode === "dark")
	theme = themeDark;

  // Apply custom branding:
  if (currentTheme.mode === "light") {
	if (currentBranding.hasOwnProperty('colors'))
	  if (currentBranding.colors.hasOwnProperty('theme-light'))
		Object.assign(theme, currentBranding.colors['theme-light']);
  } else {
	if (currentBranding.hasOwnProperty('colors'))
	  if (currentBranding.colors.hasOwnProperty('theme-dark'))
		Object.assign(theme, currentBranding.colors['theme-dark']);
  }
  
  var themeObj = createTheme(theme);

  return (  
    <CustomThemeContext.Provider value={contextValue}>
	  <div className={"theme theme-" + currentTheme.mode}>
		<ThemeProvider theme={themeObj}>{children}</ThemeProvider>
	  </div>
    </CustomThemeContext.Provider>
  )
}

export default CustomThemeProvider
