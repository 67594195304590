import React, { Fragment } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, WithTranslation } from 'react-i18next';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Typography } from '@mui/material';
import FileExplorer from '../files/FileExplorer'
import IModule, { ModuleState } from "../../system/IModule"
import { controllerKeys, hasController, isAuthorized, IUserContext, permissions } from '../../system/User.model'
import FileService from '../files/Files.service';
import ImageIcon from '@mui/icons-material/Image';
import { setPageTitle } from '../../App'
import { AuthContext } from '../../system/Base';

interface IState { }
interface IProps extends WithTranslation { }

@IModule
class Media extends React.Component<IProps, IState, WithTranslation> {

	public static menu(t: ((x:string, y:any)=>string)) {
		return {
			title: (t && t(Media.getLocale()+".title", {ns:Media.getLocale()})) || "???",
			route : "/media",
			icon : <PermMediaIcon />,
			weight : 50
		};
	}
	
	public static getLocale() { return "module.media"; }
	
	componentDidMount()
	{
		setPageTitle(this.props.t( Media.getLocale()+".title", { ns: Media.getLocale() }));
	}
	
	public static async search(input : string) {
		let results = await FileService.search("media", input);
		if (results && results.map)
			return results.map(result => { return {
				title: result.name,
				description: result.path,
				icon: <ImageIcon />,
				link: "/media?search-file-path=" + result.path
			}});
		else return [];
	}
	
	public static isEnabled(auth: IUserContext) { 
		if (!hasController(auth, controllerKeys.media))
			return ModuleState.DISABLED;
		if (isAuthorized(auth, permissions.media.read))
			return ModuleState.ENABLED;
			
		return ModuleState.NO_PERMISSIONS;
	}
	
	render() {
		return (<Fragment>
		<Typography variant="h2">
			{ this.props.t(Media.getLocale()+".title", {ns:Media.getLocale()}) }
		</Typography>
		
		<AuthContext.Consumer>{auth => {
            const readonly = !isAuthorized(auth, permissions.media.write);
			return <>
				<FileExplorer folder="media" mode="full" readonly={readonly} allowPlaylistMaking={true} />
			</>
		}}
		</AuthContext.Consumer>

		</Fragment>);
	}

}

export default hoistStatics(withTranslation()(Media), Media)