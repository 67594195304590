import { DateTime } from "luxon";
import { IFileRecord, formatSize } from "../files/Files.model";

export enum ReportType {
    All = "ALL", ButtonPosition = "BUTTON_POSITION", ButtonLabel = "BUTTON_LABEL"
}

export interface IActivity {
    date: string; // ISO UTC datetime string
    path: string; // unparsed raw URL as stored in web history
    source: ActivityDomain; // component in which the activity started
    sourceDetail: object; // relevant information about source
    target: ActivityDomain; // component in which the activity ended
    targetDetail: object; // relevant information about target
}

export interface IRemoteActivity extends IActivity {
    identifier: string; // kiosk identifier
    name: string; // kiosk name
}

export interface IButtonPositionRow {
    position: number;
    clicks: number;
}
export function isButtonPositionRow(row: any): row is IButtonPositionRow {
    return 'clicks' in row && 'position' in row;
}

export interface IButtonLabelRow {
    label: string;
    clicks: number;
}
export function isButtonLabelRow(row: any): row is IButtonLabelRow {
    return 'clicks' in row && 'label' in row;
}

export interface IActivityEntry {
    time: string; // ISO UTC datetime string
    completeData: boolean;
    rate: number;
}

export type ITimeFilter = {
    from?: string; // ISO UTC datetime string
    to?: string; // ISO UTC datetime string
};

export enum ActivityDomain {
    Any = '%',
    HomepageButton = 'HOMEPAGE_BUTTON',
    Banner = 'BANNER',
    FileExplorer = 'FILE_EXPLORER',
    Board = 'BOARD',
    PdfViewer = 'PDF_VIEWER',
    ExternalWebsite = 'EXTERNAL_WEBSITE',
  }

export type IContentFilter = {
    source?: ActivityDomain;
    target?: ActivityDomain;
    sourceDetail?: string;
    targetDetail?: string;
}

export interface IReportMeta {
    url: string,
    name: string,
    size: string,
    type?: ReportType,
    source?: ActivityDomain,
    target?: ActivityDomain,
    timeFilter?: ITimeFilter,
    customPanelSelection?: boolean,
}

export function processPath(path: string) {
    let p = path.trim();
    p = p.replaceAll('%', '\\%').replaceAll('_', '\\_');
    
    if (!p) return p;
    return `%${p}%`;
}
export function processContent(contentFilter: IContentFilter) {
    const filter = {...contentFilter};
    if (filter.sourceDetail)
        filter.sourceDetail = processPath(filter.sourceDetail);
    if (filter.targetDetail)
        filter.targetDetail = processPath(filter.targetDetail);
    return filter;
} 

export function createDefaultTimeFilter(): ITimeFilter {
    return { from: DateTime.now().startOf('day').minus({days: 6}).toUTC().toISO(), to: DateTime.now().startOf('day').plus({days: 1}).toUTC().toISO() };
}

export function createDefaultContentFilter(): IContentFilter {
    return { source: ActivityDomain.Any, target: ActivityDomain.Any, sourceDetail: '', targetDetail: '' };
}

export function getReportMeta(file: IFileRecord, locale: string): IReportMeta {
    // {lc(ReportType)}_activity_report_of_{source: string}_to_{target: string}_from_{from: datetime}_to_{to: datetime}(_for_custom_panel_selection)
    const matches = Array.from(file.name.matchAll(/(.*?)_activity_report_of_(.*?)_to_(.*?)_from_(.*?)_to_(.*?)(_for_custom_panel_selection)?.csv/g))[0];

    if (!matches || matches.length < 6)
        return {
            name: file.name,
            url: file.url,
            size: formatSize(file.size, locale),
         };

    return {
        name: file.name,
        url: file.url,
        size: formatSize(file.size, locale),
        type: String(matches[1]).toUpperCase() as ReportType,
        source: String(matches[2]).replace('everything', '%') as ActivityDomain,
        target: String(matches[3]).replace('everything', '%') as ActivityDomain,
        timeFilter: {
            from: String(matches[4]),
            to: String(matches[5]),
        },
        customPanelSelection: matches.length > 6,
    };
}