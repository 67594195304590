export class PageQuery {
    limit: number;
    offset: number;

    constructor(page: number, perPage: number) {
        this.limit = perPage;
        this.offset = (page - 1) * perPage;
    }

    toString(): string {
        return `limit=${this.limit}&offset=${this.offset}`;
    }
}

export class Pagination {
    currentPage: number;
    totalPages: number;
    perPage: number;

    constructor(totalItems: number, currentOffset: number | null, perPage: number) {
        this.currentPage = Math.floor((currentOffset ?? 0) / perPage) + 1;
        this.totalPages = Math.ceil(totalItems / perPage);
        this.perPage = perPage;
    }

    getQuery(): PageQuery {
        return new PageQuery(this.currentPage, this.perPage);
    }

    getPagePosition(itemOffset: number): { page: PageQuery, position: number } {
        return {
            page: new PageQuery(Math.floor(itemOffset / this.perPage) + 1, this.perPage),
            position: itemOffset % this.perPage
        };
    }
}

export interface IPagedResponse<T> {
    total: number,
    current: number | null, // first item in the returned set
    next: number | null, // first item outside the returned set
    data: T[];
}